import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab } from '../../components/Tab';

export const CompanyPartTimeJobDetail = () => {
  const { id: companyId, partTimeJobId } = useParams<{
    id: string;
    partTimeJobId: string;
  }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if (!companyId || !partTimeJobId) {
    return <></>;
  }

  return (
    <>
      <nav className="space-x-6 px-8 pb-4">
        <Tab
          title="공고 정보"
          onClick={() =>
            navigate(
              `/admin/company/${companyId}/part-time-job/${partTimeJobId}/edit`
            )
          }
          selected={
            pathname ===
            `/admin/company/${companyId}/part-time-job/${partTimeJobId}/edit`
          }
        />
        <Tab
          title="근무일"
          onClick={() =>
            navigate(
              `/admin/company/${companyId}/part-time-job/${partTimeJobId}/date`
            )
          }
          selected={
            pathname ===
            `/admin/company/${companyId}/part-time-job/${partTimeJobId}/date`
          }
        />
      </nav>

      <Outlet />
    </>
  );
};
