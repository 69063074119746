export function range(length: number, start = 0) {
  return Array.from({ length }, (_, i) => i + start);
}

export function getKoreanDay(day: number) {
  if (day === 0) {
    return '일';
  }
  if (day === 1) {
    return '월';
  }
  if (day === 2) {
    return '화';
  }
  if (day === 3) {
    return '수';
  }
  if (day === 4) {
    return '목';
  }
  if (day === 5) {
    return '금';
  }
  if (day === 6) {
    return '토';
  }
  return '';
}

export const sortDescString = JSON.stringify({ createdAt: 'DESC' });

export const getOriginalFileName = (fileName: string) => {
  const urlArr = fileName.split('_');
  urlArr.shift();
  const originalFileName = urlArr.join('_');
  return originalFileName;
};

export const downloadFile = async (fileUrl: string) => {
  const FileSaver = require('file-saver');
  const fileName = getOriginalFileName(fileUrl) || '';
  const blob = await fetch(fileUrl).then((r) => r.blob());
  FileSaver.saveAs(blob, fileName);
};
