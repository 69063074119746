import React, { FC } from 'react';

interface ThirdSectionImgCardProps {
  img: string;
  title: string;
  content: string;
}

export const ThirdSectionImgCard: FC<ThirdSectionImgCardProps> = ({
  img,
  title,
  content,
}) => {
  return (
    <div className="w-full space-y-10 rounded-lg bg-[#F8FAFC] p-5">
      <div className="space-y-4 text-[#073883]">
        <p className="text-[24px] font-bold">{title}</p>
        <p className="whitespace-pre-wrap text-[14px] font-medium">{content}</p>
      </div>

      <div className="flex justify-end">
        <img src={img} alt="" className="w-40" />
      </div>
    </div>
  );
};
