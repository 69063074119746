import { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  KOREAN_PAYMENT_STATUS2,
  PaymentStatus,
} from '../../../api/payment/type';
import { Card } from '../../../components/Card';
import { Icon } from '../../../components/Icon';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllContractByUserId } from '../../../hooks/contract';

export const PaymentRequestList = () => {
  const [page, setPage] = useState(1);
  const { id: userId } = useParams<{ id: string }>();
  const { data: allContractByUserId } = useAllContractByUserId(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
    },
    userId ? +userId : 0
  );
  return (
    <>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>근무지</Table.Th>
              <Table.Th>시급</Table.Th>
              <Table.Th>근무일</Table.Th>
              <Table.Th>근무시간</Table.Th>
              <Table.Th>신청일</Table.Th>
              <Table.Th>근로계약서</Table.Th>
              <Table.Th>급여지급 상태</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allContractByUserId &&
              allContractByUserId.items.map((contract) => (
                <Table.Row key={contract.id}>
                  <Table.Td>{contract.id}</Table.Td>
                  <Table.Td>{contract.workPlace}</Table.Td>
                  <Table.Td>{contract.hourPay}원</Table.Td>
                  <Table.Td>
                    {moment(contract.contractDate).format('yyyy-MM-DD')}
                  </Table.Td>
                  <Table.Td>
                    {moment(contract.startWorkTime).format('HH:mm')}
                    {' ~ '}
                    {moment(contract.endWorkTime).format('HH:mm')}
                  </Table.Td>
                  <Table.Td>
                    {moment(contract.createdAt).format('yyyy-MM-DD')}
                  </Table.Td>
                  <Table.Td>
                    <Icon.File
                      onClick={() =>
                        window.open(
                          `/admin/users/${contract.id}/contractinfo`,
                          '_blank',
                          'noopener'
                        )
                      }
                      className=" w-16 cursor-pointer "
                    />
                  </Table.Td>
                  <Table.Td
                    className={`${
                      contract.payment.paymentStatus === PaymentStatus.APPLIED
                        ? `text-red-500`
                        : `text-blue-500`
                    }`}
                  >
                    {KOREAN_PAYMENT_STATUS2[contract.payment.paymentStatus]}
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allContractByUserId?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={allContractByUserId?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
