import React, { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { some } from 'lodash';
import { Career } from '../../api/career/type';
import { ModalType } from '../../constance';
import {
  useCreateCareerAdminMutation,
  useCreateCareerMutation,
  useUpdateCareerAdminMutation,
  useUpdateCareerMutation,
} from '../../hooks/career';
import { meState } from '../../plugins/ridge';
import { Button } from '../Button';
import { Label } from '../Label';
import { TextArea } from '../TextArea';
import { TextField } from '../TextField';
import { AnimationLayout } from './AnimationLayout';

interface CareerModalProps {
  career?: Career;
  onClose: () => void;
  open: boolean;
  modalType: ModalType;
  careerId: string;
  resumeId: string;
}

interface CareerValue {
  company: string;
  position: string;
  workingStartAt: string;
  workingEndAt: string;
  description: string;
}

export const CareerModal: FC<CareerModalProps> = ({
  onClose,
  open,
  modalType,
  careerId,
  resumeId,
  career,
}) => {
  const me = meState.useValue();
  if (!me) {
    return <></>;
  }
  const [careerState, setCareerState] = useState<CareerValue>({
    company: '',
    position: '',
    workingStartAt: '',
    workingEndAt: '',
    description: '',
  });

  const [validate, setValidate] = useState<boolean>(false);
  const { company, position, workingStartAt, workingEndAt, description } =
    careerState;
  const _init = () => {
    setCareerState({
      company: '',
      position: '',
      workingStartAt: '',
      workingEndAt: '',
      description: '',
    });
    onClose();
  };

  const { mutate: createCareerMutate } = useCreateCareerMutation(_init);
  const { mutate: createCareerAdminMutate } =
    useCreateCareerAdminMutation(_init);
  const { mutate: updateCareerMutate } = useUpdateCareerMutation(_init);
  const { mutate: updateCareerAdminMutate } =
    useUpdateCareerAdminMutation(_init);

  const _onChange = (e: any) => {
    setCareerState({ ...careerState, [e.target.name]: e.target.value });
  };
  const _onClick = () => {
    if (modalType === ModalType.ADD) {
      const data = { ...careerState, resumeId };
      if (me.role === 'ADMIN') {
        createCareerAdminMutate(data);
      } else {
        createCareerMutate(data);
      }
    }
    if (modalType === ModalType.EDIT) {
      const data = { id: +careerId, ...careerState, resumeId };
      if (me.role === 'ADMIN') {
        updateCareerAdminMutate(data);
      } else {
        updateCareerMutate(data);
      }
    }
  };
  const isValidate = () => {
    setValidate(!some(careerState, (e) => e === ''));
  };

  useEffect(() => {
    isValidate();
  }, [careerState]);

  useEffect(() => {
    if (modalType === ModalType.EDIT && career) {
      setCareerState(career);
    }
  }, [career]);

  useEffect(() => {
    if (!open) {
      _init();
    }
  }, [open]);

  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 w-full max-w-3xl transform space-y-3 overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
          <div className="flex justify-between">
            <h4 className="text-gray-800">
              경력사항 {modalType === ModalType.ADD ? '추가' : '수정'}
            </h4>
          </div>
          <div className="grid gap-y-4 ">
            <TextField
              label="업체명"
              compulsory
              placeholder="업체명을 입력해주세요."
              labelClassname="text-left"
              value={company}
              name="company"
              onChange={(e) => _onChange(e)}
            />
            <TextField
              label="직무내용"
              compulsory
              placeholder="직무내용을 입력해주세요."
              labelClassname="text-left"
              value={position}
              name="position"
              onChange={(e) => _onChange(e)}
            />

            <div className="text-left">
              <Label text="근무기간" compulsory className=" hidden md:block" />
              <div className="items-center space-y-3 md:flex md:space-y-0 md:space-x-3">
                <div className="flex-1">
                  <Label
                    text="근무시작일"
                    compulsory
                    className="block md:hidden"
                  />
                  <TextField
                    type="date"
                    max="9999-12-31"
                    value={
                      workingStartAt &&
                      format(new Date(workingStartAt), 'yyyy-MM-dd')
                    }
                    name="workingStartAt"
                    onChange={(e) => _onChange(e)}
                  />
                </div>
                <p className="hidden md:block">~</p>
                <div className="flex-1">
                  <Label
                    text="근무종료일"
                    compulsory
                    className="block md:hidden"
                  />
                  <TextField
                    type="date"
                    max="9999-12-31"
                    value={
                      workingEndAt &&
                      format(new Date(workingEndAt), 'yyyy-MM-dd')
                    }
                    name="workingEndAt"
                    onChange={(e) => _onChange(e)}
                  />
                </div>
              </div>
            </div>
            <TextArea
              label="상세내용"
              compulsory
              placeholder="상세내용을 입력해주세요."
              labelClassname="text-left"
              value={description}
              name="description"
              onChange={(e) => _onChange(e)}
            />
            <div className="text-left">
              <Button
                text="저장하기"
                disabled={!validate}
                onClick={_onClick}
                className={`mt-4 w-full text-white  md:w-60 ${
                  !validate ? 'bg-gray-400' : 'bg-brand-1'
                }`}
              />
            </div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
