import { User } from '../../types';

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SCHOOL_STATUS {
  ENROLLED = 'ENROLLED',
  DROPPED = 'DROPPED',
  REST = 'REST',
  GRADUATED = 'GRADUATED',
}

export enum BankCode {
  HANA = '081',
  GIUP = '003',
  KB = '004',
  WOORI = '020',
  SINHAN = '088',
  NH = '011',
  CITY = '027',
  SC = '023',
  K = '089',
  KAKAO = '090',
  NEWVILLIGE = '045',
  POST = '071',
  INDUSTRY = '002',
  SOOHYUP = '007',
  DAEGU = '031',
  BUSAN = '032',
  GWANGJU = '034',
  JEJU = '035',
  JUNBUK = '037',
  GYUNGNAM = '039',
}

export const KOREAN_BANK_NAME = {
  [BankCode['HANA']]: '하나은행',
  [BankCode['GIUP']]: '기업은행',
  [BankCode['KB']]: 'KB국민은행',
  [BankCode['WOORI']]: '우리은행',
  [BankCode['SINHAN']]: '신한은행',
  [BankCode['NH']]: 'NH농협은행',
  [BankCode['CITY']]: '한국씨티은행',
  [BankCode['SC']]: 'SC제일은행',
  [BankCode['K']]: 'K뱅크',
  [BankCode['KAKAO']]: '카카오뱅크',
  [BankCode['NEWVILLIGE']]: '새마을금고',
  [BankCode['POST']]: '우체국',
  [BankCode['INDUSTRY']]: '산업은행',
  [BankCode['SOOHYUP']]: '수협은행',
  [BankCode['DAEGU']]: '대구은행',
  [BankCode['BUSAN']]: '부산은행',
  [BankCode['GWANGJU']]: '광주은행',
  [BankCode['JEJU']]: '제주은행',
  [BankCode['JUNBUK']]: '전북은행',
  [BankCode['GYUNGNAM']]: '경남은행',
};

export interface Resume {
  id: number;
  createdAt: string;
  updatedAt: string;
  userImage: string; // 유저 사진
  userName: string; // 이름
  email: string; // 이메일
  birth: string; // 생년월일
  gender: GENDER; // 성별
  personalId: string; // 주민등록번호
  address: string; // 주소
  addressDetail: string; // 상세 주소
  depositor: string; // 예금주
  bankCode: BankCode; // 은행명
  bankAccount: string; // 계좌 번호
  accountImgUrl: string; // 통장 사본
  schoolName: string; // 학교
  schoolStatus: SCHOOL_STATUS; // 학적
  major: string; // 전공
  healthImgUrl: string; //보건증
  agreementFileUrl: string; // 부모님 동의서
  resumeFileUrl: string; // 이력서 파일
  coverLetterFileUrl: string; // 자소서 파일
  user: User;
}

export interface CreateResumeDto {
  userImage: string; // 유저 사진
  email: string; // 이메일
  birth: string; // 생년월일
  gender: GENDER; // 성별
  personalId: string; // 주민등록번호
  address: string; // 주소
  addressDetail: string; // 상세 주소
  depositor: string; // 예금주
  bankCode: BankCode; // 은행명
  bankAccount: string; // 계좌 번호
  accountImgUrl: string; // 통장 사본
  schoolName: string; // 학교
  schoolStatus: SCHOOL_STATUS | null; // 학적
  major: string; // 전공
  healthImgUrl: string; //보건증
  agreementFileUrl: string; // 부모님 동의서
  resumeFileUrl: string; // 이력서 파일
  coverLetterFileUrl: string; // 자소서 파일
}

export interface UpdateResumeDto {
  id: number;
  userImage?: string; // 유저 사진
  email?: string; // 이메일
  birth?: string; // 생년월일
  gender?: GENDER; // 성별
  personalId?: string; // 주민등록번호
  address?: string; // 주소
  addressDetail?: string; // 상세 주소
  depositor?: string; // 예금주
  bankCode?: BankCode; // 은행명
  bankAccount?: string; // 계좌 번호
  accountImgUrl?: string; // 통장 사본
  schoolName?: string; // 학교
  schoolStatus?: SCHOOL_STATUS | null; // 학적
  major?: string; // 전공
  healthImgUrl?: string; //보건증
  agreementFileUrl?: string; // 부모님 동의서
  resumeFileUrl?: string; // 이력서 파일
  coverLetterFileUrl?: string; // 자소서 파일
}

export const KOREAN_GENDER = {
  [GENDER.MALE]: '남성',
  [GENDER.FEMALE]: '여성',
};

export const KOREAN_SCHOOL_STATUS = {
  [SCHOOL_STATUS.ENROLLED]: '재학',
  [SCHOOL_STATUS.DROPPED]: '중퇴',
  [SCHOOL_STATUS.REST]: '휴학',
  [SCHOOL_STATUS.GRADUATED]: '졸업',
};
