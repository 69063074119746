import React, { FC } from 'react';

interface FirstSectionImgCardProps {
  img: string;
  title: string;
  content: string;
}

export const FirstSectionImgCard: FC<FirstSectionImgCardProps> = ({
  img,
  title,
  content,
}) => {
  return (
    <button className="relative aspect-[4/6] h-[376px] overflow-hidden rounded-lg text-left md:aspect-[7/9]">
      <img src={img} alt="" className="rounded-lg object-cover" />
      <div className="absolute right-5 left-5 bottom-10 space-y-4 text-white md:bottom-5">
        <div className="space-y-1">
          <p className="text-[12px] font-medium">The SCV</p>
          <p className="text-[22px] font-semibold">{title}</p>
        </div>

        <p className="whitespace-pre-wrap text-[13px] font-light">{content}</p>
      </div>
    </button>
  );
};
