import { useState } from 'react';
import { map } from 'lodash';
import { PartnersIntroduction } from '../../../api/partners-introduction/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllPartnersIntroductionByAdmin } from '../../../hooks/partners-introduction';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { sortDescString } from '../../../utils';
import AdminH1 from '../../components/AdminH1';
import { PartnersIntroAddModal } from '../../components/modal/PartnersIntroAddMoal';
import { PartnersIntroModal } from '../../components/modal/PartnersIntroModal';

export const PartnersIntro = () => {
  const [page, setPage] = useState(1);
  const { data: allPartnersIntroductionByAdmin } =
    useAllPartnersIntroductionByAdmin({
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
      sort: sortDescString,
    });

  const [showAddModal, setAddModal] = useState(false);
  const [selectPartner, setSelectPartner] =
    useState<PartnersIntroduction | null>(null);

  return (
    <>
      <PartnersIntroModal
        onClose={() => setSelectPartner(null)}
        partner={selectPartner}
      />
      <PartnersIntroAddModal
        open={showAddModal}
        onClose={() => setAddModal(false)}
      />

      <div className="flex items-center justify-between py-2 px-8">
        <AdminH1>협력사 소개</AdminH1>
        <Button
          className="w-30 h-12 bg-gray-800 text-sm text-white shadow-sm"
          onClick={() => setAddModal(true)}
        >
          추가하기
        </Button>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>작성일</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allPartnersIntroductionByAdmin &&
              map(allPartnersIntroductionByAdmin.items, (partnersIntro) => (
                <Table.Row key={partnersIntro.id}>
                  <Table.Td>{partnersIntro.id}</Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(
                      partnersIntro.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDD
                    )}
                  </Table.Td>
                  <Table.Td>{partnersIntro.companyName}</Table.Td>
                  <Table.Td className="text-right">
                    <p
                      className="cursor-pointer font-normal text-indigo-600 hover:text-indigo-900"
                      onClick={() => setSelectPartner(partnersIntro)}
                    >
                      상세보기
                    </p>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allPartnersIntroductionByAdmin?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={
            allPartnersIntroductionByAdmin?.pagination.totalItemCount || 0
          }
          page={page}
        />
      )}
    </>
  );
};
