import { Worker } from './api/payment/type';

export interface Paginated<T> {
  items: T[];
  pagination: {
    totalItemCount: number;
    currentItemCount: number;
    totalPage: number;
    currentPage: number;
    itemsPerPage: number;
  };
}

export interface FilterType {
  [key: string]: string | number[];
}

export interface PaginationDto {
  sort?: string;
  filter?: FilterType | string;
  page?: number;
  itemsPerPage?: number;
}

export interface User {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  role: Role;
  name: string;
  isOperator: boolean;
  worker?: Worker;
}

export enum Role {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export enum WorkDateType {
  ALL = 'ALL',
  WEEKDAY = 'WEEKDAY',
  WEEKEND = 'WEEKEND',
}

export enum WorkTimeType {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  FULLTIME = 'FULLTIME',
}
