import { includes } from 'lodash';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { AuthRoute } from '../components/AuthRoute';
import { Footer } from '../components/Footer';
import { Modal } from '../components/modal/Modal';
import { MainNavBar } from '../components/navbar/MainNavBar';
import { TopNavBar } from '../components/navbar/TopNavBar';
import { SHOW_BOTTOM_NAVBAR_ROUTES } from './constants/router';
import { ContractAddPage } from './contract/ContractAddPage';
import { ContractDetailPage } from './contract/[id]/ContractDetailPage';
import { FindPasswordPage } from './find/password/FindPasswordPage';
import { ResetPasswordSuccessPage } from './find/password/Success/ResetPasswordSuccessPage';
import { ResetPasswordPage } from './find/password/reset/ResetPasswordPage';
import { SendEmailPage } from './find/password/send-email/SendEmailPage';
import { HomePage } from './home/HomePage';
import { LoginPage } from './login/LoginPage';
import { MainPage } from './main/MainPage';
import { MyPage } from './mypage/MyPage';
import { EditPage } from './mypage/edit/EditPage';
import { HistoryPage } from './mypage/history/HistoryPage';
import { LikePage } from './mypage/like/LikePage';
import { ResumeEditPage } from './mypage/resume-edit/ResumeEditPage';
import { NoticePage } from './notice/NoticePage';
import { NoticeDetailPage } from './notice/[id]/NoticeDetailPage';
import { PartTimeJobPage } from './part-time-job/PartTimeJobPage';
import { PartTimeJobDetailPage } from './part-time-job/[id]/PartTimeJobDetailPage';
import { PartTimeJobApplyPage } from './part-time-job/apply/PartTimeJobApplyPage';
import { PaymentRequestPage } from './payment-request/PaymentRequestPage';
import { PaymentRequestDetailPage } from './payment-request/[id]/PaymentRequestDetailPage';
import { ResumeAddPage } from './resume/ResumeAddPage';
import { SignupPage } from './signup/SignupPage';
import { SignUpSuccessPage } from './signup/success/SignUpSuccessPage';

export const Router = () => {
  const { pathname } = useLocation();
  const isWhitePage =
    pathname === '/login' ||
    pathname.startsWith('/part-time-job') ||
    pathname.startsWith('/signup/success') ||
    pathname.startsWith('/signup') ||
    pathname.startsWith('/find/password/send-email') ||
    pathname.startsWith('/find-password') ||
    pathname.startsWith('/find-password/reset') ||
    pathname.startsWith('/part-time-job/:id/apply') ||
    pathname.startsWith('/part-time-job/:id') ||
    pathname.startsWith('/payment-request/:id') ||
    pathname.startsWith('/payment-request') ||
    pathname.startsWith('/contract/:id') ||
    pathname.startsWith('/contract/add') ||
    pathname.startsWith('/reset-password/:resetToken') ||
    pathname.startsWith('/reset-password/success') ||
    pathname.startsWith('/resume/:resumeId') ||
    pathname.startsWith('/mypage/resume') ||
    pathname.startsWith('/mypage/edit') ||
    pathname.startsWith('/mypage') ||
    pathname.startsWith('/mypage/history') ||
    pathname.startsWith('/mypage/like') ||
    pathname.startsWith('/notice/:id') ||
    pathname.startsWith('/notice');

  return (
    <>
      {pathname === '/' ? (
        <MainNavBar />
      ) : (
        !includes(SHOW_BOTTOM_NAVBAR_ROUTES, pathname) && (
          <TopNavBar isWhite={isWhitePage} />
        )
      )}

      <Modal />

      <div className="flex min-h-screen flex-1 flex-col">
        <main className="relative flex flex-1 flex-col">
          <Routes>
            <Route path="/signup/success" element={<SignUpSuccessPage />} />
            <Route
              path="/signup"
              element={<AuthRoute element={<SignupPage />} guestOnly />}
            />

            <Route path="/login" element={<LoginPage />} />

            <Route
              path="/find-password/send-email"
              element={<SendEmailPage />}
            />
            <Route path="/find-password" element={<FindPasswordPage />} />
            <Route
              path="/part-time-job/:id/apply"
              element={<PartTimeJobApplyPage />}
            />
            <Route
              path="/part-time-job/:id"
              element={<PartTimeJobDetailPage />}
            />
            <Route path="/part-time-job" element={<PartTimeJobPage />} />

            <Route
              path="/payment-request/:id"
              element={<PaymentRequestDetailPage />}
            />
            <Route path="/payment-request" element={<PaymentRequestPage />} />

            <Route path="/contract/:id" element={<ContractDetailPage />} />
            <Route path="/contract/add" element={<ContractAddPage />} />

            <Route
              path="/reset-password/success"
              element={<ResetPasswordSuccessPage />}
            />
            <Route
              path="/reset-password/:resetToken"
              element={<ResetPasswordPage />}
            />

            <Route path="/resume/:resumeId" element={<ResumeAddPage />} />

            <Route path="/mypage/resume" element={<ResumeEditPage />} />
            <Route path="/mypage/edit" element={<EditPage />} />
            <Route path="/mypage" element={<MyPage />}>
              <Route
                path="/mypage"
                element={<Navigate replace to="/mypage/history" />}
              />
              <Route path="/mypage/history" element={<HistoryPage />} />

              <Route path="/mypage/like" element={<LikePage />} />
            </Route>

            <Route path="/notice/:id" element={<NoticeDetailPage />} />
            <Route path="/notice" element={<NoticePage />} />

            <Route path="/main" element={<MainPage />} />
            <Route path="/" element={<HomePage />} />
          </Routes>
        </main>
        <div className="mt-20">
          <Footer />
        </div>
      </div>
    </>
  );
};
