import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { TextField } from '../../../../components/TextField';
import { useChangePasswordMutation } from '../../../../hooks/auth';
interface FormValues {
  newPassword: string;
  newPasswordConfirm: string;
}

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { resetToken = '' } = useParams<{ resetToken: string }>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  const { mutate: changePassword } = useChangePasswordMutation({
    onSuccess: () => navigate('/reset-password/success'),
  });

  return (
    <>
      <div className="mx-5 mb-36 mt-14 grid place-items-center md:mx-0">
        <form
          className="flex w-full max-w-md flex-col space-y-4"
          onSubmit={handleSubmit((data) => {
            changePassword({ resetToken, password: data.newPassword });
          })}
        >
          <h1 className="mb-5 text-32 font-semibold text-gray-900">
            새로운 비밀번호 입력
          </h1>
          <div className="space-y-4 pb-10">
            <TextField
              type="password"
              label="새 비밀번호"
              compulsory
              placeholder="최소 6자리의 새 비밀번호를 입력해주세요."
              helper={errors.newPassword?.message}
              {...register('newPassword', {
                required: '이름을 입력해주세요',
                minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
                maxLength: {
                  value: 20,
                  message: '최대 20자 까지만 가능합니다',
                },
              })}
              name="newPassword"
            />

            <TextField
              type="password"
              label="새 비밀번호 확인"
              compulsory
              placeholder="비밀번호를 재입력해주세요."
              helper={errors.newPasswordConfirm?.message}
              {...register('newPasswordConfirm', {
                required: '이름을 입력해주세요',
                minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
                maxLength: {
                  value: 20,
                  message: '최대 20자 까지만 가능합니다',
                },
                validate: (value) => {
                  if (value !== watch().newPassword)
                    return '비밀번호가 일치하지 않습니다.';
                },
              })}
              name="newPasswordConfirm"
            />
          </div>
          <Button
            text="비밀번호 재설정"
            className="filled-brand-1 "
            type="submit"
          />
        </form>
      </div>
    </>
  );
};
