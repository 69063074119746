import { FC } from 'react';
import DaumPostcode from 'react-daum-postcode';
import { Icon } from './Icon';

interface AddressProps {
  setAddress: (address: string) => void;
  setIsOpenPost: (isOpenPost: boolean) => void;
  isOpenPost: boolean;
}

export const Address: FC<AddressProps> = ({
  setAddress,
  setIsOpenPost,
  isOpenPost,
}) => {
  const onCompletePost = (data: any) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setAddress(fullAddress);
    setIsOpenPost(false);
  };
  return (
    <div className="fixed inset-0 z-50  flex  items-center justify-center bg-black/50  ">
      <div className=" w-screen px-4 md:h-1/2 md:w-1/2">
        <div className="grid place-items-end pb-3">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none  "
            onClick={() => setIsOpenPost(false)}
          >
            <Icon.X className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {isOpenPost ? (
          <DaumPostcode autoClose={false} onComplete={onCompletePost} />
        ) : null}
      </div>
    </div>
  );
};
