import { Contract } from '../api/contract/type';
import { ContractTable } from './ContractTable';

interface ContractWageViewTableProps {
  contract: Contract;
}

export function ContractWageViewTable({
  contract,
}: ContractWageViewTableProps) {
  const { payment, hourPay, regularHourPay } = contract;
  return (
    <>
      <div className="">
        <div className=" grid h-64 w-full grid-cols-6  divide-y divide-x rounded-md border">
          <ContractTable title="급여 구분" desc=" 시급" />
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              기본시급
            </p>
          </div>
          <div className="col-span-5 grid grid-cols-2 content-center items-center gap-2 px-4 ">
            <p>{payment.hourPay ? payment.hourPay : hourPay}원</p>
          </div>
          <ContractTable
            title="인건비 구성"
            desc={`통상시급 ${regularHourPay}원 기타수당
              ${
                payment.hourPay
                  ? payment.hourPay - regularHourPay > 0
                    ? payment.hourPay - regularHourPay
                    : 0
                  : hourPay - regularHourPay > 0
                  ? hourPay - regularHourPay
                  : 0
              }원`}
          />
          <ContractTable
            title="근무시간"
            desc="기본시급x(근무시간–공제시간)=1일임금"
          />
        </div>
      </div>
    </>
  );
}
