import { AxiosError } from 'axios';
import { useQuery } from 'react-query';
import { PATHS } from '../../api/applied-resume/path';
import { AppliedResume } from '../../api/applied-resume/type';
import { adminFetcher } from '../../plugins/react-query';

export function useAppliedResume(appliedResumeId: string) {
  return useQuery<AppliedResume, AxiosError>(
    `${PATHS.getOneById(+appliedResumeId)}`,
    adminFetcher
  );
}
