import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createPartTimeJob,
  deletePartTimeJob,
  getAllLikePartTimeJobsByUser,
  getAllPartTimeJobByCompany,
  getAllPartTimeJobs,
  getAllPartTimeJobsByAdmin,
  getAllPartTimeJobsByPublic,
  updatePartTimeJob,
} from '../../api/part-time-job';
import { PATHS } from '../../api/part-time-job/paths';
import {
  CreatePartTimeJobDto,
  PartTimeJob,
  UpdatePartTimeJobDto,
} from '../../api/part-time-job/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { meState } from '../../plugins/ridge';
import { Paginated, PaginationDto, User } from '../../types';

export function useAllPartTimeJobs(
  paginationDto: PaginationDto,
  me: User | null
) {
  return useQuery<Paginated<PartTimeJob>, AxiosError>(
    [me ? PATHS.ROOT : PATHS.PUBLIC, paginationDto],
    () =>
      me
        ? getAllPartTimeJobs(paginationDto)
        : getAllPartTimeJobsByPublic(paginationDto),
    { keepPreviousData: true }
  );
}

export function useAllPartTimeJobsByAdmin(paginationDto: PaginationDto) {
  return useQuery<Paginated<PartTimeJob>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllPartTimeJobsByAdmin(paginationDto)
  );
}

export function useAllPartTimeJobsByCompany(
  paginationDto: PaginationDto,
  companyId: number
) {
  return useQuery<Paginated<PartTimeJob>, AxiosError>(
    [PATHS.getAllByCompanyId(companyId), paginationDto],
    () => getAllPartTimeJobByCompany(paginationDto, companyId)
  );
}

export function useLikePartTimeJobsByUser(paginationDto: PaginationDto) {
  return useQuery<Paginated<PartTimeJob>, AxiosError>(
    [PATHS.LIKED, paginationDto],
    () => getAllLikePartTimeJobsByUser(paginationDto)
  );
}

export function usePartTimeJobById(id: number) {
  const me = meState.useValue();
  return useQuery<PartTimeJob, AxiosError>(
    me ? PATHS.getOneById(id) : PATHS.getPublicId(id),
    fetcher,
    {
      enabled: !!id,
    }
  );
}

export function usePartTimeJobAdminById(id: number) {
  return useQuery<PartTimeJob, AxiosError>(PATHS.getOneById(id), adminFetcher, {
    enabled: !!id,
  });
}

export const useCreatePartTimeJobMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createPartTimeJobDto: CreatePartTimeJobDto) =>
      createPartTimeJob(createPartTimeJobDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('공고 등록에 성공했습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('공고 등록에 실패했습니다.');
      },
    }
  );
};

export const useUpdatePartTimeJobMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updatePartTimeJobDto: UpdatePartTimeJobDto) =>
      updatePartTimeJob(updatePartTimeJobDto),
    {
      onSuccess: (_, updatePartTimeJobDto) => {
        onSuccess && onSuccess();
        toast.success('수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getOneById(updatePartTimeJobDto.id)
        );
      },
      onError: () => {
        toast.error('저장에 실패했습니다.');
      },
    }
  );
};

export const useDeletePartTimeJobMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deletePartTimeJob(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('공고 삭제에 성공했습니다.');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('공고 삭제에 실패했습니다.');
    },
  });
};
