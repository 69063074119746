import { stringify } from 'qs';
import { PaymentData } from '../../admin/pages/payment-request/PaymentRequestPage';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreatePaymentDto, DeletePaymentDto } from './type';

export function createPayment(createPaymentDto: CreatePaymentDto) {
  return api.post(PATHS.ROOT, createPaymentDto);
}

export function updatePayment(payments: PaymentData[]) {
  return adminApi.patch(PATHS.ROOT, payments);
}

export function deletePayment(deletePaymentDto: DeletePaymentDto) {
  return adminApi.delete(PATHS.ROOT, { data: { ids: deletePaymentDto.ids } });
}

export function getAllPaymentsByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function downloadExcel(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.getExcel}${queryString}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}

export function downloadTransferExcel(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.getTransferExcel}${queryString}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}

export function downloadOnedayExcel(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.getOnedayExcel}${queryString}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}
