import React from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useNavigate, useParams } from 'react-router-dom';
import { MobileTopBar } from '../../../components/MobileTopBar';
import { useNoticeById } from '../../../hooks/notice';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

export const NoticeDetailPage = () => {
  const navigate = useNavigate();
  const { id: noticeId } = useParams<{ id: string }>();
  if (!noticeId) {
    return <></>;
  }
  const { data: notice } = useNoticeById(+noticeId);

  if (!notice) {
    return null;
  }

  const { title, description, createdAt } = notice;

  return (
    <>
      <MobileTopBar title="공지사항" />
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <div className="flex w-full max-w-5xl flex-col">
          <div className="space-y-5 border-b pb-5">
            {/* <button onClick={() => navigate(-1)}>
              <Icon.ArrowLeft />
            </button> */}
            <div className="space-y-4">
              <p className="break-words text-24 font-medium">{title}</p>
              <p className="text-gray-500">
                {utcToLocalFormat(
                  createdAt.toLocaleString(),
                  MomentFormat.YYYYMMDD
                )}
              </p>
            </div>
          </div>
          <div className="grids mt-8">
            <div className="whitespace-pre-wrap break-words">
              <FroalaEditorView model={description} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
