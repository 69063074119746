import { ButtonHTMLAttributes, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '../../components/Icon';

export interface ExcelDownButton
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
}

export const ExcelDownButton: FC<ExcelDownButton> = ({
  children,
  text,
  to,
  onClick,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <button
      className={`button flex h-12 items-center justify-center space-x-2 border bg-white px-7 text-15 font-semibold text-gray-900`}
      onClick={to ? () => navigate(to) : onClick}
      {...props}
    >
      <span>{text ?? children}</span>
      <Icon.Download className="text-gray-600" />
    </button>
  );
};
