import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { CreateContractDto } from '../../api/contract/type';
import { uploadFile } from '../../api/file';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import ContractDateTable from '../../components/ContractDateTable';
import { ContractDesc } from '../../components/ContractDesc';
import ContractInfoTable from '../../components/ContractInfoTable';
import ContractWageAddTable from '../../components/ContractWageAddTable';
import { Icon } from '../../components/Icon';
import { ContractRequiredModal } from '../../components/modal/contractRequiredModal';
import { ContractSubmitModal } from '../../components/modal/contractSubmitModal';
import { HOUR_PAY, SCV } from '../../constants';
import { useCreateContractMutation } from '../../hooks/contract';
import { useMyResume } from '../../hooks/resume';
import { useMe } from '../../hooks/user';

export interface ContractTimeList {
  startHour?: number;
  startMin?: number;
  endHour?: number;
  endMin?: number;
}

export const ContractAddPage = () => {
  const { data: me } = useMe();
  const { data: myResume } = useMyResume();
  const today = new Date();
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isDisabledTime, setIsDisableTime] = useState<boolean>(true);
  const [timeList, setTimeList] = useState<ContractTimeList>({});
  const [receiptUrl, setReceiptUrl] = useState<undefined | FileList | null>();
  const [companyId, setCompanyId] = useState<number>();
  const [contractState, setContractState] = useState<CreateContractDto>({
    userName: '', //유저 이름
    userPersonalId: '', //유저 주민등록 번호
    userPhone: '', // 유저 휴대전화
    userAddress: '', // 유저 주소
    userAddressDetail: '', // 유저 주소 상세
    workPlace: '', // 근무지
    contractDate: '', // 계약 일시
    startWorkTime: '', // 근무 시작 시간
    endWorkTime: '', // 근무 종료 시간
    hourPay: HOUR_PAY, // 시급
    scvName: '', // SCV 회사 이름
    scvPhone: '', // SCV 회사 연락처
    scvAddress: '', // SCV 회사 주소
    scvRepresentive: '', //SCV회사 대표자명
    regularHourPay: HOUR_PAY,
  });
  const [showContractSubmitModal, setShowContractSubmitModal] = useState(false);
  const [showContractRequiredModal, setShowContractRequiredModal] =
    useState(false);
  const { mutate: createContractMuate } = useCreateContractMutation(() =>
    navigate(-1)
  );

  const onAdd = () => {
    if (
      !contractState.workPlace ||
      !contractState.startWorkTime ||
      !contractState.endWorkTime ||
      !contractState.hourPay ||
      !isChecked
    ) {
      setShowContractRequiredModal(true);
      return;
    }
    setShowContractSubmitModal(true);
  };

  const _convertState = async (contractState: CreateContractDto) => {
    let _receiptUrl = '';
    if (receiptUrl instanceof FileList && receiptUrl[0]) {
      await uploadFile(receiptUrl[0]).then(
        (res: AxiosResponse<string>) => (_receiptUrl = res.data)
      );
    }
    const newContracts = {
      ...contractState,
      contractDate: contractState.contractDate,
      receiptUrl: _receiptUrl,
    };
    return newContracts;
  };

  useEffect(() => {
    if (!contractState) {
      return;
    }
    if (contractState.contractDate) {
      setIsDisableTime(false);
      return;
    }
    setIsDisableTime(true);
  });

  useEffect(() => {
    if (
      contractState.contractDate &&
      timeList.startHour !== undefined &&
      timeList.startMin !== undefined &&
      timeList.endHour !== undefined &&
      timeList.endMin !== undefined
    ) {
      const contractDate = format(
        new Date(contractState.contractDate),
        'MM/dd/yyyy'
      );
      const startTime = new Date(
        contractDate + ' ' + timeList.startHour + ':' + timeList.startMin
      );

      const endTime = new Date(
        contractDate + ' ' + timeList.endHour + ':' + timeList.endMin
      );

      setContractState({
        ...contractState,
        startWorkTime: startTime.toISOString(),
        endWorkTime: endTime.toISOString(),
      });
    }
  }, [timeList, contractState.contractDate]);

  return (
    <>
      <ContractRequiredModal
        open={showContractRequiredModal}
        onClose={() => setShowContractRequiredModal(false)}
      />

      <ContractSubmitModal
        open={showContractSubmitModal}
        onClose={() => setShowContractSubmitModal(false)}
        onClick={async () => {
          createContractMuate(await _convertState(contractState));
        }}
      />

      <div className="mt-32 grid w-[1024px] place-items-center md:w-full">
        <div className="mt-8 w-full max-w-4xl">
          <h2 className="mt-10 text-32 font-bold leading-normal">
            근로계약서 작성(급여신청)
          </h2>
          <h4 className="mt-10">일일근로계약서</h4>
          <div className="mt-5 grid gap-y-12">
            <ContractInfoTable
              contractState={contractState}
              setContractState={setContractState}
              setCompanyId={(id: number) => setCompanyId(id)}
            />
            <ContractDesc
              title="1. 계약기간"
              desc="본 계약은 1일 단위의 체결이며, 업무시간 종료시 자동 계약만료된다."
            />
            <ContractDesc
              title="2. 근무장소"
              desc="위 사용자와 근로자가 협의한 위 근무장소.(본인사정으로 인한 변동시 바뀔수 있음)"
            />
            <ContractDesc
              title="3. 해당업무"
              desc="사용자와 근로자가 사전에 협의한 업무. [ 홀서빙, 주방보조, 기물보조, 안내 등]
            "
            />
            <div className="space-y-2">
              <ContractDesc title="4. 계약기간" />
              <ContractDateTable
                contractState={contractState}
                setContractState={setContractState}
                timeList={timeList}
                setTimeList={(time: ContractTimeList) => setTimeList(time)}
                disable={isDisabledTime}
              />
              <p className="text-15">
                ※ 근무시간은 실제로 근무한 시간을 기입해주세요.
              </p>
            </div>
            <div className="space-y-2">
              <ContractDesc title="5. 근로임금" />
              <ContractWageAddTable
                contractState={contractState}
                setContractState={setContractState}
                companyId={companyId || 0}
              />
              <div className="text-15">
                ※ 기본시급은 홈페이지에 공지된 시급이며 별도의 안내를 받으신분은
                따로 기입후 지급됨
                <br />※ 임금은 근무 후 익 주 월~수요일 19시 지급이며 근무업장
                사정에 따라 최대 7일, 최소 2시간 지연 입금 될 수 있다.
                <br />※ 신한은행 외 타행으로 급여이체 받을 시 이체수수료 500원이
                공제되어 지급됩니다
              </div>
            </div>
            <ContractDesc
              title="6. 적용제외"
              desc="근로기준법상 단기간 근로자는 근로기준법의 주휴일, 연차휴가, 퇴직금 규정을 적용하지 아니하며, 기타 관련 사항은 회사 규정에 따른다."
            />
            <ContractDesc
              title="7. 고용보험"
              desc="임금지급 시 근로자부담금인 고용보험료[급여액의 0.65%]는 공제 후 입금된다."
            />
            <ContractDesc
              title="8. 손해배상"
              desc="근로자가 업무수행 중 업무와 무관하게 고의 또는 중과실로 사용자 와 근무장소에 재산상의 손해를 끼쳤을 때에는 원상회복 또는 손해배상을 하여야 한다."
            />
            <ContractDesc
              title="9. 기타사항"
              desc="본 계약에 명시하지 아니한 사항은 사용자가 별도로 정한 회사규정 및 근로기준법 등을 적용한다."
            />
            <ContractDesc
              title="10. 개인정보"
              desc="본 계약과 관련하여 사용자는 위 개인정보를 엄격히 관리하며, 근로자는 위 사용자가 DB(회원관리) 및 근로소득관련 제출증빙 등으로 근로자의 개인정보 보관 등에 관하여 동의한다."
            />
            <div>
              <ContractDesc title="비용청구(영수증 첨부)" />
              <div className="flex w-max items-center justify-between rounded-md border border-gray-100 shadow-sm">
                <label htmlFor="receipt-url-upload">
                  {receiptUrl instanceof FileList ? (
                    <>
                      <img
                        className="h-40 w-40 rounded-md object-cover object-center"
                        src={URL.createObjectURL(receiptUrl[0])}
                        alt=""
                        crossOrigin="anonymous"
                      />
                    </>
                  ) : (
                    <div className="flex cursor-pointer flex-col items-center justify-center p-4 md:flex-row md:justify-start md:space-x-4">
                      <div className="rounded-full bg-green-500 p-1.5">
                        <Icon.Plus className="wh-5 text-white" />
                      </div>
                      <div>
                        <div className="text-center text-14 text-gray-600">
                          영수증 사진을 업로드해주세요!
                        </div>
                        <div className="text-12 text-gray-600">
                          PNG, JPG, JPEG 가능
                        </div>
                      </div>
                    </div>
                  )}
                  <input
                    onChange={(e) => {
                      const files = e.target.files;
                      if (!files || files.length === 0) return;
                      if (files.length === 0) return;
                      setReceiptUrl(files);
                    }}
                    type="file"
                    accept={'image/*'}
                    id="receipt-url-upload"
                    className="hidden"
                  />
                </label>
              </div>
            </div>
            <p className="place-self-center">
              {moment(today).format('yyyy-MM-DD')}
            </p>
            <div className="grid w-full grid-cols-2 divide-x rounded-md border ">
              <div>
                <div className=" grid h-10 place-items-center border-b bg-sub-3">
                  <p>사용자</p>
                </div>
                <div className="grid h-20 content-center px-4">
                  <p>
                    {SCV.companyName} {SCV.user}
                  </p>
                </div>
              </div>
              <div>
                <div className=" grid h-10 place-items-center border-b bg-sub-3">
                  <p>근로자</p>
                </div>
                <div className="grid h-20 content-center px-4">
                  <div className="flex space-x-2">
                    <p className="text-15 text-gray-700">이름</p>
                    <p>{me?.name}</p>
                  </div>
                  <div className="flex space-x-2">
                    <p className="text-15 text-gray-700">주민등록번호</p>
                    <p>{myResume?.personalId}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center space-y-5 place-self-center">
              <div className="flex items-center space-x-2">
                <Checkbox
                  className="h-5 w-5"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.currentTarget.checked);
                  }}
                />
                <h4>확인 동의</h4>
              </div>
              <p>
                ※ 급여/비용 청구 페이지에서 단기간근로계약서 및 일일계약서를
                확인할 수 있습니다.
              </p>
            </div>
            <div className="place-self-center">
              <Button
                text="제출하기 (급여신청)"
                className="filled-brand-1 w-80"
                onClick={() => {
                  onAdd();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
