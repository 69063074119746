import { useParams } from 'react-router-dom';
import { SCV } from '../constants';
import { useContractById } from '../hooks/contract';

export function ContractInfoViewTable() {
  const { id: contractId } = useParams<{ id: string }>();
  const { data: contract } = useContractById(+(contractId as string) || 0);
  return (
    <>
      <div className="">
        <div className=" grid h-72 w-full grid-cols-7 divide-y divide-x rounded-md border">
          <div className="row-span-2 grid place-items-center text-15 text-gray-700">
            <p>사용자</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">회사명</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{SCV.companyName}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">연락처</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{SCV.phone}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">소재지</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>{SCV.address}</p>
          </div>
          <div className="row-span-3 grid place-items-center text-15 text-gray-700">
            <p>근로자</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">성명</p>
          </div>
          <div className="col-span-2 grid content-center px-4 ">
            <p>{contract?.userName}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">주민등록번호</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{contract?.userPersonalId}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">휴대전화</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>{contract?.userPhone}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">주소</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>
              {contract?.userAddress} {contract?.userAddressDetail}
            </p>
          </div>
          <div className="col-span-2 grid place-items-center bg-sub-2 ">
            <p className="text-15 text-gray-700 after:ml-0.5 after:text-brand-1 after:content-['*']">
              근무장소
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>{contract?.workPlace}</p>
          </div>
        </div>
      </div>
    </>
  );
}
