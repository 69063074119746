import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { modalState } from '../../plugins/ridge';
import { Button } from '../Button';
import { AnimationLayout } from './AnimationLayout';

export interface ModalInfoProps {
  title?: string;
  bodyText?: string;
  primaryButtonText?: string;
  primaryButtonClassName?: string;
  primaryClick?: () => void;
  secondaryButtonText?: string;
  secondaryClick?: () => void;
  secondaryButtonClassName?: string;
}

interface ModalProps {}

export const Modal: FC<ModalProps> = () => {
  const modal = modalState.useValue();
  const {
    title,
    bodyText,
    primaryClick,
    primaryButtonText,
    primaryButtonClassName,
    secondaryClick,
    secondaryButtonText,
    secondaryButtonClassName,
  } = modal;

  if (!modal.title) return <></>;
  return (
    <AnimationLayout open={!!title}>
      <div className="my-8 w-full max-w-md transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
        <h3 className="font-semibold">{title}</h3>
        <p className="whitespace-pre-wrap font-medium text-gray-700">
          {bodyText}
        </p>

        <div className="space-y-3 pt-8">
          {primaryButtonText && (
            <Button
              onClick={() => {
                primaryClick && primaryClick();
                modalState.reset();
              }}
              text={primaryButtonText}
              className={`${
                primaryButtonClassName
                  ? primaryButtonClassName
                  : 'filled-brand-1'
              } w-full`}
            />
          )}

          {secondaryButtonText && (
            <Button
              onClick={() => {
                secondaryClick && secondaryClick();
                modalState.reset();
              }}
              text={secondaryButtonText}
              className={`${
                secondaryButtonClassName
                  ? secondaryButtonClassName
                  : 'outlined-gray-900 border-gray-400'
              } w-full`}
            />
          )}
        </div>
      </div>
    </AnimationLayout>
  );
};

export function useModal() {
  const navigate = useNavigate();

  const example = () =>
    modalState.set({
      title: '나머지 주소 미입력',
      bodyText:
        '나머지 주소를 입력하지 않으셨습니다.\n이대로 저장하시겠습니까?',
      primaryButtonText: '취소',
      secondaryButtonText: '저장',
      secondaryClick: () => {
        navigate('/subscription-profile/address/form');
      },
    });

  const loginRequired = () =>
    modalState.set({
      title: '로그인',
      bodyText: '로그인 후에 확인이 가능합니다. \n 로그인하시겠습니까?',
      primaryButtonText: '로그인하기',
      primaryClick: () => {
        navigate('/login');
      },
    });
  const deleteCertificate = () =>
    modalState.set({
      title: '삭제',
      bodyText: '특기사항을 삭제하시겠습니까?',
      primaryButtonText: '로그인하기',
    });
  const deleteFile = (primaryClick: () => void) =>
    modalState.set({
      title: '삭제',
      bodyText: '파일을 삭제하시겠습니까?',
      primaryButtonText: '삭제',
      secondaryButtonText: '취소',
      primaryClick,
    });
  const resumeSkip = () =>
    modalState.set({
      title: '지원서 작성 건너뛰기',
      bodyText:
        '지원서를 작성하지 않으면 알바지원이 불가합니다. \n지원서 작성을 건너뛰시겠습니까?',
      primaryButtonText: '지원서 작성 건너뛰기',
      primaryClick: () => {
        navigate('/main', { replace: true });
      },
    });

  const resumeRequired = () =>
    modalState.set({
      title: '필수 입력사항 확인',
      bodyText: '사진, 기본정보, 계좌정보는 필수 입력사항입니다.',
      primaryButtonText: '지원서 마저 작성하기',
    });

  const contractSubmit = () =>
    modalState.set({
      title: '계약서 제출(급여신청)',
      bodyText: '제출한 계약서는 수정이 불가합니다. \n제출하시겠습니까?',
      primaryButtonText: '계약서 제출하기',
    });
  const contractRequired = () =>
    modalState.set({
      title: '필수 입력사항 확인',
      bodyText:
        '근무장소, 계약일시, 근무시간, 급여구분, 기본시급, 확인동의는 \n 필수로 작성해야 합니다.',
      primaryButtonText: '계약서 마저 작성하기',
    });
  const partTimeLogin = () =>
    modalState.set({
      title: '로그인',
      bodyText: '로그인 후에 지원이 가능합니다.\n로그인하시겠습니까?',
      primaryButtonText: '로그인하기',
      primaryClick: () => {
        navigate('/login');
      },
    });
  const partTimeResume = () =>
    modalState.set({
      title: '지원서 작성',
      bodyText:
        '지원서 작성 완료 후 지원이 가능합니다.\n지원서를 작성하러 가시겠습니까?',
      primaryButtonText: '지원서 작성하기',
      primaryClick: () => {
        navigate(`/mypage/resume`);
      },
    });
  //공고가 삭제되었거나 존재하지 않는 공고일 경우 : 마이페이지 지원한 알바, 저장한 공고에서 사용
  const deletedPartTimeJob = () =>
    modalState.set({
      title: '삭제된 공고',
      bodyText: '존재하지 않는 공고입니다.',
      primaryButtonText: '확인',
    });
  const waiting = () =>
    modalState.set({
      title: '준비 중',
      bodyText: '준비 중인 서비스입니다.',
      primaryButtonText: '확인',
    });
  const requirePC = () =>
    modalState.set({
      title: 'PC버전에서 확인 가능',
      bodyText: 'PC버전에서 확인 가능한 페이지입니다.',
      primaryButtonText: '확인',
    });
  const deletePayments = (onSuccess: () => void) => {
    modalState.set({
      title: '급여 신청 삭제',
      bodyText: '선택된 급여신청을 삭제하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () => onSuccess(),
    });
  };

  const removeUserModal = (onSuccess: () => void) => {
    modalState.set({
      title: '유저 삭제',
      bodyText: '해당 유저를 탈퇴처리 하시겠습니까?',
      primaryButtonText: '확인',
      primaryClick: () => onSuccess(),
    });
  };
  return {
    example,
    loginRequired,
    deleteCertificate,
    deleteFile,
    resumeSkip,
    resumeRequired,
    contractSubmit,
    contractRequired,
    partTimeLogin,
    partTimeResume,
    deletedPartTimeJob,
    waiting,
    deletePayments,
    removeUserModal,
  };
}
