import { useState } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllCompaniesByAdmin } from '../../../hooks/company';
import { sortDescString } from '../../../utils';
import AdminH1 from '../../components/AdminH1';

export const CompanyList = () => {
  const [page, setPage] = useState(1);
  const { data: allCompainesByAdmin } = useAllCompaniesByAdmin({
    page,
    itemsPerPage: ITEMS_PER_PAGE_MD,
    sort: sortDescString,
  });

  return (
    <>
      <div className="flex items-center justify-between py-2 px-8">
        <AdminH1>회사관리</AdminH1>
        <Button
          className=" shadow-xs w-30 h-12 bg-gray-800 text-sm text-white"
          to="/admin/company/add"
        >
          회사 추가하기
        </Button>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>회사명</Table.Th>
              <Table.Th>담당자</Table.Th>
              <Table.Th>연락처</Table.Th>
              <Table.Th>문의전화</Table.Th>
              <Table.Th>홈페이지</Table.Th>
              <Table.Th>등록된 공고</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allCompainesByAdmin &&
              map(allCompainesByAdmin.items, (company) => (
                <Table.Row key={company.id}>
                  <Table.Td>{company.id}</Table.Td>
                  <Table.Td>{company.companyName}</Table.Td>
                  <Table.Td>{company.contactPerson}</Table.Td>
                  <Table.Td>{company.phone}</Table.Td>
                  <Table.Td>{company.customerService}</Table.Td>
                  <Table.Td>{company.hompageUrl}</Table.Td>
                  <Table.Td>{company.partTimeJobsCount}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/company/${company.id}/info`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allCompainesByAdmin?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={allCompainesByAdmin?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
