import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createUser,
  getAllUsersByAdmin,
  removeUserByAdmin,
  updateMe,
  updateUserByAdmin,
} from '../../api/user';
import { PATHS } from '../../api/user/paths';
import { CreateUserDto, UpdateUserDto, User } from '../../api/user/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useMe() {
  return useQuery<User, AxiosError>(PATHS.ME, fetcher);
}

export function useDetailMe() {
  return useQuery<User, AxiosError>(PATHS.ME_DETAIL, fetcher);
}

export function useAllUsersByAdmin(paginationDto: PaginationDto) {
  return useQuery<Paginated<User>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllUsersByAdmin(paginationDto),
    { keepPreviousData: true }
  );
}

export function useUserByAdmin(id: number) {
  return useQuery<User, AxiosError>(PATHS.getOneById(id), adminFetcher, {
    enabled: !!id,
  });
}

export const useCreateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createUserDto: CreateUserDto) => createUser(createUserDto),
    {
      onSuccess: () => {
        toast.success('회원가입 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('회원가입 실패');
      },
    }
  );
};

export const useUpdateMeMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateUserDto: UpdateUserDto) => updateMe(updateUserDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.ME);
      },
      onError: (e: AxiosError) => {
        if (e.response?.data?.status === 409) {
          toast.error(e.response?.data?.message);
        } else {
          toast.error('저장에 실패했습니다.');
        }
      },
    }
  );
};

export const useUpdateUserByAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateUserDto: UpdateUserDto) => updateUserByAdmin(updateUserDto),
    {
      onSuccess: (_, updateUserDto) => {
        toast.success('수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.getOneById(updateUserDto.id));
      },
      onError: (err: AxiosError) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('저장에 실패했습니다.');
        }
      },
    }
  );
};

export const useRemoveUserByAdminMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => removeUserByAdmin(id), {
    onSuccess: (res, userId) => {
      toast.success('삭제 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(userId));
    },
    onError: () => {
      toast.error('삭제 실패');
    },
  });
};
