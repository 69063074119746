import { useState } from 'react';
import { map } from 'lodash';
import { LikeCard } from '../../../components/LikeCard';
import { Pagination } from '../../../components/Pagination';
import { ITEMS_PER_PAGE_SM } from '../../../constants';
import { useLikePartTimeJobsByUser } from '../../../hooks/part-time-job';
import { sortDescString } from '../../../utils';

export const LikePage = () => {
  const [page, setPage] = useState(1);

  const { data: likeAllPartTimeJobsByUser } = useLikePartTimeJobsByUser({
    page,
    itemsPerPage: ITEMS_PER_PAGE_SM + 5,
    sort: sortDescString,
  });

  if (likeAllPartTimeJobsByUser?.items.length === 0) {
    return (
      <>
        <div className="flex justify-center py-20 text-gray-800 ">
          저장한 공고가 없습니다.
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mt-32">
        <div className="grid grid-cols-1 gap-x-7 gap-y-14 md:grid-cols-3 md:gap-y-11">
          {likeAllPartTimeJobsByUser &&
            map(likeAllPartTimeJobsByUser.items, (bookmark) => (
              <LikeCard partTimeJob={bookmark} />
            ))}
        </div>
      </div>
      {likeAllPartTimeJobsByUser && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_SM + 5}
          setPage={setPage}
          totalItemCount={
            likeAllPartTimeJobsByUser?.pagination.totalItemCount || 0
          }
          page={page}
        />
      )}
    </>
  );
};
