import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import AdminH1 from '../../components/AdminH1';
import { Tab } from '../../components/Tab';

export const UserManage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams<{ id: string }>();
  return (
    <>
      <div className="px-8 pt-8">
        <AdminH1>회원관리</AdminH1>
      </div>

      <nav className="space-x-6 px-8 pb-4 ">
        <Tab
          title="회원 정보"
          onClick={() => navigate(`/admin/users/${id}/info`)}
          selected={pathname === `/admin/users/${id}/info`}
        />
        <Tab
          title="지원서 정보"
          onClick={() => navigate(`/admin/users/${id}/resume`)}
          selected={pathname === `/admin/users/${id}/resume`}
        />
        <Tab
          title="알바 지원 내역"
          onClick={() => navigate(`/admin/users/${id}/application`)}
          selected={pathname === `/admin/users/${id}/application`}
        />
        <Tab
          title="급여지급내역"
          onClick={() => navigate(`/admin/users/${id}/payment-request`)}
          selected={pathname === `/admin/users/${id}/payment-request`}
        />
        <Tab
          title="업장시급"
          onClick={() => navigate(`/admin/users/${id}/hours-pay`)}
          selected={pathname === `/admin/users/${id}/hours-pay`}
        />
      </nav>

      <Outlet />
    </>
  );
};
