import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createNotice,
  deleteNotice,
  getAllNotices,
  getAllNoticesByAdmin,
  updateNotice,
} from '../../api/notice';
import { PATHS } from '../../api/notice/path';
import {
  CreateNoticeDto,
  Notice,
  UpdateNoticeDto,
} from '../../api/notice/type';
import { fetcher } from '../../plugins/react-query';
import { adminFetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllNotices(paginationDto: PaginationDto) {
  return useQuery<Paginated<Notice>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllNotices(paginationDto),
    { keepPreviousData: true }
  );
}

export function useAllNoticesByAdmin(paginationDto: PaginationDto) {
  return useQuery<Paginated<Notice>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllNoticesByAdmin(paginationDto),
    { keepPreviousData: true }
  );
}

export function useNoticeById(id: number) {
  return useQuery<Notice, AxiosError>(PATHS.getOneById(id), fetcher, {
    enabled: !!id,
  });
}

export function useNoticeByAdminId(id: number) {
  return useQuery<Notice, AxiosError>(PATHS.getOneById(id), adminFetcher, {
    enabled: !!id,
  });
}

export const useCreateNoticeMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createNoticeDto: CreateNoticeDto) => createNotice(createNoticeDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('공지 추가 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('공지 추가 실패');
      },
    }
  );
};

export const useUpdateNoticeMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateNoticeDto: UpdateNoticeDto) => updateNotice(updateNoticeDto),
    {
      onSuccess: (_, updateNoticeDto) => {
        onSuccess && onSuccess();
        toast.success('공지 수정 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.getOneById(updateNoticeDto.id));
      },
      onError: () => {
        toast.error('공지 수정 실패');
      },
    }
  );
};

export const useDeleteNoticeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteNotice(id), {
    onSuccess: (_, id) => {
      toast.success('공지 삭제 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('공지 삭제 실패');
    },
  });
};
