import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { UpdateCompanyDto } from '../../../api/company/type';
import { Address } from '../../../components/Address';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import {
  useCompanyById,
  useDeleteCompanyMutation,
  useUpdateCompanyMutation,
} from '../../../hooks/company';

interface FormValues {
  companyName: string;
  contactPerson: string;
  phone: string;
  customerService: string;
  email: string;
  hompageUrl: string;
  addressDetail: string;
  wayToCome: string;
  adminMemo: string;
}

export const CompanyDetail = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState<string>('');
  const { id: companyId } = useParams<{ id: string }>();
  const { data: company } = useCompanyById(+(companyId as string) || 0);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [haveAddress, setHaveAddress] = useState(true);
  const [triggerAddressValidation, setTriggerAddressValidation] =
    useState(false);
  const formSchema = Yup.object().shape({
    companyName: Yup.string().required('회사명을 입력해주세요'),
    contactPerson: Yup.string().required('담당자를 입력해주세요'),
    phone: Yup.string().required('연락처를 입력해주세요'),
    customerService: Yup.string(),
    email: Yup.string()
      .required('이메일을 입력해주세요')
      .matches(
        /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        '올바른 이메일 형식이 아닙니다.'
      ),
    hompageUrl: Yup.string(),
    addressDetail: Yup.string().required('상세주소를 입력해주세요'),
    wayToCome: Yup.string(),
    adminMemo: Yup.string(),
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(formSchema),
  });

  const { mutate: deleteCompanyMutate } = useDeleteCompanyMutation(() =>
    navigate(-1)
  );
  const onDelete = () => {
    if (!company) {
      return;
    }

    if (company.partTimeJobs?.length !== 0) {
      toast.error('공고가 남아있는 회사는 삭제할 수 없습니다.');
      return;
    }

    const deleteConfirm = window.confirm('회사를 삭제하시겠습니까?');
    if (deleteConfirm) {
      deleteCompanyMutate(company.id);
    }
  };

  const { mutate: updateCompanyMutate } = useUpdateCompanyMutation(() =>
    navigate(-1)
  );

  useEffect(() => {
    if (triggerAddressValidation) {
      if (!address) {
        setHaveAddress(false);
      } else {
        setHaveAddress(true);
      }
    }
  }, [address, triggerAddressValidation]);

  useEffect(() => reset(company), [reset, company]);

  useEffect(() => {
    if (company) {
      setAddress(company.address);
    }
  }, [company]);

  if (!company || !companyId) {
    return <></>;
  }
  return (
    <>
      <div>
        <form
          onSubmit={handleSubmit((data) => {
            const newData: UpdateCompanyDto = {
              ...data,
              address,
              id: +companyId,
            };
            haveAddress &&
              updateCompanyMutate({ ...newData, id: Number(companyId) });
          })}
        >
          <div className="grid grid-cols-1 gap-4 px-4 sm:px-6 md:grid-cols-2 md:px-8">
            <TextField
              label="회사명"
              helper={errors.companyName?.message}
              {...register('companyName')}
              compulsory
            />
            <TextField
              label="담당자"
              helper={errors.contactPerson?.message}
              {...register('contactPerson')}
              compulsory
            />
            <TextField
              label="연락처"
              helper={errors.phone?.message}
              {...register('phone')}
              compulsory
            />
            <TextField
              label="문의전화"
              helper={errors.customerService?.message}
              {...register('customerService')}
            />
            <TextField
              label="이메일"
              type="email"
              helper={errors.email?.message}
              {...register('email')}
              compulsory
            />
            <TextField
              label="홈페이지"
              helper={errors.hompageUrl?.message}
              {...register('hompageUrl')}
            />
            <div className="col-span-2 col-start-1 space-y-2 ">
              <Label compulsory>주소</Label>
              <div className="flex items-center space-x-2">
                <input
                  className="textfield flex-1 rounded-md"
                  placeholder="주소를 검색해주세요."
                  value={address}
                  disabled
                />
                <Button
                  type="button"
                  text="주소 검색"
                  onClick={() => setIsOpenPost(true)}
                  className="h-12 w-max border border-brand-1 text-brand-1"
                />
              </div>
              <TextField
                placeholder="상세주소 입력하기"
                helper={
                  (!haveAddress && '주소를 검색해주세요') ||
                  errors.addressDetail?.message
                }
                {...register('addressDetail')}
                name="addressDetail"
              />
              {isOpenPost && (
                <Address
                  setAddress={(address: string) => setAddress(address)}
                  isOpenPost={isOpenPost}
                  setIsOpenPost={(isOpenPost: boolean) =>
                    setIsOpenPost(isOpenPost)
                  }
                />
              )}
            </div>
          </div>
          <div className="mt-7 px-7">
            <Label text="오시는 길" />
            <TextArea className="h-40 w-full" {...register('wayToCome')} />
          </div>

          <div className="mt-7 px-7">
            <TextArea
              label="관리자 메모"
              placeholder="메모할 내용을 입력해주세요."
              labelClassname="text-left"
              className="h-20"
              {...register('adminMemo')}
            />
          </div>

          <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
            <Button
              text="delete"
              type="button"
              onClick={onDelete}
              className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
            />
            <Button
              text="Save"
              onClick={() => setTriggerAddressValidation(true)}
              className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
            />
          </div>
        </form>
      </div>
    </>
  );
};
