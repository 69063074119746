import { Fragment } from 'react';
import { map } from 'lodash';
import { AppliedResumeCareer } from '../api/applied-resume-career/type';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';

interface AppliedCareerListProps {
  appliedCareers: AppliedResumeCareer[];
}

export const AppliedCareerList = ({
  appliedCareers,
}: AppliedCareerListProps) => {
  return (
    <>
      <h3 className="mb-9">경력사항</h3>
      {appliedCareers &&
        map(appliedCareers, (appliedCareer: AppliedResumeCareer) => {
          return (
            <Fragment key={appliedCareer.id}>
              <div className="mb-8 border-b pb-8">
                <div className="grid grid-cols-1 gap-8">
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">업체명</p>
                    <p>{appliedCareer.company}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">직무내용</p>
                    <p>{appliedCareer.position}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">근무기간</p>
                    <span>
                      {utcToLocalFormat(
                        appliedCareer.workingStartAt.toLocaleString(),
                        MomentFormat.YYYYMMDD
                      )}
                    </span>
                    <span>{`  ~  `}</span>
                    <span>
                      {utcToLocalFormat(
                        appliedCareer.workingEndAt.toLocaleString(),
                        MomentFormat.YYYYMMDD
                      )}
                    </span>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">상세내용</p>
                    <p>{appliedCareer.description}</p>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
    </>
  );
};
