import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '../../components/Tab';

export const IntroductionManage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      <nav className="space-x-6 px-8 pb-4 ">
        <Tab
          title="회사 소개"
          onClick={() => navigate('/admin/introduction/company')}
          selected={pathname === '/admin/introduction/company'}
        />
        <Tab
          title="협력사 소개"
          onClick={() => navigate('/admin/introduction/partners')}
          selected={pathname === '/admin/introduction/partners'}
        />
      </nav>

      <Outlet />
    </>
  );
};
