import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { round, slice } from 'lodash';
import {
  KOREAN_PAYMENT_STATUS,
  Payment,
  PaymentStatus,
  Worker,
} from '../../../api/payment/type';
import { BankCode } from '../../../api/resume/type';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { Select } from '../../../components/Select';
import { Table } from '../../../components/Table';
import { TextField } from '../../../components/TextField';

interface PaymentRequestRowProps {
  payment: Payment;
  setPayment: (payment: Payment) => void;
  handleClick: () => void;
  isChecked: boolean;
  isHideExtra: boolean;
}

export const PaymentRequestRow = ({
  payment,
  setPayment,
  handleClick,
  isChecked,
  isHideExtra,
}: PaymentRequestRowProps) => {
  const {
    bankCode,
    worker,
    restTime,
    startWorkHour,
    endWorkHour,
    hourPay,
    totalWorkTime,
    baseAmount,
    overtimeHour,
    overtimePay,
    nightHour,
    nightPay,
    holidayPay,
    otherPay,
    totalExtraAmount,
    totalAmount,
    insurance,
    fee,
    incomeTax,
    deductedAmount,
    finalAmount,
    memo,
    contract,
  } = payment;
  const [restTimeInput, setRestTimeInput] = useState<string>(
    restTime ? String(restTime) : ''
  );
  const [overTimeHourInput, setOverTimeHourInput] = useState<string>('');
  const [nightHourInput, setNightHourInput] = useState<string>('');
  const [workerInput, setWorkerInput] = useState<Worker>();

  // 공제 시간 계산 함수
  const calRestTime = (endWorkHour: number, startWorkHour: number) => {
    if (+(endWorkHour - startWorkHour).toFixed(1) >= 8) {
      return 1;
    } else if (+(endWorkHour - startWorkHour).toFixed(1) > 4) {
      return 0.5;
    } else {
      return 0;
    }
  };

  //근무시간 계산 함수
  const calTotalWorkTime = (
    startWorkHour: number,
    endWorkHour: number,
    restTime: number
  ) => +(endWorkHour - startWorkHour - restTime).toFixed(1);

  //기본 급여 계산 함수
  const calBaseAmount = (hourPay: number, totalWorkTime: number) => {
    return hourPay * totalWorkTime;
  };

  //연장시간 계산 함수
  const calOvertimeHour = (
    startWorkHour: number,
    endWorkHour: number,
    restTime: number
  ) => {
    if (+(endWorkHour - startWorkHour - restTime).toFixed(1) > 8)
      return +(endWorkHour - startWorkHour - restTime).toFixed(1) - 8;
    else return 0;
  };

  //연장수당 계산 함수
  const calOvertimePay = (overtimeHour: number, hourPay: number) => {
    if (overtimeHour <= 0) return 0;
    return overtimeHour * hourPay * 0.5;
  };

  //야간시간 계산 함수
  const calNightHour = (
    startWorkHour: number,
    endWorkHour: number,
    restTime: number
  ) => {
    if (endWorkHour > 30 && startWorkHour >= 22) {
      return +(30 - 22 - restTime).toFixed(1);
    }

    if (endWorkHour > 30 && startWorkHour < 22) {
      return 8;
    }

    if (endWorkHour > 22 && startWorkHour >= 22) {
      return +(endWorkHour - startWorkHour - restTime).toFixed(1);
    }

    if (endWorkHour > 22 && startWorkHour < 22) {
      return +(endWorkHour - 22).toFixed(1);
    }
    return 0;
  };

  //야간수당 계산 함수
  const calNightPay = (nightHour: number, hourPay: number) => {
    if (nightHour <= 0) {
      return 0;
    }

    return hourPay * nightHour * 0.5;
  };

  //수당합계 계산 함수
  const calTotalExtraAmount = (
    overtimePay: number,
    nightPay: number,
    holidayPay: number,
    otherPay: number
  ) => {
    return overtimePay + nightPay + holidayPay + otherPay;
  };
  //보험료 계산 함수
  const calInsurance = (worker: Worker, totalAmount: number) => {
    if (worker === Worker.ONEDAY) {
      return round(totalAmount * 0.009);
    } else {
      return 0;
    }
  };

  //소득세 계산 함수
  const calIncomeTax = (worker: Worker, totalAmount: number) => {
    if (worker === Worker.ONEDAY) {
      return 0;
    } else {
      return round(totalAmount * 0.033);
    }
  };

  //타행 수수료 계산 함수
  const calFee = (bankCode: BankCode) => {
    if (bankCode === BankCode.SINHAN) {
      return 0;
    } else {
      return 500;
    }
  };

  //총 급여 계산 함수
  const calTotalAmount = (
    totalWorkTime: number,
    hourPay: number,
    totalExtraAmount: number
  ) => {
    return hourPay * totalWorkTime + totalExtraAmount;
  };

  //차감 금액 계산 함수
  const calDeductedAmount = (
    insurance: number,
    fee: number,
    incomeTax: number
  ) => {
    return insurance + fee + incomeTax;
  };

  //실 지급액 계산 함수
  const calFinalAmount = (totalAmount: number, deductedAmount: number) => {
    if (totalAmount > 0) {
      return totalAmount - deductedAmount;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const _restTime =
      restTimeInput === ''
        ? calRestTime(endWorkHour, startWorkHour)
        : +restTimeInput;
    const _totalWorkTime = calTotalWorkTime(
      startWorkHour,
      endWorkHour,
      _restTime
    );
    const _baseAmount = calBaseAmount(hourPay, _totalWorkTime);
    const _overTimeHour =
      overTimeHourInput === ''
        ? calOvertimeHour(startWorkHour, endWorkHour, _restTime)
        : +overTimeHourInput;
    const _overTimePay = calOvertimePay(_overTimeHour, hourPay);
    const _nightHour =
      nightHourInput === ''
        ? calNightHour(startWorkHour, endWorkHour, _restTime)
        : +nightHourInput;
    const _nightPay = calNightPay(_nightHour, hourPay);
    const _totalExtraAmount = calTotalExtraAmount(
      _overTimePay,
      _nightPay,
      holidayPay,
      otherPay
    );
    const _totalAmount = calTotalAmount(
      _totalWorkTime,
      hourPay,
      _totalExtraAmount
    );
    const _insurance = calInsurance(worker, _totalAmount);
    const _incomeTax = calIncomeTax(worker, _totalAmount);
    const _fee = calFee(bankCode);
    const _deductedAmount = calDeductedAmount(_insurance, _fee, _incomeTax);
    const _finalAmount = calFinalAmount(_totalAmount, _deductedAmount);

    setPayment({
      ...payment,
      restTime: _restTime,
      totalWorkTime: _totalWorkTime,
      baseAmount: _baseAmount,
      overtimeHour: _overTimeHour,
      overtimePay: _overTimePay,
      nightHour: _nightHour,
      nightPay: _nightPay,
      totalExtraAmount: _totalExtraAmount,
      totalAmount: _totalAmount,
      insurance: _insurance,
      incomeTax: _incomeTax,
      fee: _fee,
      worker: workerInput || worker || contract?.user?.worker,
      deductedAmount: _deductedAmount,
      finalAmount: _finalAmount,
    });
  }, [
    restTime,
    restTimeInput,
    nightHour,
    nightHourInput,
    overtimeHour,
    overTimeHourInput,
    startWorkHour,
    endWorkHour,
    worker,
    workerInput,
    hourPay,
    holidayPay,
    otherPay,
    bankCode,
  ]);

  useEffect(() => {
    if (!workerInput) {
      setWorkerInput(worker || contract?.user?.worker);
    }
  }, []);

  const _onChange = (e: any) => {
    e.target.value = e.target.value.replace(/,/g, '');
    if (isNaN(+e.target.value)) return;
    setPayment({ ...payment, [e.target.name]: +e.target.value });
  };

  if (!contract) {
    return <Table.Row></Table.Row>;
  }

  return (
    <Table.Row>
      <Table.Td>
        <Checkbox onClick={handleClick} checked={isChecked} />
      </Table.Td>
      <Table.Td className="w-10">{contract.userName}</Table.Td>
      <Table.Td className="w-8">
        <Icon.File
          onClick={() =>
            window.open(
              `/admin/users/${contract.id}/ContractInfo`,
              '_blank',
              'noopener'
            )
          }
          className="w-6 cursor-pointer text-gray-500 "
        />
      </Table.Td>
      <Table.Td className="w-8">
        {contract.receiptUrl && (
          <Icon.ExternalLink
            onClick={() =>
              window.open(contract.receiptUrl, '_blank', 'noopener')
            }
            className="w-6 cursor-pointer text-gray-500 "
          />
        )}
      </Table.Td>
      <Table.Td>
        <Select
          className="h-8 w-16 p-1 text-13 text-gray-800"
          value={workerInput}
          disabled={isChecked}
          onChange={(e) => {
            if (e.target.value) {
              setWorkerInput(e.target.value as Worker);
            }
          }}
        >
          <option value={undefined} hidden defaultChecked>
            선택
          </option>
          <option value={Worker.BUSINESS}>사업소득</option>
          <option value={Worker.ONEDAY}>일용직</option>
        </Select>
      </Table.Td>
      <Table.Td>{slice(contract.userPersonalId, 0, 6)}</Table.Td>
      <Table.Td>{contract.workPlace}</Table.Td>
      <Table.Td>
        {format(new Date(contract.contractDate), 'yyyy-MM-dd')}
      </Table.Td>
      <Table.Td
        className={`${
          payment.paymentStatus === PaymentStatus.PAID
            ? 'text-blue-500'
            : 'text-red-500'
        } font-semibold`}
      >
        {KOREAN_PAYMENT_STATUS[payment.paymentStatus]}
      </Table.Td>
      <Table.Td className="text-center">
        {
          <input
            className="w-fit text-xs"
            disabled={isChecked}
            type="date"
            max="9999-12-31"
            value={
              payment.paymentDate &&
              format(new Date(payment.paymentDate), 'yyyy-MM-dd')
            }
            onChange={(e) => {
              setPayment({ ...payment, paymentDate: e.target.value });
            }}
          />
        }
      </Table.Td>
      <Table.Td>
        {/* 공제 자동 */}
        <TextField
          className="h-8 w-8 p-1 text-13"
          value={
            calRestTime(endWorkHour, startWorkHour) === restTime ? restTime : ''
          }
          type="number"
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 공제 수동 */}
        <TextField
          className="h-8 w-8 p-1 text-13 text-gray-800"
          value={
            calRestTime(endWorkHour, startWorkHour) !== restTime
              ? restTimeInput
              : ''
          }
          type="number"
          name="restTime"
          onChange={(e) => setRestTimeInput(e.target.value)}
          disabled={isChecked}
        />
      </Table.Td>
      <Table.Td>
        {/* 시급(수동) */}
        <TextField
          className="h-8 w-16 p-1 text-13 text-gray-800"
          name="hourPay"
          type="text"
          value={
            hourPay === 0
              ? ''
              : String(hourPay).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          onChange={_onChange}
          disabled={isChecked}
        />
      </Table.Td>
      <Table.Td>
        {/* 출근(수동) */}
        <TextField
          className="h-8 w-10 p-1 text-gray-800"
          name="startWorkHour"
          type="number"
          value={startWorkHour === 0 ? '' : startWorkHour}
          onChange={_onChange}
          disabled={isChecked}
        />
      </Table.Td>
      <Table.Td>
        {/* 퇴근(수동) */}
        <TextField
          className="h-8 w-10 p-1 text-gray-800"
          name="endWorkHour"
          type="number"
          value={endWorkHour === 0 ? '' : endWorkHour}
          onChange={_onChange}
          disabled={isChecked}
        />
      </Table.Td>
      <Table.Td>
        {/* 근무시간 */}
        <TextField className="h-8 w-10 p-1" value={totalWorkTime} disabled />
      </Table.Td>
      <Table.Td>
        {/* 기본급여 */}
        <TextField
          className="h-8 w-16 p-1"
          value={baseAmount.toLocaleString()}
          disabled
        />
      </Table.Td>
      {!isHideExtra && (
        <>
          <Table.Td>
            {/* 연장 시간 */}
            <TextField
              className="h-8 w-10 p-1"
              value={overTimeHourInput === '' ? overtimeHour : ''}
              disabled
            />
          </Table.Td>
          <Table.Td>
            {/* 연장 시간 수동 */}
            <TextField
              className="h-8 w-8 p-1 text-13 text-gray-800"
              value={overTimeHourInput}
              type="number"
              name="overTimeHourInput"
              onChange={(e) => setOverTimeHourInput(e.target.value)}
              disabled={isChecked}
            />
          </Table.Td>
          <Table.Td>
            {/* 연장 수당 */}
            <TextField
              className="h-8 w-16 p-1"
              value={overtimePay.toLocaleString()}
              disabled
            />
          </Table.Td>
          <Table.Td>
            {/* 야간 시간 */}
            <TextField
              className="h-8 w-10 p-1"
              value={nightHourInput === '' ? nightHour : ''}
              disabled
            />
          </Table.Td>
          <Table.Td>
            {/* 야간 시간 수동 */}
            <TextField
              className="h-8 w-8 p-1 text-13 text-gray-800"
              value={nightHourInput}
              type="number"
              name="nightHourInput"
              onChange={(e) => setNightHourInput(e.target.value)}
              disabled={isChecked}
            />
          </Table.Td>
          <Table.Td>
            {/* 야간 수당 */}
            <TextField
              className="h-8 w-16 p-1"
              value={nightPay.toLocaleString()}
              disabled
            />
          </Table.Td>
          <Table.Td>
            {/* 주휴 수당 (수동) */}
            <TextField
              className="h-8 w-16 p-1 text-gray-800"
              name="holidayPay"
              value={
                holidayPay === 0
                  ? ''
                  : String(holidayPay).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={_onChange}
              disabled={isChecked}
            />
          </Table.Td>
          <Table.Td>
            {/* 기타 수당 (수동) */}
            <TextField
              className="h-8 w-16 p-1 text-gray-800"
              name="otherPay"
              value={
                otherPay === 0
                  ? ''
                  : String(otherPay).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              onChange={_onChange}
              disabled={isChecked}
            />
          </Table.Td>
          <Table.Td>
            {/* 수당 합계 */}
            <TextField
              className="h-8 w-16 p-1 "
              value={totalExtraAmount.toLocaleString()}
              disabled
            />
          </Table.Td>
        </>
      )}

      <Table.Td>
        {/* 총 급여 */}
        <TextField
          className="h-8 w-16 p-1 "
          value={totalAmount.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 보험료 */}
        <TextField
          className="h-8 w-16 p-1 "
          value={insurance.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 타행 수수료 */}
        <TextField
          className="h-8 w-9 p-1"
          value={fee.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 소득세 */}
        <TextField
          className="h-8 w-16 p-1"
          value={incomeTax.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 차감 금액 */}
        <TextField
          className="h-8 w-16 p-1"
          value={deductedAmount.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 실 지급액 */}
        <TextField
          className="h-8 w-16 p-1"
          value={finalAmount.toLocaleString()}
          disabled
        />
      </Table.Td>
      <Table.Td>
        {/* 비고 */}
        <TextField
          className="h-8 w-16 p-1 text-gray-800"
          value={memo}
          name="memo"
          onChange={(e) => setPayment({ ...payment, memo: e.target.value })}
          disabled={isChecked}
        />
      </Table.Td>
    </Table.Row>
  );
};
