import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import { PartTimeJob } from '../../api/part-time-job/type';
import { Icon } from '../../components/Icon';
import { Pagination } from '../../components/Pagination';
import { PartTimeCard } from '../../components/PartTimeCard';
import { SearchPartTimeJob } from '../../components/SearchPartTImeJob';
import { Select } from '../../components/Select';
import {
  ITEMS_PER_PAGE_MD,
  KOREAN_WORK_DATE_TYPE,
  KOREAN_WORK_TIME_TYPE,
  WORK_FORM,
} from '../../constants';
import { useDebounce } from '../../hooks';
import { useAllPartTimeJobs } from '../../hooks/part-time-job';
import { meState } from '../../plugins/ridge';
import { WorkDateType, WorkTimeType } from '../../types';

export const PartTimeJobPage = () => {
  const me = meState.useValue();
  const [workDateType, setWorkDateType] = useState<WorkDateType>(
    WorkDateType.ALL
  );
  const [workDate, setWorkDate] = useState('');
  const [workTimeType, setWorkTimeType] = useState<WorkTimeType>();
  const [workForm, setWorkForm] = useState('');
  const [address, setAddress] = useState('');
  const [searchData, setSearchData] = useState('');
  const [page, setPage] = useState<number>(1);

  const debouncedSearchData = useDebounce({ value: searchData, delay: 250 });
  const { data: allPartTimeJobs } = useAllPartTimeJobs(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
      filter: {
        title: debouncedSearchData,
        ...(workDate && { workDate }),
        ...(workTimeType && { workTimeType }),
        ...(workDateType && { workDateType }),
        ...(workForm && { workForm: workForm }),
        ...(address && { address }),
      },
    },
    me
  );

  useEffect(() => {
    setPage(1);
  }, [workDateType, debouncedSearchData, setPage]);

  return (
    <>
      <div className="mt-20 grid place-items-center">
        <div className="flex w-full max-w-7xl flex-col px-5 md:px-2">
          <h2 className="mt-10 mb-8 hidden font-bold md:block">알바 공고</h2>
          <div className="flex flex-col space-y-2 md:flex-row md:justify-between md:space-y-0 ">
            <div className="mt-4 flex flex-col space-y-2 md:mt-0 md:flex-row md:space-x-2 md:space-y-0">
              <Select
                onChange={(e) =>
                  setWorkDateType(e.target.value as WorkDateType)
                }
                className="pr-8"
              >
                {map(WorkDateType, (dateType: WorkDateType, index) => (
                  <option key={index} value={dateType}>
                    {KOREAN_WORK_DATE_TYPE[dateType]}
                  </option>
                ))}
              </Select>
              <SearchPartTimeJob
                searchData={workDate}
                setSearchData={(data: string) => setWorkDate(data)}
                placeholder="일자"
                type="date"
                label="일자"
              />
              <Select
                onChange={(e) =>
                  setWorkTimeType(e.target.value as WorkTimeType)
                }
                className={`pr-8 ${!workTimeType && `text-gray-400`}`}
              >
                <option selected hidden>
                  근무 시간
                </option>
                <option value="">전체</option>
                {map(WorkTimeType, (dateType: WorkTimeType, index) => (
                  <option key={index} value={dateType}>
                    {KOREAN_WORK_TIME_TYPE[dateType]}
                  </option>
                ))}
              </Select>
              <SearchPartTimeJob
                searchData={address}
                setSearchData={(data: string) => setAddress(data)}
                placeholder="주소"
                label="주소"
              />
              <Select
                onChange={(e) => setWorkForm(e.target.value)}
                className={`pr-8 ${!workForm && `text-gray-400`}`}
              >
                <option selected hidden>
                  업무 형태
                </option>
                <option value="">전체</option>
                {map(WORK_FORM, (workType, index) => (
                  <option key={index} value={workType}>
                    {workType}
                  </option>
                ))}
              </Select>
            </div>

            <div className="textfield flex items-center space-x-2">
              <Icon.Search />
              <input
                className="w-full"
                value={searchData}
                onChange={(e) => setSearchData(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-x-7 gap-y-10 md:grid-cols-4">
            {allPartTimeJobs &&
              map(allPartTimeJobs.items, (partTimejob: PartTimeJob) => (
                <PartTimeCard
                  partTimeJob={partTimejob}
                  isLogin={!!me}
                  key={partTimejob.id}
                />
              ))}
          </div>
        </div>
      </div>
      {allPartTimeJobs && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={allPartTimeJobs?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
