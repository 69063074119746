import React, { FC, useEffect, useState } from 'react';
import { getHours, getMinutes } from 'date-fns';
import { filter, last, map, omit, some } from 'lodash';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/Label';
import { TextField } from '../../../components/TextField';
import { TimeDropdown } from '../../../components/TimeDropdown';
import { AnimationLayout } from '../../../components/modal/AnimationLayout';
import {
  useCreatePartTimeJobDateBulkMutation,
  useDeletePartTimeJobDateMutation,
} from '../../../hooks/part-time-job-date';
import { EditDateI } from '../../pages/company/CompanyPartTimeJobDate';
import { TimeList } from '../../pages/company/types';
import { setHourAndMinDate } from '../../pages/company/util';

interface EditDateModalProps {
  onClose: () => void;
  open: boolean;
  editDateItems: EditDateI[] | undefined;
  partTimeJobId: number;
}

const INIT_TIME = {
  startTime: { hour: 0, min: 0 },
  endTime: { hour: 0, min: 0 },
};

export const EditDateModal: FC<EditDateModalProps> = ({
  onClose,
  open,
  editDateItems,
  partTimeJobId,
}) => {
  const [editWorkDate, setEditWorkDate] = useState('');
  const [timeList, setTimeList] = useState<TimeList[]>([]);
  const { mutate: createPartTimeJobDateBulkMutate } =
    useCreatePartTimeJobDateBulkMutation(onClose);
  const { mutate: deletePartTimeJobDateMutate } =
    useDeletePartTimeJobDateMutation(onClose);
  const [isDisabledDate, setIsDisabledDate] = useState<boolean>(false);
  const _init = () => {
    const dateItems = map(editDateItems, (editDate) => {
      const startDateTime = new Date(editDate.workingStartAt);
      const endDateTime = new Date(editDate.workingEndAt);
      const startTime = {
        hour: getHours(startDateTime),
        min: getMinutes(startDateTime),
      };
      const endTime = {
        hour: getHours(endDateTime),
        min: getMinutes(endDateTime),
      };
      return {
        startTime,
        endTime,
        id: editDate.id,
        index: editDate.id,
        applications: editDate.applications,
      };
    });
    setTimeList(dateItems);
  };

  const _addTimeList = () => {
    setTimeList((prev) => [
      ...prev,
      {
        index: (last(timeList)?.index || 0) + 1,
        ...INIT_TIME,
      },
    ]);
  };

  const _deleteTimeList = (id: number) => {
    const deleteTimeList = filter(timeList, (timeList) => {
      return timeList.index !== id;
    });
    setTimeList(deleteTimeList);
  };

  const _convertTimeList = (timeList: TimeList[]) => {
    const result = map(timeList, (time) => {
      const newTime = omit(time, ['applications', 'index']);
      const { startTime, endTime, id } = newTime;

      return {
        ...(id && { id }),
        workDate: new Date(editWorkDate).toISOString(),
        workingStartAt: setHourAndMinDate(
          editWorkDate,
          startTime
        ).toISOString(),
        workingEndAt: setHourAndMinDate(editWorkDate, endTime).toISOString(),
      };
    });
    return result;
  };

  useEffect(() => {
    if (!editDateItems || !open) {
      setEditWorkDate('');
      return;
    }
    setEditWorkDate(editDateItems[0].workDate);
  }, [setEditWorkDate, editDateItems, open]);

  useEffect(() => {
    _init();
  }, [setTimeList, editDateItems]);

  useEffect(() => {
    if (!timeList || timeList.length === 0) {
      return;
    }

    some(timeList, (time) => {
      if (time.id) {
        if (time.applications?.length !== 0) {
          setIsDisabledDate(true);
          return true;
        } else {
          setIsDisabledDate(false);
          return false;
        }
      }
    });
  }, [timeList]);

  return (
    <>
      <AnimationLayout
        open={open}
        onClose={() => {
          _init();
          onClose();
        }}
        isAdmin
      >
        <div className="my-8 max-h-[600px] w-full max-w-3xl transform space-y-3 overflow-hidden overflow-y-auto rounded-lg bg-white p-6 shadow-xl transition-all">
          <div className="flex justify-between">
            <h4 className="text-gray-800">근무일 수정</h4>
            <button
              type="button"
              className="right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
              onClick={() => {
                _init();
                onClose();
              }}
            >
              <Icon.X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="grid gap-y-4">
            <TextField
              label="날짜"
              type="date"
              max="9999-12-31"
              compulsory
              disabled={isDisabledDate}
              labelClassname="text-left"
              value={editWorkDate}
              onChange={(e) => setEditWorkDate(e.target.value)}
            />
            <div className="space-y-3 text-left">
              <Label text="시간" compulsory />
              {timeList &&
                map(timeList, (editDate, i) => {
                  let isDisable;
                  if (editDate.applications) {
                    if (editDate.applications?.length === 0) {
                      isDisable = false;
                    } else {
                      isDisable = true;
                    }
                  } else {
                    isDisable = false;
                  }

                  return (
                    <div
                      key={editDate.index}
                      className="flex items-center space-x-2 text-15 text-gray-700"
                    >
                      <div className="flex items-center space-x-2">
                        <TimeDropdown
                          time={editDate.startTime}
                          disabled={isDisable}
                          onChange={(startTime: {
                            hour: number;
                            min: number;
                          }) => {
                            const updateTimeList = [...timeList];
                            updateTimeList[i].startTime = startTime;
                            setTimeList(updateTimeList);
                          }}
                        />
                        <div> ~ </div>
                        <TimeDropdown
                          time={editDate.endTime}
                          disabled={isDisable}
                          onChange={(endTime: {
                            hour: number;
                            min: number;
                          }) => {
                            const updateTimeList = [...timeList];
                            updateTimeList[i].endTime = endTime;
                            setTimeList(updateTimeList);
                          }}
                        />
                      </div>
                      <button
                        type="button"
                        onClick={() => _deleteTimeList(editDate.index)}
                        className="right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
                      >
                        {isDisable ? null : (
                          <Icon.X className="h-6 w-6" aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  );
                })}
            </div>
            <div className="flex text-15 text-gray-700">
              <button onClick={_addTimeList}>+ 시간 추가</button>
            </div>

            <div className="flex space-x-3">
              <Button
                text="저장하기"
                disabled={!editWorkDate || timeList.length === 0}
                onClick={() => {
                  const result = {
                    partTimeJobId,
                    workDate: editWorkDate,
                    createPartTimeJobDateDtos: _convertTimeList(timeList),
                    existedWorkDate: editDateItems && editDateItems[0].workDate,
                  };
                  createPartTimeJobDateBulkMutate(result);
                }}
                className="mt-4 w-60 bg-gray-800 text-white"
              />

              <Button
                text="삭제하기"
                disabled={some(
                  editDateItems,
                  (date) => date.applications.length !== 0
                )}
                onClick={() => {
                  if (!editDateItems) {
                    return;
                  }

                  const deleteDto = {
                    partTimeJobId,
                    workDate: editDateItems[0].workDate,
                  };

                  deletePartTimeJobDateMutate(deleteDto);
                }}
                className="mt-4 w-60 border-2 border-gray-500 bg-white text-gray-800"
              />
            </div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
