import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';

export interface AuthRouteProps {
  element: JSX.Element;
  redirectTo?: string;
  guestOnly?: boolean;
  guestOnlyRedirectTo?: string;
}

export const AuthRoute: FC<AuthRouteProps> = ({
  element: Element,
  redirectTo = '/login',
  guestOnly = false,
  guestOnlyRedirectTo = '/main',
}) => {
  const navigate = useNavigate();
  const { authenticated } = useAuth();

  useEffect(() => {
    if (guestOnly && authenticated) {
      navigate(guestOnlyRedirectTo, { replace: true });
      return;
    }
    if (!guestOnly && !authenticated) {
      navigate(redirectTo, { replace: true });
      return;
    }
  }, [guestOnly, authenticated]);

  return Element;
};
