import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from './Icon';

interface MobileTopBarProps {
  title?: string;
}

export const MobileTopBar: FC<MobileTopBarProps> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="sticky top-[57px] z-20 border-b border-gray-100 bg-white p-5 md:hidden">
        <button className="absolute top-5 left-5" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft />
        </button>
        <div className="mx-auto text-center font-medium">{title}</div>
      </div>
    </>
  );
};
