import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateCareerDto, UpdateCareerDto } from './type';

export function createCareer(createCareerDto: CreateCareerDto) {
  return api.post(PATHS.ROOT, createCareerDto);
}

export function createCareerByAdmin(createCareerDto: CreateCareerDto) {
  return adminApi.post(PATHS.ROOT, createCareerDto);
}

export function updateCareer(updateCareerDto: UpdateCareerDto) {
  const { id } = updateCareerDto;

  return api.patch(PATHS.getOneById(id), updateCareerDto);
}

export function updateCareerByAdmin(updateCareerDto: UpdateCareerDto) {
  const { id } = updateCareerDto;

  return adminApi.patch(PATHS.getOneById(id), updateCareerDto);
}

export function deleteCareer(id: number) {
  return api.delete(PATHS.getOneById(id));
}

export function deleteCareerByAdmin(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}
