import { FC } from 'react';
import { format } from 'date-fns';
import moment from 'moment';
import { Contract } from '../api/contract/type';
import {
  KOREAN_PAYMENT_STATUS2,
  Payment,
  PaymentStatus,
} from '../api/payment/type';
import { Button } from './Button';
import { Icon } from './Icon';

interface PaymentRequestListProps {
  paymentRequest: Payment;
  contract: Contract;
  onClick: (paymentId: number) => void;
}

export const PaymentRequestList: FC<PaymentRequestListProps> = ({
  paymentRequest,
  contract,
  onClick,
}) => {
  return (
    <>
      <div className="py-11">
        <div className="flex justify-between">
          <div className="font-medium text-gray-700">{contract.workPlace}</div>
          <div
            className={`${
              paymentRequest.paymentStatus === PaymentStatus.APPLIED
                ? `text-red-500`
                : `text-blue-500`
            } text-right`}
          >
            <p className="font-medium">
              {KOREAN_PAYMENT_STATUS2[paymentRequest.paymentStatus]}
            </p>
            <p className="pt-1 text-13 text-gray-600">
              {paymentRequest.paymentStatus === PaymentStatus.PAID
                ? `지급 완료일 :  ${format(
                    new Date(paymentRequest.paymentDate),
                    'yyyy-MM-dd'
                  )} `
                : ` 신청 완료일 : ${format(
                    new Date(contract.createdAt),
                    'yyyy-MM-dd'
                  )} `}
            </p>
          </div>
        </div>
        <h5 className="mt-4 font-bold md:mt-4">
          시급
          {paymentRequest.hourPay ? paymentRequest.hourPay : contract.hourPay}원
        </h5>
        <div className="mt-2 flex flex-col space-y-4 md:flex-row md:place-items-center md:justify-between md:space-y-0">
          <div className="flex space-x-2">
            <div className="flex h-8 items-center space-x-1 rounded-md bg-sub-2 p-2 ">
              <Icon.Calendar className="wh-4 flex-none  text-brand-1" />
              <p className="truncate text-13 font-medium ">
                {format(new Date(contract.contractDate), 'yyyy-MM-dd')}
              </p>
            </div>
            <div className="flex h-8 items-center space-x-1 rounded-md bg-sub-2 p-2">
              <Icon.Clock className="wh-4 flex-none  text-brand-1" />
              <p className="truncate text-13 font-medium ">
                {moment(contract.startWorkTime).format('HH:mm')} {' ~ '}
                {moment(contract.endWorkTime).format('HH:mm')}
              </p>
            </div>
          </div>
          <div className="text-left md:text-right">
            <Button
              text="근로계약서 확인 >"
              className="mr-3 h-12 border border-brand-1 px-6 text-15 text-brand-1 "
              to={`/contract/${contract.id}`}
            />
            <Button
              disabled={paymentRequest.paymentStatus === PaymentStatus.APPLIED}
              text="급여상세내역"
              className="h-12 bg-brand-1 px-6 text-15 text-white"
              onClick={() => onClick(paymentRequest.id)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
