import { Icon } from './Icon';

interface SearchUserProps {
  searchData: string;
  setSearchData: (data: string) => void;
}

export const SearchUser = ({ searchData, setSearchData }: SearchUserProps) => {
  return (
    <>
      <div className="textfield flex w-72 items-center justify-between rounded-full">
        <input
          className="w-full"
          value={searchData}
          onChange={(e) => setSearchData(e.target.value)}
        />
        <button>
          <Icon.Search className="wh-6 stroke-gray-500" />
        </button>
      </div>
    </>
  );
};
