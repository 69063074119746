import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createResume,
  updateResume,
  updateResumeByAdmin,
} from '../../api/resume';
import { PATHS } from '../../api/resume/paths';
import {
  CreateResumeDto,
  Resume,
  UpdateResumeDto,
} from '../../api/resume/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { meState } from '../../plugins/ridge';

export function useMyResume() {
  const me = meState.useValue();
  return useQuery<Resume, AxiosError>(PATHS.ME, fetcher, { enabled: !!me });
}

export function useResumeByUserId(userId: string) {
  return useQuery<Resume, AxiosError>(
    PATHS.getOneByUserId(+userId),
    adminFetcher,
    { enabled: !!userId }
  );
}

export function useResumeById(id: number, options?: any) {
  return useQuery<Resume, AxiosError>(
    PATHS.getOneById(id),
    adminFetcher,
    options
  );
}

export const useCreateResumeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createResumeDto: CreateResumeDto) => createResume(createResumeDto),
    {
      onSuccess: () => {
        toast.success('이력서 작성이 완료되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('이력서 작성에 실패하였습니다.');
      },
    }
  );
};

export const useUpdateResumeMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateResumeDto: UpdateResumeDto) => updateResume(updateResumeDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('이력서 저장이 완료되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.ME);
      },
      onError: () => {
        toast.error('이력서 저장에 실패하였습니다.');
      },
    }
  );
};

export const useUpdateResumeAdminMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateResumeDto: UpdateResumeDto) => updateResumeByAdmin(updateResumeDto),
    {
      onSuccess: (_, updateResumeDto) => {
        onSuccess && onSuccess();
        toast.success('이력서 저장이 완료되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.getOneById(updateResumeDto.id));
      },
      onError: () => {
        toast.error('이력서 저장에 실패하였습니다.');
      },
    }
  );
};
