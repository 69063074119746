import React, { FC, HTMLAttributes } from 'react';
import { Pagination } from 'swiper';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './swiper.css';

export type SwiperPaginationCardProps = HTMLAttributes<HTMLDivElement>;

export const SwiperPaginationCard: FC<SwiperPaginationCardProps> = ({
  children,
  className = '',
}) => {
  return (
    <Swiper
      pagination={true}
      modules={[Pagination]}
      className={`mySwiper ${className} `}
    >
      {children}
    </Swiper>
  );
};
