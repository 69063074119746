import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateCertificateDto, UpdateCertificateDto } from './type';

export function createCertificate(createCertificateDto: CreateCertificateDto) {
  return api.post(PATHS.ROOT, createCertificateDto);
}

export function createCertificateByAdmin(
  createCertificateDto: CreateCertificateDto
) {
  return adminApi.post(PATHS.ROOT, createCertificateDto);
}

export function updateCertificate(updateCertificateDto: UpdateCertificateDto) {
  const { id } = updateCertificateDto;

  return api.patch(PATHS.getOneById(id), updateCertificateDto);
}

export function updateCertificateByAdmin(
  updateCertificateDto: UpdateCertificateDto
) {
  const { id } = updateCertificateDto;

  return adminApi.patch(PATHS.getOneById(id), updateCertificateDto);
}

export function deleteCertificate(id: number) {
  return api.delete(PATHS.getOneById(id));
}

export function deleteCertificateByAdmin(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}
