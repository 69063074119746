import { useEffect, useState } from 'react';
import { parse, stringify } from 'qs';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from './plugins/axios';
import { meState, tokenState } from './plugins/ridge';

export const useAuth = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [token, setToken] = tokenState.use();
  const authenticated = token !== null;
  const signup = (data: any) =>
    api
      .post('/auth/signup', data)
      .then(({ data: { token } }) => setToken(token));

  const login = (data: any) =>
    api
      .post('/auth/login', data)
      .then(({ data: { token } }) => setToken(token))
      .catch(() => {
        toast.error('아이디 또는 비밀번호가 틀렸습니다.');
      });

  const logout = () => {
    meState.reset();
    tokenState.reset();
    queryClient.clear();
    navigate('/main');
  };
  return { token, authenticated, signup, login, logout };
};

export function useQueryString(queryObject: any = {}) {
  const { search } = useLocation();
  const parsed = parse(search, { ignoreQueryPrefix: true });
  const searchObject = {
    page: '1',
    limit: '10',
    sort: { id: 'DESC' },
    ...parsed,
    ...queryObject,
  };
  return stringify(searchObject, { addQueryPrefix: true, encode: false });
}

interface DebounceProps<T> {
  value: T;
  delay: number;
}

export function useDebounce<T>({ value, delay }: DebounceProps<T>): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
