import { ReactNode } from 'react';

export default function AdminMain({
  children,
  hasMaxWidth = false,
}: {
  children: ReactNode;
  hasMaxWidth: boolean;
}) {
  return (
    <main className="relative flex-1 overflow-y-auto py-6" id="admin-main">
      <div
        className={`mx-auto flex ${
          hasMaxWidth && 'max-w-7xl'
        } flex-col space-y-4 px-4 sm:px-6 md:px-8`}
      >
        {children}
      </div>
    </main>
  );
}
