import { map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';
import HeroImg from '../../assets/img/cover-image.jpg';
import { ReactComponent as HexagonSvg } from '../../assets/svg/hexagon.svg';
import { ReactComponent as LogoSvg } from '../../assets/svg/logo.svg';
import { Icon } from '../../components/Icon';
import { PartnershipCard } from '../../components/PartnershipCard';
import { useModal } from '../../components/modal/Modal';
import { SwiperNavigationCard } from '../../components/swiper/SwiperNavigationCard';
import { SwiperPaginationCard } from '../../components/swiper/SwiperPaginationCard';
import { useAuth } from '../../hooks';
import { useAllCompanyIntroductions } from '../../hooks/company-introduction';
import { useAllPartnersIntroduction } from '../../hooks/partners-introduction';

export const MainPage = () => {
  const { authenticated } = useAuth();
  const navigate = useNavigate();
  const { loginRequired } = useModal();
  const { data: allCompanyIntroduction } = useAllCompanyIntroductions();
  const { data: allPartnersIntroduction } = useAllPartnersIntroduction();
  return (
    <div>
      <div
        className="flex h-[80vh] w-full flex-col items-center justify-center bg-cover bg-center bg-no-repeat py-12 md:h-[80vh] md:px-0 md:py-0"
        style={{ backgroundImage: `url('${HeroImg}')` }}
      >
        <div className="mx-6 space-y-5 text-center text-white md:space-y-10">
          <LogoSvg className="wh-96 -my-16 md:wh-128" />
        </div>

        <div className="grid grid-cols-2 gap-3 md:grid-cols-3 md:gap-8">
          <button
            onClick={() => navigate('/part-time-job')}
            className="relative w-max"
          >
            <HexagonSvg className="wh-40 md:wh-44" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 translate-y-1/2 pt-1 text-white">
              <Icon.FileText className="wh-10 mx-auto text-white md:wh-12" />
              <p className="pt-2">알바 공고</p>
            </div>
          </button>
          <button
            onClick={() =>
              authenticated ? navigate('/payment-request') : loginRequired()
            }
            className="relative w-max"
          >
            <HexagonSvg className="wh-40 md:wh-44" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 translate-y-1/2 pt-1 text-white">
              <Icon.Dollar className="wh-10 mx-auto text-white md:wh-12" />
              <p className="w-max pt-2">급여/비용 청구</p>
            </div>
          </button>
          <button
            onClick={() =>
              authenticated ? navigate('/mypage') : loginRequired()
            }
            className="relative w-max"
          >
            <HexagonSvg className="wh-40 md:wh-44" />
            <div className="absolute top-0 left-1/2 -translate-x-1/2 translate-y-1/2 pt-1 text-white">
              <Icon.Link className="wh-10 mx-auto text-white md:wh-12" />
              <p className="pt-2">마이페이지</p>
            </div>
          </button>
        </div>
      </div>

      <SwiperPaginationCard>
        {map(allCompanyIntroduction, (companyintro) => (
          <SwiperSlide key={companyintro.id}>
            <div className="aspect-w-16 aspect-h-8 relative m-auto ">
              <img
                src={companyintro.image}
                crossOrigin="anonymous"
                className=" h-[680px] w-full object-cover"
                alt=""
              />
            </div>
          </SwiperSlide>
        ))}
      </SwiperPaginationCard>

      <div className="py-28">
        <div className="pb-5 text-center">
          <p className="font-semibold">협력사 소개</p>
          <h1>PARTNERSHIP</h1>
        </div>

        <SwiperNavigationCard>
          {map(allPartnersIntroduction, (partners) => (
            <SwiperSlide>
              <PartnershipCard
                key={partners.id}
                companyName={partners.companyName}
                image={partners.image}
                description={partners.description}
                address={partners.address}
              />
            </SwiperSlide>
          ))}
        </SwiperNavigationCard>
      </div>
    </div>
  );
};
