import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Admin } from './admin/Admin';
import 'react-toastify/dist/ReactToastify.css';
import { AdminRoute } from './components/AdminRoute';
import { ScrollToTop } from './components/ScrollToTop';
import { Router } from './pages/Router';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="admin/*" element={<AdminRoute element={<Admin />} />} />
        <Route path="*" element={<Router />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
