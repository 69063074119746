import { useState } from 'react';
import { format } from 'date-fns';
import { filter, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { downloadWorkExcel } from '../../../api/application';
import {
  Application,
  Attendance,
  KOREAN_ATTENDANCE,
} from '../../../api/application/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Checkbox } from '../../../components/Checkbox';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_LG } from '../../../constants';
import {
  useAllApplicationsByDateId,
  useNotWorkApplications,
  useWorkApplications,
} from '../../../hooks/application';

export const GotoWorkList = () => {
  const [page, setPage] = useState<number>(1);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const { id: partTimeJobId, day: clickedDay } = useParams<{
    id: string;
    day: string;
  }>();
  const { data: allApplications, refetch } = useAllApplicationsByDateId(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_LG,
      filter: {
        jobStatus: 'APPROVED',
        ...(clickedDay ? { workDate: clickedDay } : {}),
      },
    },
    Number(partTimeJobId)
  );
  const _init = () => {
    setIsCheckAll(false);
    setIsCheck([]);
    refetch();
  };
  const { mutate: workApplicationMutate } = useWorkApplications(_init);
  const { mutate: notWorkApplicationMutate } = useNotWorkApplications(_init);

  const handleSelectAll = () => {
    if (!allApplications) {
      return;
    }
    setIsCheckAll(!isCheckAll);
    setIsCheck(map(allApplications.items, (app) => app.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (
    app: Application,
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (!app || !event) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const checked = target.checked;
    setIsCheck([...isCheck, app.id]);
    if (!checked) {
      setIsCheck(filter(isCheck, (item) => item !== app.id));
    }
  };

  return (
    <>
      <div className="flex items-center justify-end space-x-2 py-2 px-8">
        <Button
          className=" shadow-xs w-30 h-12 bg-red-500 text-sm text-white"
          onClick={() => {
            if (isCheck.length === 0) {
              return;
            }
            notWorkApplicationMutate(isCheck);
          }}
        >
          결근 처리
        </Button>
        <Button
          className=" shadow-xs w-30 h-12 bg-brand-1 text-sm text-white"
          onClick={() => {
            if (isCheck.length === 0) {
              return;
            }
            workApplicationMutate(isCheck);
          }}
        >
          출근 처리
        </Button>
        <Button
          className="shadow-xs w-30 h-12 bg-green-700 text-sm text-white"
          onClick={() => {
            downloadWorkExcel(
              isCheck
                ? {
                    filter: {
                      ids: isCheck,
                    },
                  }
                : {}
            ).then((res) => {
              const url = URL.createObjectURL(res.data);
              const link = document.createElement('a');
              link.href = url;
              link.download = `출근여부_관리_${format(
                new Date(),
                'yyyyMMddHHmmss'
              )}`;
              link.click();
              URL.revokeObjectURL(url);
            });
          }}
        >
          엑셀 다운로드
        </Button>
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>
                <Checkbox onClick={handleSelectAll} isChecked={isCheckAll} />
              </Table.Th>
              <Table.Th>이름</Table.Th>
              <Table.Th>주민번호</Table.Th>
              <Table.Th>휴대폰번호</Table.Th>
              <Table.Th>출근여부</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allApplications &&
              map(allApplications.items, (app) => (
                <Table.Row>
                  <Table.Td>{app.id}</Table.Td>
                  <Table.Td>
                    <Checkbox
                      isChecked={isCheck.includes(app.id)}
                      onClick={(e) => {
                        handleClick(app, e);
                      }}
                    />
                  </Table.Td>
                  <Table.Td>{app.userName}</Table.Td>
                  <Table.Td>{app.userPersonalId}</Table.Td>
                  <Table.Td>{app.userPhone}</Table.Td>
                  <Table.Td
                    className={`${
                      app.attendance === Attendance.WORK
                        ? `text-blue-500`
                        : app.attendance === Attendance.NOT_WORK
                        ? `text-red-500`
                        : ''
                    } font-medium`}
                  >
                    {KOREAN_ATTENDANCE[app.attendance]}
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allApplications?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_LG}
          setPage={setPage}
          totalItemCount={allApplications?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
