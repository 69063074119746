import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button } from '../../../../components/Button';
import { useFindPasswordMutation } from '../../../../hooks/auth';

export const SendEmailPage = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const email = searchParams.get('email') || '';

  const { mutate: findPassword } = useFindPasswordMutation();

  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <div className="flex w-full max-w-md flex-col space-y-6">
          <h1 className="mb-5 text-24 font-semibold text-gray-900">
            가입하신 이메일 주소로 <br />
            비밀번호 재설정 링크가 전송되었습니다.
          </h1>
          <div className="pb-9 leading-normal text-gray-600">
            받은편지함이나, 받은 링크를 확인해 주세요. 이메일이나 문자가 오지
            않으면, 스팸메일함을 확인해 주시거나 아래 버튼을 눌러 다시
            보내주세요.
          </div>
          <Button
            type="button"
            text="다시 보내기"
            className="border border-brand-1 bg-white text-brand-1"
            disabled={!email}
            onClick={() => findPassword({ email })}
          />
        </div>
      </div>
    </>
  );
};
