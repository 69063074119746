import React from 'react';
import History2018SVG from '../../assets/img/history-2018.png';
import History2019SVG from '../../assets/img/history-2019.png';
import History2020SVG from '../../assets/img/history-2020.png';
import History2021SVG from '../../assets/img/history-2021.png';
import History2022SVG from '../../assets/img/history-2022.png';
import { HistoryCard } from './HistoryCard';

const HISTORY_DATA = [
  {
    id: 1,
    yearImg: History2022SVG,
    history: (
      <div>
        <li>
          제휴 계약 2건 체결 및 누적 회원 수 10,365명
          <br />
          <span className="pl-4">(테이크 / 더 채플)</span>
        </li>
        <li>인력 공급 총 20,510건</li>
        <li>아웃소싱 홈페이지 리뉴얼 및 확장</li>
        <li>신규 플랫폼 개설 및 어플리케이션 출시</li>
      </div>
    ),
  },
  {
    id: 2,
    yearImg: History2021SVG,
    history: (
      <div>
        <li>
          제휴 계약 5건 체결 및 누적 회원 수 7,872명 <br />{' '}
          <span className="pl-4">
            (더리버 / 락구정 / 아키텍처 / 지타워 / 삼청각)
          </span>
        </li>
        <li>인력 공급 총 12,155건</li>
      </div>
    ),
  },
  {
    id: 3,
    yearImg: History2020SVG,
    history: (
      <div>
        <li>
          제휴 계약 2건 체결 및 누적 회원 수 6,115명 <br />
          <span className="pl-4">(노블발렌티 청담 / 대치점 루벨 강동점)</span>
        </li>
        <li>인력 공급 총 9,291건</li>
        <li>더에스씨브이 홈페이지 개설</li>
      </div>
    ),
  },
  {
    id: 4,
    yearImg: History2019SVG,
    history: (
      <div>
        <li>
          제휴 계약 5건 체결 및 누적 회원 수 4,227명 <br />
          <span className="pl-4">
            (노블발렌티 / 더라빌 / 현대그린푸드 / 애견협회{' '}
            <br className="md:hidden" />{' '}
            <span className="pl-4 md:pl-0">/ SL&C 삼천리)</span>
          </span>
        </li>
        <li>아웃소싱 홈페이지 개설</li>
        <li>인력 공급 총 13,365건</li>
      </div>
    ),
  },
  {
    id: 5,
    yearImg: History2018SVG,
    history: (
      <div>
        <li>주식회사 더에스씨브이 창사</li>
      </div>
    ),
  },
];

export const FourthSection = () => {
  return (
    <div
      className="mx-auto w-full max-w-screen-xl space-y-5 px-5 md:space-y-10"
      id="section4"
    >
      <p className="text-[26px] font-semibold md:text-[36px]">OUR HISTORY</p>

      <div className="grid grid-cols-1 gap-16 lg:grid-cols-2 xl:grid-cols-3">
        {HISTORY_DATA.map((hi) => (
          <HistoryCard key={hi.id} yearImg={hi.yearImg} history={hi.history} />
        ))}
      </div>
    </div>
  );
};

export default { FourthSection };
