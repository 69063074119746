import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreatePartTimeJobDto, UpdatePartTimeJobDto } from './type';

export function createPartTimeJob(createPartTimeJobDto: CreatePartTimeJobDto) {
  return adminApi.post(PATHS.ROOT, createPartTimeJobDto);
}

export function updatePartTimeJob(updatePartTimeJobDto: UpdatePartTimeJobDto) {
  const { id } = updatePartTimeJobDto;
  return adminApi.patch(PATHS.getOneById(id), updatePartTimeJobDto);
}

export function deletePartTimeJob(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllPartTimeJobs(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAllPartTimeJobByCompany(
  paginationDto: PaginationDto,
  companyId: number
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get(`/${PATHS.getAllByCompanyId(companyId)}${queryString}`)
    .then(({ data }) => data);
}

export function getAllPartTimeJobsByPublic(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.PUBLIC}${queryString}`).then(({ data }) => data);
}

export function getAllPartTimeJobsByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAllLikePartTimeJobsByUser(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.LIKED}${queryString}`).then(({ data }) => data);
}
