// import 'swiper/css';
// import 'swiper/css/pagination';

import { useState } from 'react';
// import { Autoplay, FreeMode } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';
import PartnerImg1 from '../../assets/img/partner-img1.jpg';
import PartnerImg2 from '../../assets/img/partner-img2.jpg';
import PartnerImg3 from '../../assets/img/partner-img3.jpg';
import PartnerImg4 from '../../assets/img/partner-img4.jpg';
import PartnerImg5 from '../../assets/img/partner-img5.jpg';
import PartnerImg6 from '../../assets/img/partner-img6.jpg';
import PartnerImg7 from '../../assets/img/partner-img7.jpg';
import PartnerImg8 from '../../assets/img/partner-img8.jpg';
import PartnerImg9 from '../../assets/img/partner-img9.jpg';
import { PartnersModal } from '../modal/PartnersModal';

const PARTNERS_DUMMY = [
  {
    id: 1,
    img: PartnerImg1,
    text: `영국 대저택에서 영감을 받은 노블 발렌티는 클래식과 모던의 조화로운 공간과 특별한 채플 웨딩으로 소중한 순간을 선사합니다. 대형 종으로 축복을 시작하며, 한국 최초의 메뉴 선택형 웨딩 프로그램은 세련된 감각과 정서를 고루 갖춘 완벽한 웨딩을 완성합니다.`,
  },
  {
    id: 2,
    img: PartnerImg2,
    text: `대한민국 디지털 심장인 G밸리타운의 랜드마크, 지타워에 최고의 웨딩 명소를 선보입니다. 30m의 버진로드, 20mx5.5m 규모의 최첨단 고화질 시어터 스크린과 하이테크 음향 시스템, 7m 상공의 아름다운 크리스탈 샹들리에로 당신의 웨딩을 빛나게 해드립니다.`,
  },
  {
    id: 3,
    img: PartnerImg3,
    text: `"The Laville"은 평화로운 마을을 상징하는 프랑스어 "ville"에서 영감을 얻어 탄생했습니다. 도심 속 숲에 둘러싸인 더라빌은 도시 리조트 웨딩으로, 품격 있는 연회 서비스로 아름다운 자연과 도시의 조화를 즐길 수 있는 곳입니다. `,
  },
  {
    id: 4,
    img: PartnerImg4,
    text: `루벨은 "프렌치 가든에서의 왈츠"를 슬로건으로 삼아, 신랑 신부의 퍼스트 댄스와 함께하는 낭만적인 왈츠의 선율로 신랑 신부와 하객들이 모두 주인공인 웨딩 데이를 선사합니다. 이스트센트럴 타워의 최상층 위치와 강동역과의 연결로 최적의 교통과 차량 350대까지  수용 가능합니다.`,
  },
  {
    id: 5,
    img: PartnerImg5,
    text: `클래식은 본질을 고민하여 아름다움을 완성합니다. 신의 축복과 영원한 사랑을 바라는 채플 웨딩. 국내 최고의 천장고 아래 드라마틱한 버진로드와 압도적 웅장함, 사랑스러운 축복으로 가득한 위대한 러브스토리의 절정, 더채플 웨딩 주인공이 되어보세요.`,
  },
  {
    id: 6,
    img: PartnerImg6,
    text: `락구정은 서울대학교 내 한식당으로, 저염식과 건강식을 기본으로 하며 정갈하고 담백한 식사를 제공합니다. 학내 행사와 국제 학술대회, 컨퍼런스 등의 다양한 행사를 위해 다양한 메뉴를 준비하며 고객 만족을 최우선으로 합니다. `,
  },
  {
    id: 7,
    img: PartnerImg7,
    text: `삼청(三淸)은 산청(山淸), 수청(水淸), 인청(人淸) 산이 맑고, 물이 맑고, 인심이 좋다는 의미를 담고 있습니다. 북악산의 아름다운 경관속에 5개의 한옥과 2개의 정자, 3개의 마당이 어우러진 삼청각은 도심 속에서 찾기 힘든 특색있는 서울의 대표 전통문화예술 복합공간입니다.`,
  },
  {
    id: 8,
    img: PartnerImg8,
    text: `글로벌 차이니즈 레스토랑 브랜드, 크리스탈 제이드는 1991년부터 이어지는 요리 철학 기반으로 120개 이상의 레스토랑에서 현지의 맛을 선사하며 8가지 다채로운 중국 요리 컨셉 매장에서는 현지 초청 분야별 전문 셰프들의 손에서 중국 미식 여행을 경험할 수 있습니다.`,
  },
  {
    id: 9,
    img: PartnerImg9,
    text: `"h'_Garden"은 현대백화점 그룹을 상징하는 "h'"와 편안하고 생기 넘치는 웰빙 다이닝 공간을 의미하는 "Garden"의 만남으로 탄생했습니다. 세계 각국의 뷔페 메뉴뿐만 아니라 계절별, 스페셜, 일품 요리, 세트 메뉴 등 다양한 요리를 최상의 셰프들이 제공합니다.`,
  },
];

export const FifthSection = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [imgId, setImgId] = useState<number>(0);

  return (
    <div className="mx-auto w-full max-w-screen-xl space-y-5 px-5 md:space-y-10">
      <PartnersModal
        imgId={imgId}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />

      <p className="text-[26px] font-semibold md:text-[36px]">OUR PARTNERS</p>

      <div className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {PARTNERS_DUMMY.map((pa) => (
          <div key={pa.id} className="relative w-full">
            <button
              onClick={() => {
                setModalOpen(true);
                setImgId(pa.id - 1);
              }}
            >
              <img
                src={pa.img}
                alt=""
                className=" aspect-[3/4] w-full rounded-lg object-cover"
              />

              <p className="absolute top-3 right-3 text-right text-[12px] font-medium text-white">
                상세보기
              </p>
              <p className="absolute bottom-7 left-5 right-5 whitespace-pre-wrap text-left text-[12px] text-white">
                {pa.text}
              </p>
            </button>
          </div>
        ))}
      </div>

      {/* <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        freeMode={true}
        modules={[FreeMode, Autoplay]}
        loop
        autoplay={{
          delay: 0.5,
          disableOnInteraction: false,
        }}
        speed={5000}
      >
        {PARTNERS_DUMMY.map((pa) => (
          <SwiperSlide key={pa.id} className="relative w-fit">
            <button
              onClick={() => {
                setModalOpen(true);
                setImgId(pa.id - 1);
              }}
            >
              <img
                src={pa.img}
                alt=""
                className="h-[420px] w-fit rounded-lg object-cover"
              />

              <p className="absolute top-3 right-3 text-right text-[12px] font-medium text-white">
                상세보기
              </p>
              <p className="absolute bottom-7 left-5 right-5 whitespace-pre-wrap text-left text-[12px] text-white">
                {pa.text}
              </p>
            </button>
          </SwiperSlide>
        ))}
      </Swiper> */}
    </div>
  );
};

export default { FifthSection };
