import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createContract,
  deleteContract,
  getAllContract,
  getAllContractByAdmin,
  getAllContractByUserId,
  getMyContract,
  updateContract,
} from '../../api/contract';
import { PATHS } from '../../api/contract/paths';
import {
  Contract,
  CreateContractDto,
  UpdateContractDto,
} from '../../api/contract/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllContract(paginationDto: PaginationDto) {
  return useQuery<Paginated<Contract>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllContract(paginationDto)
  );
}
export function useAllContractByAdmin(paginationDto: PaginationDto) {
  return useQuery<Paginated<Contract>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllContractByAdmin(paginationDto)
  );
}

export function useAllContractByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  return useQuery<Paginated<Contract>, AxiosError>(
    [PATHS.userId(userId), paginationDto],
    () => getAllContractByUserId(paginationDto, userId),
    {
      keepPreviousData: true,
    }
  );
}

export function useMyContract(paginationDto: PaginationDto) {
  return useQuery<Paginated<Contract>, AxiosError>(
    [PATHS.ME, paginationDto],
    () => getMyContract(paginationDto)
  );
}

export function useContractById(id: number) {
  return useQuery<Contract, AxiosError>(PATHS.getOneById(id), fetcher, {
    enabled: !!id,
  });
}

export function useContractByAdminId(id: number) {
  return useQuery<Contract, AxiosError>(PATHS.getOneById(id), adminFetcher, {
    enabled: !!id,
  });
}

export const useCreateContractMutation = (onSuccess?: () => void) => {
  return useMutation(
    (createContractDto: CreateContractDto) => createContract(createContractDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('계약서 제출(급여신청)이 완료되었습니다.');
      },
      onError: () => {
        toast.error('계약서 제출(급여신청)이 실패했습니다.');
      },
    }
  );
};

export const useUpdateContractMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateContractDto: UpdateContractDto) => updateContract(updateContractDto),
    {
      onSuccess: (_, updateContractDto) => {
        onSuccess && onSuccess();
        toast.success('계약서 수정 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(PATHS.getOneById(updateContractDto.id));
      },
      onError: () => {
        toast.error('계약서 수정 실패');
      },
    }
  );
};

export const useDeleteContractMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteContract(id), {
    onSuccess: (_, id) => {
      toast.success('계약서 삭제 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('계약서 삭제 실패');
    },
  });
};
