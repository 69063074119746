import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { join, map, omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../api/file';
import {
  BankCode,
  GENDER,
  KOREAN_BANK_NAME,
  KOREAN_GENDER,
  KOREAN_SCHOOL_STATUS,
  SCHOOL_STATUS,
} from '../../../api/resume/type';
import { Address } from '../../../components/Address';
import { Button } from '../../../components/Button';
import { CareerList } from '../../../components/CareerList';
import { CertificateList } from '../../../components/CertificateList';
import { FileUpload } from '../../../components/FileUpload';
import { Icon } from '../../../components/Icon';
import { IdPhoto } from '../../../components/IdPhoto';
import { Label } from '../../../components/Label';
import { MobileTopBar } from '../../../components/MobileTopBar';
import { ResumeBottomNavBar } from '../../../components/ResumeBottomNavBar';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { useMyResume, useUpdateResumeMutation } from '../../../hooks/resume';

interface ResumeFormValues {
  userImage: FileList | string; // 유저 사진
  userName: string; // 이름
  email: string; // 이메일
  birth: string; // 생년월일
  gender: GENDER; // 성별
  personalId1: string; // 주민등록번호 앞자리
  personalId2: string; // 주민등록번호 뒷자리
  addressDetail: string; // 상세 주소
  depositor: string; // 예금주
  bankCode: BankCode; // 은행명
  bankAccount: string; // 계좌 번호
  accountImg: FileList | string; // 통장 사본
  schoolName?: string; // 학교
  schoolStatus?: SCHOOL_STATUS; // 학적
  major?: string; // 전공
  healthImg?: FileList | string; //보건증
  agreementFile?: FileList | string; // 부모님 동의서
  resumeFile?: FileList | string; // 이력서 파일
  coverLetterFile?: FileList | string; // 자소서 파일
}

export const ResumeEditPage = () => {
  const { data: myResume } = useMyResume();
  const [address, setAddress] = useState('');
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isAccountImgEmpty, setIsAccountImgEmpty] = useState(true);
  const [isUserImageEmpty, setIsUserImageEmpty] = useState(true);
  const [buttonClicked, setButtonClicked] = useState(false);
  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm<ResumeFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      schoolName: '',
      schoolStatus: undefined,
      major: '',
    },
  });
  const { mutate: useUpdateResumeMutate } = useUpdateResumeMutation();

  useEffect(() => {
    if (!myResume) {
      return;
    }
    setAddress(myResume.address);
    reset({
      ...myResume,
      personalId1: myResume.personalId.slice(0, 6),
      personalId2: myResume.personalId.slice(6),
      accountImg: myResume.accountImgUrl,
      healthImg: myResume.healthImgUrl,
      agreementFile: myResume.agreementFileUrl,
      resumeFile: myResume.resumeFileUrl,
      coverLetterFile: myResume.coverLetterFileUrl,
    });
  }, [reset, myResume]);

  useEffect(() => {
    if (!watch('accountImg')) {
      setIsAccountImgEmpty(true);
    } else {
      setIsAccountImgEmpty(false);
    }
  }, [watch('accountImg')]);

  useEffect(() => {
    if (!watch('userImage')) {
      setIsUserImageEmpty(true);
    } else {
      setIsUserImageEmpty(false);
    }
  }, [watch('userImage')]);

  if (!myResume) {
    return <></>;
  }

  const {
    userImage,
    accountImg,
    healthImg,
    agreementFile,
    resumeFile,
    coverLetterFile,
  } = watch();

  return (
    <>
      {isOpenPost && (
        <Address
          setAddress={(address: string) => setAddress(address)}
          isOpenPost={isOpenPost}
          setIsOpenPost={(isOpenPost: boolean) => setIsOpenPost(isOpenPost)}
        />
      )}
      <MobileTopBar title="지원서 수정" />
      <div className="mb-36 mt-32 md:grid md:place-items-center">
        <div className="flex w-full max-w-4xl flex-col px-5 md:px-0">
          <div className="hidden space-y-5 md:block">
            <h3 className="text-28 ">지원서 수정</h3>
          </div>
          <form
            className="mt-0 grid divide-y divide-blue-100 md:mt-6"
            onSubmit={handleSubmit(async (data) => {
              setButtonClicked(true);
              if (!address) {
                toast.error('주소를 검색해주세요.');
                setButtonClicked(false);
                return;
              }
              if (!data.accountImg || !watch('userImage')) {
                setButtonClicked(false);
                return;
              }

              const formData = omit(data, [
                'personalId1',
                'personalId2',
                'careers',
                'certificates',
                'accountImg',
                'healthImg',
                'agreementFile',
                'resumeFile',
                'coverLetterFile',
              ]);
              const personalId = join([data.personalId1, data.personalId2], '');
              const { schoolName, schoolStatus, major } = formData;

              let userImage = '',
                accountImgUrl = '',
                healthImgUrl = '',
                resumeFileUrl = '',
                agreementFileUrl = '',
                coverLetterFileUrl = '';

              if (data.userImage instanceof FileList && data.userImage[0]) {
                await uploadFile(data.userImage[0]).then(
                  (res: AxiosResponse<string>) => (userImage = res.data)
                );
              } else if (typeof data.userImage === 'string') {
                userImage = data.userImage;
              }
              if (data.accountImg instanceof FileList && data.accountImg[0]) {
                await uploadFile(data.accountImg[0]).then(
                  (res: AxiosResponse<string>) => (accountImgUrl = res.data)
                );
              } else if (typeof data.accountImg === 'string') {
                accountImgUrl = data.accountImg;
              }
              if (data.healthImg instanceof FileList && data.healthImg[0]) {
                await uploadFile(data.healthImg[0]).then(
                  (res: AxiosResponse<string>) => (healthImgUrl = res.data)
                );
              } else if (typeof data.healthImg === 'string') {
                healthImgUrl = data.healthImg;
              }
              if (
                data.agreementFile instanceof FileList &&
                data.agreementFile[0]
              ) {
                await uploadFile(data.agreementFile[0]).then(
                  (res: AxiosResponse<string>) => (agreementFileUrl = res.data)
                );
              } else if (typeof data.agreementFile === 'string') {
                agreementFileUrl = data.agreementFile;
              }
              if (data.resumeFile instanceof FileList && data.resumeFile[0]) {
                await uploadFile(data.resumeFile[0]).then(
                  (res: AxiosResponse<string>) => (resumeFileUrl = res.data)
                );
              } else if (typeof data.resumeFile === 'string') {
                resumeFileUrl = data.resumeFile;
              }
              if (
                data.coverLetterFile instanceof FileList &&
                data.coverLetterFile[0]
              ) {
                await uploadFile(data.coverLetterFile[0]).then(
                  (res: AxiosResponse<string>) =>
                    (coverLetterFileUrl = res.data)
                );
              } else if (typeof data.coverLetterFile === 'string') {
                coverLetterFileUrl = data.coverLetterFile;
              }

              const result = {
                ...formData,
                personalId,
                address,
                ...(schoolName && { schoolName }),
                ...(schoolStatus
                  ? { schoolStatus }
                  : { schoolStatus: undefined }),
                ...(major && { major }),
                userImage,
                accountImgUrl,
                healthImgUrl,
                resumeFileUrl,
                agreementFileUrl,
                coverLetterFileUrl,
              };

              await useUpdateResumeMutate({
                ...result,
                id: myResume.id,
              });
              setButtonClicked(false);
            })}
          >
            <div className="pb-20">
              <h3 className="mb-9">지원서 사진(필수)</h3>
              <Label compulsory>사진</Label>
              <IdPhoto
                accept={['.png', '.jpg', '.jpeg']}
                src={
                  userImage instanceof FileList
                    ? userImage[0] && URL.createObjectURL(userImage[0])
                    : userImage
                }
                value={userImage}
                setValue={(value: FileList | string) =>
                  setValue('userImage', value)
                }
                helper={isUserImageEmpty ? '지원서 사진을 업로드해주세요.' : ''}
              />
            </div>
            <div className="py-20">
              <h3 className="mb-9">계좌 정보 (필수)</h3>
              <div className="gap-8 space-y-5 md:grid md:grid-cols-2 md:space-y-0">
                <TextField
                  label="예금주"
                  compulsory
                  helper={errors.depositor?.message}
                  placeholder="예금주명을 입력해주세요."
                  {...register('depositor', {
                    required: '이름을 입력해주세요.',
                  })}
                />
                <div className="col-start-1">
                  <Select
                    label="은행"
                    compulsory
                    helper={errors.bankCode?.message}
                    placeholder="은행을 선택해주세요."
                    {...register('bankCode', {
                      required: '은행을 선택해주세요.',
                    })}
                  >
                    <option disabled selected hidden value="">
                      은행을 선택해주세요.
                    </option>

                    {map(BankCode, (bank, index) => (
                      <option key={index} value={bank}>
                        {KOREAN_BANK_NAME[bank]}
                      </option>
                    ))}
                  </Select>
                </div>

                <TextField
                  label="계좌번호"
                  type="number"
                  compulsory
                  helper={errors.bankAccount?.message}
                  placeholder="계좌번호를 (-) 없이 입력해주세요."
                  {...register('bankAccount', {
                    required: '계좌번호를 입력해주세요.',
                    pattern: {
                      value: /^\d+$/,
                      message: '올바른 계좌번호가 아닙니다.',
                    },
                  })}
                />
                <FileUpload
                  label="통장사본"
                  compulsory
                  desc="통장 사본은 모바일 캡쳐본으로 대체 가능합니다."
                  accept={['.pdf', '.png', '.jpeg', '.jpg']}
                  src={
                    accountImg instanceof FileList
                      ? accountImg[0] && URL.createObjectURL(accountImg[0])
                      : accountImg
                  }
                  isValueFile={accountImg instanceof FileList}
                  config={register('accountImg')}
                  resetValue={() => setValue('accountImg', '')}
                  fileName={
                    accountImg instanceof FileList
                      ? accountImg[0] && accountImg[0].name
                      : (accountImg || '').split(/_(.*)/s).slice(1)[0]
                  }
                  helper={isAccountImgEmpty ? '통장사본을 업로드해주세요.' : ''}
                />
              </div>
            </div>
            <div className="py-20">
              <div className="mb-9 space-y-4">
                <h3 className="">기본 정보 (필수)</h3>
                <div className="flex space-x-1.5 pr-5 text-gray-600">
                  <Icon.AlertCircle className="wh-5 flex-none" />
                  <p className="text-14">
                    이름은 '내 정보 수정'에서 수정 가능합니다.
                  </p>
                </div>
              </div>
              <div className="gap-8 space-y-5 md:grid md:grid-cols-2 md:space-y-0">
                <TextField
                  label="이름"
                  compulsory
                  placeholder="이름을 입력해주세요."
                  disabled
                  helper={errors.userName?.message}
                  {...register('userName', {
                    required: '이름을 입력해주세요.',
                  })}
                />
                <TextField
                  label="이메일"
                  compulsory
                  placeholder="이메일을 입력해주세요."
                  helper={errors.email?.message}
                  {...register('email', {
                    required: '이메일을 입력해주세요.',
                    pattern: {
                      value:
                        /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: '올바른 이메일 형식이 아닙니다.',
                    },
                  })}
                />
                <TextField
                  label="생년월일"
                  compulsory
                  placeholder="생년월일을 선택해주세요."
                  helper={errors.birth?.message}
                  type="date"
                  max="9999-12-31"
                  {...register('birth', {
                    required: '생년월일을 선택해주세요.',
                  })}
                  className="w-full"
                />
                <Select
                  label="성별"
                  compulsory
                  helper={errors.gender?.message}
                  {...register('gender', { required: '성별을 선택해주세요.' })}
                >
                  <option disabled selected hidden value="">
                    성별을 선택해주세요.
                  </option>

                  {map(GENDER, (gender, index) => (
                    <option key={index} value={gender}>
                      {KOREAN_GENDER[gender]}
                    </option>
                  ))}
                </Select>
                <div className="space-y-2">
                  <Label text="주민등록번호" compulsory />
                  <div className="flex items-center space-x-3">
                    <TextField
                      type="number"
                      className="w-32 md:w-full"
                      placeholder="ex) 990524"
                      helper={errors.personalId1?.message}
                      {...register('personalId1', {
                        required: '주민등록번호를 입력해주세요.',
                        pattern: {
                          value: /\d{6}/,
                          message: '올바른 주민등록번호가 아닙니다.',
                        },
                        maxLength: {
                          value: 6,
                          message: '올바른 주민등록번호가 아닙니다.',
                        },
                      })}
                    />
                    <span className=""> - </span>
                    <TextField
                      type="number"
                      placeholder="1234567"
                      className="w-32 md:w-full"
                      helper={errors.personalId2?.message}
                      {...register('personalId2', {
                        required: '주민등록번호를 입력해주세요.',
                        pattern: {
                          value: /\d{7}/,
                          message: '올바른 주민등록번호가 아닙니다.',
                        },
                        maxLength: {
                          value: 7,
                          message: '올바른 주민등록번호가 아닙니다.',
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="col-span-2 col-start-1 space-y-2 ">
                  <Label compulsory>주소</Label>
                  <div className="flex items-center space-x-2">
                    <input
                      className="textfield w-32 flex-1 rounded-md md:w-full"
                      placeholder="주소를 검색해주세요."
                      value={address}
                      disabled
                    />
                    <Button
                      type="button"
                      text="주소 검색"
                      onClick={() => setIsOpenPost(true)}
                      className="h-12 w-24 border border-brand-1 !px-4 text-brand-1 md:w-max md:!px-8"
                    />
                  </div>
                  <TextField
                    placeholder="상세주소 입력하기"
                    helper={errors.addressDetail?.message}
                    {...register('addressDetail', {
                      required: '상세주소를 입력해주세요.',
                    })}
                  />
                </div>
              </div>
            </div>
            <div className="py-20">
              <h3 className="mb-9">최종학력</h3>
              <div className="gap-8 space-y-5 md:grid md:grid-cols-2 md:space-y-0">
                <TextField
                  label="학교"
                  placeholder="학교명을 입력해주세요."
                  {...register('schoolName')}
                />

                <div>
                  <Select label="학적" {...register('schoolStatus')}>
                    <option disabled selected hidden value="">
                      학적을 선택해주세요.
                    </option>
                    {map(SCHOOL_STATUS, (status, index) => (
                      <option key={index} value={status}>
                        {KOREAN_SCHOOL_STATUS[status]}
                      </option>
                    ))}
                  </Select>
                </div>

                <TextField
                  label="전공"
                  placeholder="전공을 입력해주세요."
                  {...register('major')}
                />
              </div>
            </div>
            <div className="py-20">
              <h3 className="mb-9">근무 확인사항</h3>
              <div className="grid grid-cols-1 gap-8">
                <FileUpload
                  label="보건증"
                  desc="최근 6개월 이내 발급된 보건증을 업로드해주세요."
                  accept={['.pdf', '.png', '.jpeg', '.jpg']}
                  src={
                    healthImg instanceof FileList
                      ? healthImg[0] && URL.createObjectURL(healthImg[0])
                      : healthImg
                  }
                  isValueFile={healthImg instanceof FileList}
                  config={register('healthImg')}
                  resetValue={() => setValue('healthImg', '')}
                  fileName={
                    healthImg instanceof FileList
                      ? healthImg[0] && healthImg[0].name
                      : (healthImg || '').split(/_(.*)/s).slice(1)[0]
                  }
                />
                <FileUpload
                  label="부모님 동의서"
                  desc="부모님 동의서를 업로드해주세요."
                  accept={[
                    '.pdf',
                    '.png',
                    '.jpeg',
                    '.jpg',
                    '.doc',
                    '.hwp',
                    '.docx',
                  ]}
                  src={
                    agreementFile instanceof FileList
                      ? agreementFile[0] &&
                        URL.createObjectURL(agreementFile[0])
                      : agreementFile
                  }
                  isValueFile={agreementFile instanceof FileList}
                  config={register('agreementFile')}
                  resetValue={() => setValue('agreementFile', '')}
                  fileName={
                    agreementFile instanceof FileList
                      ? agreementFile[0] && agreementFile[0].name
                      : (agreementFile || '').split(/_(.*)/s).slice(1)[0]
                  }
                  hasFormFile
                />
              </div>
            </div>
            <div className="py-20">
              <h3 className="mb-9">이력서</h3>
              <div className="grid grid-cols-1 gap-8">
                <FileUpload
                  label="나의 이력서"
                  desc="이력서를 등록해주세요."
                  accept={['.pdf', '.doc', '.hwp', '.docx']}
                  src={
                    resumeFile instanceof FileList
                      ? resumeFile[0] && URL.createObjectURL(resumeFile[0])
                      : resumeFile
                  }
                  isValueFile={resumeFile instanceof FileList}
                  config={register('resumeFile')}
                  resetValue={() => setValue('resumeFile', '')}
                  fileName={
                    resumeFile instanceof FileList
                      ? resumeFile[0] && resumeFile[0].name
                      : (resumeFile || '').split(/_(.*)/s).slice(1)[0]
                  }
                />
                <FileUpload
                  label="나의 자기소개서"
                  desc="자기소개서를 등록해주세요."
                  accept={['.pdf', '.doc', '.hwp', '.docx']}
                  src={
                    coverLetterFile instanceof FileList
                      ? coverLetterFile[0] &&
                        URL.createObjectURL(coverLetterFile[0])
                      : coverLetterFile
                  }
                  isValueFile={coverLetterFile instanceof FileList}
                  config={register('coverLetterFile')}
                  resetValue={() => setValue('coverLetterFile', '')}
                  fileName={
                    coverLetterFile instanceof FileList
                      ? coverLetterFile[0] && coverLetterFile[0].name
                      : (coverLetterFile || '').split(/_(.*)/s).slice(1)[0]
                  }
                />
              </div>
            </div>
            <div className="py-20">
              <CareerList resumeId={String(myResume.id)} isAdmin={false} />
            </div>
            <div className="pt-20">
              <CertificateList resumeId={String(myResume.id)} isAdmin={false} />
            </div>
            <ResumeBottomNavBar buttonClicked={buttonClicked} />
          </form>
        </div>
      </div>
    </>
  );
};
