import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import { Modal } from '../components/modal/Modal';
import { useAuth } from '../hooks';
import { meState } from '../plugins/ridge';
import AdminMain from './components/AdminMain';
import { AppliedResume } from './pages/applied-resume/AppliedResume';
import { CompanyAdd } from './pages/company/CompanyAdd';
import { CompanyDetail } from './pages/company/CompanyDetail';
import { CompanyList } from './pages/company/CompanyList';
import { CompanyManage } from './pages/company/CompanyManage';
import { CompanyPartTimeJob } from './pages/company/CompanyPartTimeJob';
import { ComepanyPartTimeJobAdd } from './pages/company/CompanyPartTimeJobAdd';
import { CompanyPartTimeJobDate } from './pages/company/CompanyPartTimeJobDate';
import { CompanyPartTimeJobDetail } from './pages/company/CompanyPartTimeJobDetail';
import { CompanyPartTimeJobEdit } from './pages/company/CompanyPartTimeJobEdit';
import { CompanyIntro } from './pages/introduction/CompanyIntro';
import { CompanyIntroAdd } from './pages/introduction/CompanyIntroAdd';
import { CompanyIntroDetail } from './pages/introduction/CompanyIntroDetail';
import { IntroductionManage } from './pages/introduction/IntroductionManage';
import { PartnersIntro } from './pages/introduction/PartnersIntro';
import { NoticeAdd } from './pages/notice/NoticeAdd';
import { NoticeDetail } from './pages/notice/NoticeDetail';
import { NoticeList } from './pages/notice/NoticeList';
import { GotoWorkList } from './pages/part-time-job/GotoWorkList';
import { PartTimeJobDetail } from './pages/part-time-job/PartTimeJobDetail';
import { PartTimeJobList } from './pages/part-time-job/PartTimeJobList';
import { PartTimeJobManageList } from './pages/part-time-job/PartTimeJobManageList';
import { PaymentRequestPage } from './pages/payment-request/PaymentRequestPage';
import { ApplicationList } from './pages/users/ApplicationList';
import { ContractDetail } from './pages/users/ContractDetail';
import { HoursPayList } from './pages/users/HoursPayList';
import { PaymentRequestList } from './pages/users/PaymentRequestList';
import { ResumeInfo } from './pages/users/ResumeInfo';
import { UserInfo } from './pages/users/UserInfo';
import { UserList } from './pages/users/UserList';
import { UserManage } from './pages/users/UserManage';

export const Admin = () => {
  const { logout } = useAuth();
  const me = meState.useValue();
  const { pathname } = useLocation();

  return (
    <>
      <Modal />
      <div className="flex h-screen">
        <Sidebar className="w-48">
          <Sidebar.Title>The SCV</Sidebar.Title>
          <Sidebar.Menu>
            <Sidebar.Menu.Item
              className="pl-4"
              text="회원 관리"
              to="/admin/users"
            />
            <Sidebar.Menu.Item
              className="pl-4"
              text="회사 관리"
              to="/admin/company"
            />
            <Sidebar.Menu.Item
              className="pl-4"
              text="알바 신청자 관리"
              to="/admin/part-time-job"
            />
            {me && me.isOperator && (
              <Sidebar.Menu.Item
                className="pl-4"
                text="급여 정산"
                to="/admin/payment-request"
              />
            )}
            <Sidebar.Menu.Item
              className="pl-4"
              text="공지사항 관리"
              to="/admin/notice"
            />
            <Sidebar.Menu.Item
              className="pl-4"
              text="회사 소개, 협력사 소개 관리"
              to="/admin/introduction/company"
            />
            <Sidebar.Menu.Item
              className="pl-4"
              text="로그아웃"
              onClick={logout}
            />
          </Sidebar.Menu>
        </Sidebar>

        <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
          {/* <AdminHeader /> */}
          <AdminMain hasMaxWidth={!pathname.includes('/payment-request')}>
            <Routes>
              <Route
                path="/users/:id/contractinfo"
                element={<ContractDetail />}
              />
              <Route path="/users/:id" element={<UserManage />}>
                <Route
                  path=""
                  element={<Navigate replace to="/admin/users" />}
                />
                <Route path="info" element={<UserInfo />} />
                <Route path="resume" element={<ResumeInfo />} />
                <Route path="application" element={<ApplicationList />} />
                <Route
                  path="payment-request"
                  element={<PaymentRequestList />}
                />
                <Route path="hours-pay" element={<HoursPayList />} />
              </Route>
              <Route path="users" element={<UserList />} />
              <Route
                path="/company/:id/part-time-job/:partTimeJobId"
                element={<CompanyPartTimeJobDetail />}
              >
                <Route path="edit" element={<CompanyPartTimeJobEdit />} />
                <Route path="date" element={<CompanyPartTimeJobDate />} />
              </Route>
              <Route
                path="/company/:id/part-time-job/add"
                element={<ComepanyPartTimeJobAdd />}
              />
              <Route path="/company/add" element={<CompanyAdd />} />
              <Route path="/company/:id" element={<CompanyManage />}>
                <Route
                  path=""
                  element={<Navigate replace to={`/admin/company`} />}
                />
                <Route path="info" element={<CompanyDetail />} />
                <Route path="part-time-job" element={<CompanyPartTimeJob />} />
              </Route>
              <Route path="/company" element={<CompanyList />} />

              <Route path="/part-time-job/:id" element={<PartTimeJobDetail />}>
                <Route path="manage/:day" element={<PartTimeJobManageList />} />
                <Route path="manage" element={<PartTimeJobManageList />} />
                <Route path="go-to-work/:day" element={<GotoWorkList />} />
                <Route path="go-to-work" element={<GotoWorkList />} />
                <Route
                  path=""
                  element={<Navigate replace to={`/admin/part-time-job`} />}
                />
              </Route>
              <Route path="/applied-resume/:id" element={<AppliedResume />} />
              <Route path="/part-time-job" element={<PartTimeJobList />} />
              <Route path="/payment-request" element={<PaymentRequestPage />} />
              <Route path="/notice/add" element={<NoticeAdd />} />
              <Route path="/notice/:id" element={<NoticeDetail />} />
              <Route path="/notice" element={<NoticeList />} />

              <Route path="/introduction" element={<IntroductionManage />}>
                <Route
                  path=""
                  element={<Navigate replace to={`/introduction/company`} />}
                />
                <Route
                  path="/introduction/partners"
                  element={<PartnersIntro />}
                />
                <Route
                  path="/introduction/company"
                  element={<CompanyIntro />}
                />
              </Route>
              <Route
                path="/introduction/company/add"
                element={<CompanyIntroAdd />}
              />
              <Route
                path="/introduction/company/:id"
                element={<CompanyIntroDetail />}
              />
              <Route path="" element={<Navigate replace to="/admin/users" />} />
            </Routes>
          </AdminMain>
        </div>
      </div>
    </>
  );
};
