import React, { useState } from 'react';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
import { useNavigate, useParams } from 'react-router-dom';
import { BookmarkButton } from '../../../components/BookmarkButton';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { PartTimeDateCard } from '../../../components/PartTimeDateCard';
import { PartTimeJobAsk } from '../../../components/PartTimeJobAsk';
import { PartTimeJobInfo } from '../../../components/PartTimeJobInfo';
import { useModal } from '../../../components/modal/Modal';
import { usePartTimeJobById } from '../../../hooks/part-time-job';
import { useMyResume } from '../../../hooks/resume';
import { meState } from '../../../plugins/ridge';

export const PartTimeJobDetailPage = () => {
  const navigate = useNavigate();
  const me = meState.useValue();
  const { id: partTimeJobId } = useParams<{ id: string }>();
  const { data: myResume } = useMyResume();
  const { partTimeLogin, partTimeResume } = useModal();
  const [isOpen, setIsOpen] = useState(true);
  const { data: ptj } = usePartTimeJobById(partTimeJobId ? +partTimeJobId : 0);

  if (!ptj || !partTimeJobId) {
    return <></>;
  }

  return (
    <>
      <div className="mt-32 grid place-items-center">
        <div className="mt-0 grid w-full max-w-4xl gap-5 md:mt-4">
          <div className="relative">
            <button
              className="absolute top-5 left-5 block rounded-full bg-white p-2 md:hidden"
              onClick={() => navigate(-1)}
            >
              <Icon.ArrowLeft />
            </button>
            <img
              className="aspect-[5/3] w-full rounded-none border-b border-gray-200 object-cover md:aspect-[5/2] md:rounded-lg md:border"
              src={ptj.mainImgUrl}
              crossOrigin="anonymous"
              alt=""
            />
            <div className="mx-5 md:mx-0">
              <div className="mt-8 flex justify-between">
                <div className="mr-2">
                  <h3 className="mb-2 text-24 font-bold">{ptj.title}</h3>
                  <p className="font-medium">{ptj.company.companyName}</p>
                </div>
                <BookmarkButton
                  partTimeJobId={+partTimeJobId}
                  isLogin={!!me}
                  isLike={ptj.userPartTimeJobs?.length !== 0}
                />
              </div>
              <div className="mt-10">
                <h3 className="mb-2 text-24 font-bold">
                  시급{' '}
                  <span className="text-brand-1">
                    {ptj.hourPay.toLocaleString()}
                  </span>
                  원
                </h3>
                <h5>
                  <span className="text-gray-700">모집내용 | </span>
                  <span>{ptj.recruitDescription}</span>
                </h5>
                <div className="flex items-center space-x-1 rounded-md py-2 ">
                  <Icon.MapPin className="wh-5 flex-none  text-brand-1" />
                  <p className="truncate whitespace-normal text-13 font-medium md:text-16">
                    {ptj.company.address} {ptj.company.addressDetail}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex space-x-8 pt-8 md:border-b md:pb-12">
              <PartTimeJobInfo partTimeJob={ptj} />
            </div>
            <div className="mx-5 md:mx-0">
              <div className="hidden border-b py-12 md:block">
                <div className="flex justify-between">
                  <h5>지원 가능한 날짜</h5>
                  <button
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <Icon.ChevronDown
                      className={`${isOpen ? `rotate-180` : ``} text-gray-600`}
                    />
                  </button>
                </div>
                {isOpen && (
                  <div className="mt-6">
                    <PartTimeDateCard
                      ptjDate={ptj.partTimeJobDates}
                      partTimeJobId={+partTimeJobId}
                    />
                  </div>
                )}
              </div>

              <div className="border-b py-12 ">
                <h4>상세정보</h4>
                <div className="mt-6">
                  <div className="grid w-full place-content-center">
                    <FroalaEditorView model={ptj.description} />
                  </div>
                </div>
              </div>
              <PartTimeJobAsk company={ptj.company} />
            </div>

            <Button
              text="지원하러 가기"
              className="sticky bottom-0 mt-6 h-16 w-full max-w-4xl rounded-none bg-brand-1 text-white md:bottom-5 md:rounded-lg"
              onClick={() => {
                if (!me) {
                  return partTimeLogin();
                }
                if (!myResume) {
                  return;
                }
                if (myResume?.bankAccount) {
                  navigate(`/part-time-job/${partTimeJobId}/apply`);
                } else {
                  partTimeResume();
                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
