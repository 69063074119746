import { Contract } from '../contract/type';
import { BankCode } from '../resume/type';

export enum Worker {
  ONEDAY = 'ONEDAY',
  BUSINESS = 'BUSINESS',
}

export const KOREAN_WORKER = {
  [Worker.ONEDAY]: '일용직',
  [Worker.BUSINESS]: '사업자소득',
};
export enum PaymentStatus {
  PAID = 'PAID',
  APPLIED = 'APPLIED',
}

export const KOREAN_PAYMENT_STATUS = {
  [PaymentStatus.PAID]: '지급완료',
  [PaymentStatus.APPLIED]: '지급신청',
};

export const KOREAN_PAYMENT_STATUS2 = {
  [PaymentStatus.PAID]: '급여지급 완료',
  [PaymentStatus.APPLIED]: '급여지급 신청완료',
};
export interface Payment {
  id: number;
  bankCode: BankCode;
  worker: Worker; // 근무자 구분
  paymentStatus: PaymentStatus; // 결제 진행 상태
  paymentDate: string; // 결제일
  restTime: number; // 휴게 시간
  hourPay: number; // 시급
  totalWorkTime: number; // 총 근무 시간
  baseAmount: number; // 기본 급여
  overtimeHour: number; // 연장 시간
  overtimePay: number; // 연장 수당
  nightHour: number; // 야간 시간
  nightPay: number; // 야간 근무 수당
  holidayPay: number; // 주휴 수당
  otherPay: number; //기타 수당
  totalExtraAmount: number; // 수당 합계
  totalAmount: number; // 총 금액
  insurance: number; // 4대 보험
  fee: number; // 타행 수수료
  incomeTax: number; //소득세
  deductedAmount: number; // 차감 금액
  finalAmount: number; //실 지급액
  startWorkHour: number; // 근무 시작 시간
  endWorkHour: number; // 근무 종료 시간
  memo: string; // 비고
  contract: Contract; // 계약서
}

export interface CreatePaymentDto {
  worker?: Worker; // 근무자 구분
  paymentStatus: PaymentStatus; // 결제 진행 상태
  paymentDate: string; // 결제일
  restTime: number; // 휴게 시간
  hourPay: number; // 시급
  totalWorkTime: number; // 총 근무 시간
  baseAmount: number; // 기본 급여
  overtimeHour: number; // 연장 시간
  overtimePay: number; // 연장 수당
  nightHour: number; // 야간 시간
  nightPay: number; // 야간 근무 수당
  holidayPay: number; // 주휴 수당
  otherPay: number; //기타 수당
  totalExtraAmount: number; // 수당 합계
  totalAmount: number; // 총 금액
  insurance: number; // 4대 보험
  fee: number; // 타행 수수료
  incomeTax: number; //소득세
  deductedAmount: number; // 차감 금액
  finalAmount: number; //실 지급액
  startWorkHour: number; // 근무 시작 시간
  endWorkHour: number; // 근무 종료 시간
  memo: string; // 비고
}

export interface UpdatePaymentDto {
  id: number;
  worker?: Worker; // 근무자 구분
  paymentStatus?: PaymentStatus; // 결제 진행 상태
  paymentDate?: string; // 결제일
  restTime?: number; // 휴게 시간
  hourPay?: number; // 시급
  totalWorkTime?: number; // 총 근무 시간
  baseAmount?: number; // 기본 급여
  overtimeHour?: number; // 연장 시간
  overtimePay?: number; // 연장 수당
  nightHour?: number; // 야간 시간
  nightPay?: number; // 야간 근무 수당
  holidayPay?: number; // 주휴 수당
  otherPay?: number; //기타 수당
  totalExtraAmount?: number; // 수당 합계
  totalAmount?: number; // 총 금액
  insurance?: number; // 4대 보험
  fee?: number; // 타행 수수료
  incomeTax?: number; //소득세
  deductedAmount?: number; // 차감 금액
  finalAmount?: number; //실 지급액
  startWorkHour?: number; // 근무 시작 시간
  endWorkHour?: number; // 근무 종료 시간
  memo?: string; // 비고
}

export interface DeletePaymentDto {
  ids: number[];
}
