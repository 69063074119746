import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { modalState } from '../../plugins/ridge';

interface AnimationLayoutProps {
  children: ReactNode;
  open: boolean;
  onClose?: () => void;
  isAdmin?: boolean;
}

export const AnimationLayout: React.FC<AnimationLayoutProps> = ({
  children,
  open,
  onClose,
  isAdmin,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 max-h-screen"
        onClose={() => {
          if (isAdmin) {
            return;
          }
          onClose ? onClose() : modalState.reset();
        }}
      >
        <div className="flex h-full items-center justify-center px-4 pt-4 pb-20 text-center ">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            {children}
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
