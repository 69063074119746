import { forwardRef, InputHTMLAttributes } from 'react';
import { useId } from 'react-id-generator';
import { Label } from './Label';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  compulsory?: boolean;
  label?: string;
  labelClassname?: string;
  helper?: string;
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      compulsory,
      className = '',
      label,
      labelClassname = '',
      helper,
      ...props
    },
    ref
  ) => {
    const [id] = useId(1, 'textfield');

    return (
      <div className="label-col">
        {label && (
          <Label
            htmlFor={id}
            text={label}
            className={`${labelClassname} text-gray-700`}
            compulsory={compulsory}
          />
        )}
        <input
          ref={ref}
          id={id}
          className={`textfield disabled:text-gray-600 ${
            helper ? 'border-error border-red-500' : ''
          } ${className}`}
          {...props}
        />
        {helper && <p className="text-error text-sm text-red-500">{helper}</p>}
      </div>
    );
  }
);
