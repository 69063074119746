import { api } from '../../plugins/axios';
import {
  ChangePasswordDto,
  FindPasswordDto,
  MobileVerificationDto,
} from './type';

export function createUser(mobileVerificationDto: MobileVerificationDto) {
  return api.post('/auth/mobile', mobileVerificationDto);
}

export function mobileAuth(mobileVerificationDto: MobileVerificationDto) {
  return api.post('/auth/mobile', mobileVerificationDto);
}

export function findPassword(findPasswordDto: FindPasswordDto) {
  return api.post('/auth/find-password', findPasswordDto);
}

export function changePassword(changePasswordDto: ChangePasswordDto) {
  return api.post('/auth/change-password', changePasswordDto);
}
