import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createPartnersIntroduction,
  deletePartnersIntroduction,
  getAllPartnersIntroductionByAdmin,
  updatePartnersIntroduction,
} from '../../api/partners-introduction';
import { PATHS } from '../../api/partners-introduction/paths';
import {
  PartnersIntroduction,
  CreatePartnersIntroductionDto,
  UpdatePartnersIntroductionDto,
} from '../../api/partners-introduction/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllPartnersIntroduction() {
  return useQuery<PartnersIntroduction[], AxiosError>(PATHS.ROOT, fetcher);
}
export function useAllPartnersIntroductionByAdmin(
  paginationDto: PaginationDto
) {
  return useQuery<Paginated<PartnersIntroduction>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllPartnersIntroductionByAdmin(paginationDto)
  );
}

export function usePartnersIntroductionById(id: number) {
  return useQuery<PartnersIntroduction, AxiosError>(
    PATHS.getOneById(id),
    fetcher,
    { enabled: !!id }
  );
}

export function usePartnersIntroductionByAdminId(id: number) {
  return useQuery<PartnersIntroduction, AxiosError>(
    PATHS.getOneById(id),
    adminFetcher,
    { enabled: !!id }
  );
}

export const useCreatePartnersIntroductionMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createPartnersIntroductionDto: CreatePartnersIntroductionDto) =>
      createPartnersIntroduction(createPartnersIntroductionDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('협력사 추가 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('협력사 추가 실패');
      },
    }
  );
};

export const useUpdatePartnersIntroductionMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updatePartnersIntroductionDto: UpdatePartnersIntroductionDto) =>
      updatePartnersIntroduction(updatePartnersIntroductionDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('협력사 수정 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('협력사 수정 실패');
      },
    }
  );
};

export const useDeletePartnersIntroductionMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deletePartnersIntroduction(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('협력사 삭제 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('협력사 삭제 실패');
    },
  });
};
