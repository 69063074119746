import { ReactComponent as BookmarkIcon } from '../assets/icons/icon-bookmark.svg';
import { useModal } from '../components/modal/Modal';
import { useCreateLike, useDeleteLike } from '../hooks/user-part-time-job';

interface BookmarkBookmarkButtonProps {
  partTimeJobId: number;
  isLike: boolean;
  isLogin: boolean;
}

export const BookmarkButton = ({
  partTimeJobId,
  isLike,
  isLogin,
}: BookmarkBookmarkButtonProps) => {
  const { loginRequired } = useModal();

  const { mutate: createLikeMutate } = useCreateLike();
  const { mutate: deleteLikeMutate } = useDeleteLike();

  return (
    <>
      <button
        onClick={(e) => {
          e.stopPropagation();
          if (isLogin) {
            if (isLike) {
              deleteLikeMutate(partTimeJobId);
            } else {
              createLikeMutate({ partTimeJobId });
            }
          } else {
            loginRequired();
          }
        }}
        className="wh-10 z-0 grid flex-none place-items-center rounded-md border border-gray-100"
      >
        <BookmarkIcon
          className={`${
            isLike ? `fill-sub-1 stroke-sub-1` : `stroke-gray-500`
          } wh-6`}
        />
      </button>
    </>
  );
};
