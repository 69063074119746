import { useEffect, useState } from 'react';
import { format, getMonth, setMonth } from 'date-fns';
import { includes } from 'lodash';
import moment from 'moment';
import Calendar from 'react-calendar';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { PartTimeJobDate } from '../../../api/part-time-job-date/type';
import { usePartTimeJobAdminById } from '../../../hooks/part-time-job';
import AdminH1 from '../../components/AdminH1';
import { Tab } from '../../components/Tab';

export const PartTimeJobDetail = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id: partTimeJobId, day } = useParams<{
    id: string;
    day: string;
  }>();

  const [clickedDay, setClickedDay] = useState<Date | null>(
    day ? new Date(day) : null
  );
  const [startDate, setStartDate] = useState<Date>(new Date());

  const { data: partTimeJob } = usePartTimeJobAdminById(
    partTimeJobId ? +partTimeJobId : 0
  );

  const isIncludesDate = (
    dates: Record<string, PartTimeJobDate[]>,
    date: Date
  ) => {
    if (includes(Object.keys(dates), format(date, 'yyyy-MM-dd'))) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const urlType = pathname.startsWith(
      `/admin/part-time-job/${partTimeJobId}/manage`
    )
      ? '/manage'
      : pathname.startsWith(`/admin/part-time-job/${partTimeJobId}/go-to-work`)
      ? '/go-to-work'
      : '';

    if (!clickedDay) {
      navigate(`/admin/part-time-job/${partTimeJobId}${urlType}`);
      return;
    }

    navigate(
      `/admin/part-time-job/${partTimeJobId}${urlType}/${format(
        clickedDay,
        'yyyy-MM-dd'
      )}`
    );
  }, [clickedDay]);

  if (!partTimeJob || !partTimeJobId) {
    return <></>;
  }

  return (
    <>
      <div className="px-8 pt-8">
        <AdminH1>신청자 목록 상세</AdminH1>
      </div>

      <div className="grid place-items-start">
        <div className="flex space-x-5">
          <div>
            <div className="max-w-md rounded-[10px] bg-sub-3">
              <Calendar
                className="text-md mx-auto flex w-full flex-col items-center justify-between space-y-3 pt-5 text-center"
                activeStartDate={startDate}
                formatDay={(locale, date) => moment(date).format('D')}
                onClickDay={(value) => {
                  if (
                    clickedDay &&
                    format(new Date(value), 'yyyy-MM-dd') ===
                      format(clickedDay, 'yyyy-MM-dd')
                  ) {
                    setClickedDay(null);
                  } else {
                    setClickedDay(new Date(value));
                  }
                }}
                onActiveStartDateChange={({ action }) => {
                  const currentMonth = getMonth(startDate);
                  const nextMonth = setMonth(startDate, currentMonth + 1);
                  const prevMonth = setMonth(startDate, currentMonth - 1);
                  if (action === 'next') {
                    setStartDate(nextMonth);
                  } else if (action === 'prev') {
                    setStartDate(prevMonth);
                  }
                }}
                defaultView="month"
                minDetail="month"
                maxDetail="month"
                next2Label={null}
                prev2Label={null}
                tileClassName={({ date }) => {
                  if (!isIncludesDate(partTimeJob.partTimeJobDates, date)) {
                    return 'text-gray-400';
                  }

                  const dateString = date.toDateString();
                  const clickedDayString = clickedDay?.toDateString();

                  if (dateString === clickedDayString) {
                    return '';
                  }

                  return 'text-bold';
                }}
                value={clickedDay}
                tileDisabled={({ date }) => {
                  if (!isIncludesDate(partTimeJob.partTimeJobDates, date)) {
                    return true;
                  }

                  return false;
                }}
                tileContent={({ date }) => {
                  const currentDate = new Date();
                  const isToday =
                    format(date, 'yyyy-MM-dd') ===
                    format(currentDate, 'yyyy-MM-dd');
                  if (isToday) {
                    return (
                      <div
                        className={`absolute -top-[2.7px] left-[42.7%] ${
                          isToday && 'text-sub-1'
                        }`}
                      >
                        •
                      </div>
                    );
                  } else {
                    return null;
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6 flex flex-col rounded-lg bg-gray-50 p-8">
        <div className="grid- grid grid-cols-3 text-gray-700">
          <p>회사명</p>
          <p>공고명</p>
        </div>

        <div className="grid grid-cols-3 grid-rows-3 font-medium">
          <p>{partTimeJob.company.companyName}</p>
          <Link
            to={`/part-time-job/${partTimeJobId}`}
            target="_blank"
            rel="noreferrer"
            className="text-purple-500 underline"
          >
            {partTimeJob.title}
          </Link>
        </div>

        <div className="grid grid-cols-3 text-gray-700">
          <p>모집 인원</p>
          <p>모집 내용</p>
        </div>

        <div className="grid grid-cols-3 font-medium">
          <p>{partTimeJob.recruitNumber}</p>
          <p>{partTimeJob.recruitDescription}</p>

          <Link
            to={`/admin/company/${partTimeJob.company.id}/part-time-job/${partTimeJobId}/edit`}
            className="place-self-center font-medium text-brand-1 "
          >
            공고 관리 {'>'}
          </Link>
        </div>
      </div>
      <nav className="space-x-6 px-8 pb-4 ">
        <Tab
          title="아르바이트 신청자 관리"
          onClick={() =>
            !pathname.startsWith(
              `/admin/part-time-job/${partTimeJobId}/manage`
            ) &&
            navigate(
              `/admin/part-time-job/${partTimeJobId}/manage${
                clickedDay ? `/${format(clickedDay, 'yyyy-MM-dd')}` : ``
              }`
            )
          }
          selected={pathname.startsWith(
            `/admin/part-time-job/${partTimeJobId}/manage`
          )}
        />
        <Tab
          title="출근여부 관리"
          onClick={() =>
            !pathname.startsWith(
              `/admin/part-time-job/${partTimeJobId}/go-to-work`
            ) &&
            navigate(
              `/admin/part-time-job/${partTimeJobId}/go-to-work${
                clickedDay ? `/${format(clickedDay, 'yyyy-MM-dd')}` : ``
              }`
            )
          }
          selected={pathname.startsWith(
            `/admin/part-time-job/${partTimeJobId}/go-to-work`
          )}
        />
      </nav>

      <Outlet />
    </>
  );
};
