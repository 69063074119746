import { Link, useLocation, Outlet, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import '../../components/swiper/swiper.css';
import { ApplyHistory } from '../../components/ApplyHistory';
import { Icon } from '../../components/Icon';
import { useMyResume } from '../../hooks/resume';
import { useDetailMe } from '../../hooks/user';
export const MyPage = () => {
  const { data: myResume } = useMyResume();

  const { data: me } = useDetailMe();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  if (!myResume) {
    return <></>;
  }
  return (
    <>
      <div className="mt-32 md:grid md:place-items-center">
        <div className="w-full max-w-4xl px-5 md:px-0">
          <div className="flex items-center justify-between">
            <h3 className=" text-28">{me?.name}</h3>
            <button
              onClick={() => navigate(`/mypage/edit`)}
              className="h-max text-gray-600"
            >
              내 정보 수정
            </button>
          </div>
          <div className="mt-8 flex flex-col space-y-5">
            <div className="flex justify-between rounded-md bg-sub-2 p-6 md:p-7">
              <h4 className="text-brand-1">내 지원서</h4>
              <button
                className="flex items-center space-x-2 text-gray-700"
                onClick={() => {
                  navigate(`/mypage/resume`);
                }}
              >
                <p>수정</p>
                <Icon.Edit className="wh-5" />
              </button>
            </div>
            {me && (
              <ApplyHistory
                contractCount={me.contractCount}
                approvedCount={me.approvedCount}
                applicationCount={me.applicationCount}
              />
            )}
          </div>

          <nav className=" flex space-x-3 pt-11">
            {[
              { text: '지원한 알바', path: '/mypage/history' },
              { text: '저장한 공고', path: '/mypage/like' },
            ].map((nav) => (
              <Link
                key={nav.text}
                to={nav.path}
                className={`${
                  pathname === nav.path
                    ? `border-b-2 border-brand-1 font-semibold text-gray-900`
                    : ``
                } p-2 `}
              >
                {nav.text}
              </Link>
            ))}
          </nav>
          <div className=" pt-8">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
