import { useEffect, useRef, useState } from 'react';
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';
import Froala from 'react-froala-wysiwyg';
import { toast } from 'react-toastify';
import { uploadAdminFroalaImage } from '../../api/file';

type FroalaEditorProps = {
  defaultValue?: string;
  onChange: (model: any) => void;
};

export default function FroalaEditor(props: FroalaEditorProps) {
  const ref = useRef({ editor: null });
  const [isFroalaInitialized, setIsFroalaInitialized] = useState(false);
  const [editor, setEditor] = useState<any>(undefined);
  const [model, setModel] = useState<string>('');

  const handleModelChange = (changeModel: any) => {
    setModel(changeModel);
    props.onChange(changeModel);
  };

  useEffect(() => {
    props.defaultValue && setModel(props.defaultValue);
  }, [props.defaultValue]);

  useEffect(() => {
    setEditor(ref.current.editor);
    editor && setIsFroalaInitialized(true);
  }, [ref.current]);

  useEffect(() => {
    setEditor(ref.current.editor);
    editor && setIsFroalaInitialized(true);
  }, [ref.current]);

  useEffect(() => {
    if (isFroalaInitialized) {
      editor.html.set(model);
    }
  }, [isFroalaInitialized]);

  return (
    <Froala
      model={model}
      onModelChange={handleModelChange}
      tag="textarea"
      config={{
        charCounterMax: 100000,
        key: process.env.REACT_APP_FROALA_KEY,
        imageUpload: true,
        imageDefaultAlign: 'center',
        imageDefaultDisplay: 'block',
        imageMaxSize: 5 * 1135 * 453,
        imageAllowedTypes: ['jpeg', 'jpg', 'png', 'gif'],
        events: {
          'image.beforeUpload': async function (images: File[]) {
            //Before image is uploaded
            const url = await uploadAdminFroalaImage(images[0])
              .catch((err: any) => {
                toast.error(err?.message);
              })
              .then((res: any) => {
                const { data } = res;
                // @ts-ignore
                this.image.insert(
                  process.env.REACT_APP_ADMIN_API_URL + '/images' + data,
                  null,
                  null,
                  // @ts-ignore
                  this.image.get()
                );
              });

            return false;
          },
        },
        // attribution: false,
        placeholderText: 'Start typing...',
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'strikeThrough',
              'subscript',
              'superscript',
              'fontFamily',
              'fontSize',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
          },
          moreRich: {
            buttons: [
              'insertLink',
              'insertImage',
              'emoticons',
              'insertTable',
              'fontAwesome',
              'specialCharacters',
              'embedly',
              'insertFile',
              'insertHR',
            ],
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              'fullscreen',
              'print',
              'getPDF',
              'spellChecker',
              'selectAll',
              'html',
              'help',
            ],
            align: 'right',
            buttonsVisible: 1,
          },
        },
        pluginsEnabled: [
          'table',
          'spell',
          'quote',
          'save',
          'quickInsert',
          'paragraphFormat',
          'paragraphStyle',
          'help',
          'draggable',
          'align',
          'link',
          'lists',
          'file',
          'image',
          'emoticons',
          'url',
          'video',
          'embedly',
          'colors',
          'entities',
          'inlineClass',
          'inlineStyle',
          'imageTUI',
          'codeView',
        ],
      }}
    />
  );
}
