import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreateApplicationDto, UpdateApplicationDto } from './type';

export function createApplication(createApplicationDto: CreateApplicationDto) {
  return api.post(PATHS.ROOT, createApplicationDto);
}

export function updateApplication(updateApplicationDto: UpdateApplicationDto) {
  const { id } = updateApplicationDto;
  return adminApi.patch(PATHS.getOneById(id), updateApplicationDto);
}

export function getAllApplicationByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get(`${PATHS.userId(userId)}${queryString}`)
    .then(({ data }) => data);
}

export function getAllMyApplications(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAllApplicationsByDateId(
  paginationDto: PaginationDto,
  partTimeJobId: number
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get(`${PATHS.partTimeJobId(partTimeJobId)}${queryString}`)
    .then(({ data }) => data);
}

export function approveApplications(updateApplicationIds: number[]) {
  return adminApi.patch(PATHS.APPROVE, {
    ids: updateApplicationIds,
  });
}

export function withdrawApplications(updateApplicationIds: number[]) {
  return adminApi.patch(PATHS.WITHDRAW, {
    ids: updateApplicationIds,
  });
}

export function workApplications(updateApplicationIds: number[]) {
  return adminApi.patch(PATHS.WORK, { ids: updateApplicationIds });
}

export function notWorkApplications(updateApplicationIds: number[]) {
  return adminApi.patch(PATHS.NOT_WORK, { ids: updateApplicationIds });
}

export function downloadPTJExcel(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.getPTJExcel}${queryString}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}

export function downloadWorkExcel(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.getWorkExcel}${queryString}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
  });
}
