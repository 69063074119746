import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { filter, includes, map } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  downloadExcel,
  downloadOnedayExcel,
  downloadTransferExcel,
} from '../../../api/payment';
import {
  KOREAN_PAYMENT_STATUS,
  Payment,
  PaymentStatus,
  Worker,
} from '../../../api/payment/type';
import { BankCode } from '../../../api/resume/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Checkbox } from '../../../components/Checkbox';
import { Select } from '../../../components/Select';
import { Table } from '../../../components/Table';
import { TextField } from '../../../components/TextField';
import { useModal } from '../../../components/modal/Modal';
import { useDebounce } from '../../../hooks';
import { useAllCompaniesNoPagination } from '../../../hooks/company';
import {
  useAllPaymentsByAdmin,
  useBulkUpdatePaymentMutation,
  useDeletePaymentMutation,
} from '../../../hooks/payment';
import { meState } from '../../../plugins/ridge';
import AdminH1 from '../../components/AdminH1';
import { ExcelDownButton } from '../../components/ExcelDownButton';
import { PaymentRequestRow } from './PaymentRequestRow';

export interface PaymentData {
  id: number;
  bankCode: BankCode;
  worker: Worker;
  restTime: number;
  startWorkHour: number;
  endWorkHour: number;
  hourPay: number;
  totalWorkTime: number;
  baseAmount: number;
  overtimeHour: number;
  overtimePay: number;
  nightHour: number;
  nightPay: number;
  holidayPay: number;
  otherPay: number;
  totalExtraAmount: number;
  totalAmount: number;
  insurance: number;
  fee: number;
  incomeTax: number;
  deductedAmount: number;
  finalAmount: number;
  memo: string;
}

export const PaymentRequestPage = () => {
  const { deletePayments } = useModal();
  const me = meState.useValue();
  const navigate = useNavigate();
  const [paymentDate, setPaymentDate] = useState<string>('');
  const [workPlace, setWorkPlace] = useState<string>('');
  const [paymentStatus, setPaymentStatus] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [filterObj, setFilterObj] = useState<{
    paymentDate?: string;
    paymentStatus?: string;
    workPlace?: string;
    userNameOrPersonalId?: string;
  }>({});
  const userNameOrPersonalId = useDebounce({ value: text, delay: 250 });
  const [checkedPayments, setCheckedPayments] = useState<Payment[]>([]);
  const { data: allCompanies } = useAllCompaniesNoPagination();
  const { data: getAllPayments, refetch } = useAllPaymentsByAdmin({
    filter: filterObj,
  });
  const [payments, setPayments] = useState<Payment[]>([]);
  const [isHideExtra, setIsHideExtra] = useState<boolean>(false);
  const isCheckAll = checkedPayments.length === payments.length;

  const _setPayment = (payment: Payment, index: number) => {
    const updatePayments = [...payments];
    updatePayments[index] = payment;
    setPayments(updatePayments);
  };

  const _onHandleChecked = (payment: Payment) => {
    if (includes(checkedPayments, payment)) {
      setCheckedPayments(
        filter(checkedPayments, (checkedPayment) => checkedPayment !== payment)
      );
      return;
    }
    setCheckedPayments([...checkedPayments, payment]);
  };

  const handleSelectAll = () => {
    if (!payments) {
      return;
    }
    if (!isCheckAll) {
      setCheckedPayments(map(payments, (payment) => payment));
    } else {
      setCheckedPayments([]);
    }
  };

  const _init = () => {
    setCheckedPayments([]);
  };
  const { mutate: updatePaymentMutate } = useBulkUpdatePaymentMutation(_init);
  const { mutate: DeletePaymentMutate } = useDeletePaymentMutation(_init);
  const onHideExtra = () => {
    setIsHideExtra(!isHideExtra);
  };

  useEffect(() => {
    if (getAllPayments) {
      setPayments(getAllPayments);
    }
  }, [getAllPayments]);

  useEffect(() => {
    if (!me) {
      return;
    }

    if (!me.isOperator) {
      navigate('/admin', { replace: true });
    }
  }, [me]);

  // useEffect(() => {
  //   setFilterObj({
  //     ...(paymentDate && { paymentDate }),
  //     ...(paymentStatus && { paymentStatus }),
  //     ...(workPlace && { workPlace }),
  //     ...(userNameOrPersonalId && { userNameOrPersonalId }),
  //   });
  //   refetch();
  // }, [paymentDate, paymentStatus, workPlace, userNameOrPersonalId]);

  return (
    <div className="relative min-h-[90vh] space-y-4 ">
      <AdminH1>급여 정산</AdminH1>
      <div className="flex items-center space-x-3">
        <Select
          className="w-full"
          defaultValue=""
          label="근무장소"
          value={workPlace}
          onChange={(e) => setWorkPlace(e.target.value)}
        >
          <option value="" key={0} hidden disabled>
            근무장소를 선택해주세요.
          </option>
          {allCompanies &&
            map(allCompanies, (company) => (
              <option key={company.id} value={company.companyName}>
                {company.companyName}
              </option>
            ))}
        </Select>
        <TextField
          className="w-40"
          type="date"
          max="9999-12-31"
          label="지급일"
          placeholder="지급일을 입력해주세요"
          value={paymentDate}
          onChange={(e) => setPaymentDate(e.target.value)}
        ></TextField>
        <TextField
          className="w-40"
          label="이름/주민번호"
          placeholder="ex) 김철수, 000101..."
          onChange={(e) => setText(e.target.value)}
        ></TextField>
        <Select
          className="w-full"
          defaultValue=""
          label="지급상태"
          value={paymentStatus}
          onChange={(e) => setPaymentStatus(e.target.value)}
        >
          <option value="">지급상태를 선택해주세요.</option>
          <option value={PaymentStatus.PAID}>
            {KOREAN_PAYMENT_STATUS[PaymentStatus.PAID]}
          </option>
          <option value={PaymentStatus.APPLIED}>
            {KOREAN_PAYMENT_STATUS[PaymentStatus.APPLIED]}
          </option>
        </Select>
        <Button
          text="검색"
          onClick={() =>
            setFilterObj({
              ...(paymentDate && { paymentDate }),
              ...(paymentStatus && { paymentStatus }),
              ...(workPlace && { workPlace }),
              ...(userNameOrPersonalId && { userNameOrPersonalId }),
            })
          }
          className="filled-blue-600 mt-5 h-12 whitespace-pre"
        />
        <Button
          text={`수당시급 ${isHideExtra ? '펼침' : '숨김'}`}
          onClick={() => onHideExtra()}
          className="filled-gray-800 mt-5 h-12"
        />
        <Button
          text={`삭제`}
          onClick={() => {
            const selectedIds = map(checkedPayments, (payment) => payment.id);

            deletePayments(() => DeletePaymentMutate({ ids: selectedIds }));
          }}
          className="filled-red-500 mt-5 h-12 whitespace-pre"
        />
      </div>
      <div className="flex space-x-3 text-sm">
        <p>{`총 ${getAllPayments?.length || 0}건`} </p>
        <p>{`체크 ${checkedPayments?.length}건`}</p>
      </div>
      <Card className="w-fit overflow-x-scroll">
        <Table className="overflow-x-visible ">
          <Table.Head>
            <Table.Row>
              <Table.Th>
                <Checkbox onClick={handleSelectAll} isChecked={isCheckAll} />
              </Table.Th>
              <Table.Th className="text-center">이름</Table.Th>
              <Table.Th className="w-4 text-center">
                <p className="whitespace-pre-wrap text-center">근로계약서</p>
              </Table.Th>
              <Table.Th className="w-4 text-center">
                <p className="whitespace-pre-wrap text-center">영수증</p>
              </Table.Th>
              <Table.Th className="text-center">근무자 구분</Table.Th>
              <Table.Th className="text-center text-gray-600 ">
                생년월일
              </Table.Th>
              <Table.Th className="text-center">근무지</Table.Th>
              <Table.Th className="text-center">근무일</Table.Th>
              <Table.Th className="text-center">지급상태</Table.Th>
              <Table.Th className="text-center">지급일</Table.Th>
              <Table.Th className="whitespace-pre-line text-center text-gray-600 ">
                공제(자동)
              </Table.Th>
              <Table.Th className="whitespace-pre-line text-center">
                공제(수동)
              </Table.Th>
              <Table.Th className="text-center">시급</Table.Th>
              <Table.Th className="text-center">출근</Table.Th>
              <Table.Th className="text-center">퇴근</Table.Th>
              <Table.Th className="text-center">근무시간</Table.Th>
              <Table.Th className="bg-red-50 text-center font-semibold text-red-500">
                기본급여
              </Table.Th>
              {!isHideExtra && (
                <>
                  <Table.Th className="bg-blue-50 text-center">
                    연장 시간
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    연장 수동
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    연장 수당
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    야간 시간
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    야간 수동
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    야간 수당
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    주휴수당
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center">
                    기타수당
                  </Table.Th>
                  <Table.Th className="bg-blue-50 text-center font-semibold text-blue-500">
                    수당합계
                  </Table.Th>
                </>
              )}
              <Table.Th className="bg-red-50 text-center text-red-500">
                총급여
              </Table.Th>
              <Table.Th className="bg-violet-50 text-center">보험료</Table.Th>
              <Table.Th className="whitespace-pre-line bg-violet-50 text-center">
                타행 수수료
              </Table.Th>
              <Table.Th className="bg-violet-50 text-center ">소득세</Table.Th>
              <Table.Th className="bg-violet-50 text-center ">
                차감 금액
              </Table.Th>
              <Table.Th className="bg-red-50 text-center text-red-500">
                실 지급액
              </Table.Th>
              <Table.Th className="text-center">비고</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {map(payments, (payment: Payment, index: number) => {
              const { id } = payment;
              return (
                <>
                  <PaymentRequestRow
                    payment={payment}
                    setPayment={(payment: Payment) =>
                      _setPayment(payment, index)
                    }
                    key={id}
                    handleClick={() => _onHandleChecked(payment)}
                    isChecked={includes(checkedPayments, payment)}
                    isHideExtra={isHideExtra}
                  />
                </>
              );
            })}
          </Table.Body>
        </Table>
      </Card>

      <div className="fixed bottom-0 z-10 mt-2 flex w-full items-center justify-between border-t border-gray-100 bg-white px-5 py-3.5 md:px-0">
        <div className="flex space-x-4">
          <ExcelDownButton
            type="button"
            text="엑셀 파일 다운"
            onClick={() => {
              downloadExcel({
                filter: {
                  ...filterObj,
                  checkedPaymentIds: checkedPayments.map((p) => p.id),
                },
              }).then((res) => {
                const url = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `급여정산_${format(
                  new Date(),
                  'yyyyMMddHHmmss'
                )}`;
                link.click();
                URL.revokeObjectURL(url);
              });
            }}
          />
          <ExcelDownButton
            type="button"
            text="은행이체내역"
            onClick={() => {
              downloadTransferExcel({
                filter: {
                  ...filterObj,
                  checkedPaymentIds: checkedPayments.map((p) => p.id),
                },
              }).then((res) => {
                const url = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `은행_이체내역_${format(
                  new Date(),
                  'yyyyMMddHHmmss'
                )}`;
                link.click();
                URL.revokeObjectURL(url);
              });
            }}
          />
          <ExcelDownButton
            type="button"
            text="일용직 신고"
            onClick={() => {
              downloadOnedayExcel({
                filter: {
                  ...filterObj,
                  checkedPaymentIds: checkedPayments.map((p) => p.id),
                },
              }).then((res) => {
                const url = URL.createObjectURL(res.data);
                const link = document.createElement('a');
                link.href = url;
                link.download = `일용직_신고_${format(
                  new Date(),
                  'yyyyMMddHHmmss'
                )}`;
                link.click();
                URL.revokeObjectURL(url);
              });
            }}
          />

          <Button
            type="button"
            text="지급완료"
            className=" h-12 w-32 min-w-max bg-blue-500 text-white"
            disabled={checkedPayments.length === 0}
            onClick={() => {
              const confirm = window.confirm(
                '선택된 결제정보들을 지급완료 상태로 변경하시겠습니까?'
              );

              if (confirm) {
                updatePaymentMutate(
                  checkedPayments.map((el) => ({
                    ...el,
                    paymentStatus: PaymentStatus.PAID,
                  }))
                );
              }
            }}
          />

          <Button
            type="button"
            text="지급신청"
            className=" h-12 w-32 min-w-max bg-red-500 text-white"
            disabled={checkedPayments.length === 0}
            onClick={() => {
              const confirm = window.confirm(
                '선택된 결제정보들을 지급신청 상태로 변경하시겠습니까?'
              );

              if (confirm) {
                updatePaymentMutate(
                  checkedPayments.map((el) => ({
                    ...el,
                    paymentStatus: PaymentStatus.APPLIED,
                    paymentDate: null,
                  }))
                );
              }
            }}
          />

          <Button
            type="button"
            text="저장"
            className=" h-12 w-32 min-w-max bg-gray-800 text-white"
            disabled={payments.length === 0}
            onClick={() =>
              updatePaymentMutate(
                payments.map((el) => ({
                  ...el,
                  paymentDate: el.paymentDate || null,
                }))
              )
            }
          />
        </div>
      </div>
    </div>
  );
};
