import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { PaymentData } from '../../admin/pages/payment-request/PaymentRequestPage';
import {
  createPayment,
  deletePayment,
  getAllPaymentsByAdmin,
  updatePayment,
} from '../../api/payment';
import { PATHS } from '../../api/payment/paths';
import {
  CreatePaymentDto,
  DeletePaymentDto,
  Payment,
} from '../../api/payment/type';
import { fetcher } from '../../plugins/react-query';
import { FilterType, PaginationDto } from '../../types';

export function useAllPaymentsByAdmin(paginationDto: PaginationDto) {
  return useQuery<Payment[], AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllPaymentsByAdmin(paginationDto),
    {
      keepPreviousData: true,
      enabled: !!Object.keys(paginationDto.filter as FilterType).length,
    }
  );
}

export function usePaymentById(id: number) {
  return useQuery<Payment, AxiosError>(PATHS.getOneById(id), fetcher, {
    enabled: !!id,
  });
}

export const useCreatePaymentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createPaymentDto: CreatePaymentDto) => createPayment(createPaymentDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('급여 생성 실패');
      },
    }
  );
};

export const useBulkUpdatePaymentMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation((payments: PaymentData[]) => updatePayment(payments), {
    onSuccess: () => {
      onSuccess && onSuccess();
      toast.success('업데이트 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
    },
    onError: () => {
      toast.error('업데이트 실패');
    },
  });
};

export const useDeletePaymentMutation = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (deletePaymentDto: DeletePaymentDto) => deletePayment(deletePaymentDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
        onSuccess();
        toast.success('삭제 완료');
      },
      onError: () => {
        toast.error('급여 삭제 실패');
      },
    }
  );
};
