import React, { FC } from 'react';
import { format, getDay } from 'date-fns';
import { Company } from '../api/company/type';
import { PartTimeJobDate } from '../api/part-time-job-date/type';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { getKoreanDay } from '../utils';
import { Icon } from './Icon';

interface SelectInfoProps {
  company: Company;
  workDate: Date | null;
  time: PartTimeJobDate | undefined;
}

export const SelectInfo: FC<SelectInfoProps> = ({
  company,
  workDate,
  time,
}) => {
  const { address, addressDetail } = company;

  return (
    <div className="grid grid-cols-1 rounded-md border border-brand-1 ">
      <div className="flex space-x-2 border-b border-gray-100 p-5">
        <Icon.MapPin className="wh-5 flex-none stroke-brand-1" />
        <p>
          <span>{address}</span>
          <span>{addressDetail}</span>
        </p>
      </div>
      {workDate && (
        <div className="flex space-x-2 border-b border-gray-100 p-5">
          <Icon.Calendar className="wh-5 flex-none stroke-brand-1" />

          <p>
            {format(workDate, 'yyyy/MM/dd')}({getKoreanDay(getDay(workDate))})
          </p>
        </div>
      )}

      {time && (
        <div className="flex space-x-2 p-5">
          <Icon.Clock className="wh-5 flex-none stroke-brand-1" />
          <span>
            {utcToLocalFormat(
              time.workingStartAt.toLocaleString(),
              MomentFormat.HHmm
            )}
          </span>{' '}
          <span>~</span>{' '}
          <span>
            {utcToLocalFormat(
              time.workingEndAt.toLocaleString(),
              MomentFormat.HHmm
            )}
          </span>
        </div>
      )}
    </div>
  );
};
