import React, { FC } from 'react';
import {
  useDeleteCareerAdminMutation,
  useDeleteCareerMutation,
} from '../../hooks/career';
import { meState } from '../../plugins/ridge';
import { Button } from '../Button';
import { AnimationLayout } from './AnimationLayout';

interface DeleteCareerModalProps {
  onClose: () => void;
  open: boolean;
  careerId: string;
  resumeId: string;
}

export const DeleteCareerModal: FC<DeleteCareerModalProps> = ({
  open,
  onClose,
  careerId,
  resumeId,
}) => {
  const me = meState.useValue();
  if (!me) {
    return <></>;
  }
  const { mutate: deleteCareerMutate } = useDeleteCareerMutation(
    resumeId,
    onClose
  );
  const { mutate: deleteCareerAdminMutate } = useDeleteCareerAdminMutation(
    resumeId,
    onClose
  );
  const _onClick = () => {
    if (me.role === 'ADMIN') {
      deleteCareerAdminMutate(+careerId);
    } else {
      deleteCareerMutate(+careerId);
    }
  };

  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 w-full max-w-md transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <div className="flex justify-between">
            <h4 className="text-gray-800">삭제</h4>
          </div>
          <div className="mt-2">
            <p className="subhead-4 whitespace-pre-line	">
              경력사항을 삭제하시겠습니까?
            </p>
          </div>
          <div className="text-left">
            <Button
              text="삭제하기"
              onClick={_onClick}
              className="mt-4 w-full bg-brand-1 text-white"
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
