import { AxiosError } from 'axios';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { changePassword, findPassword } from '../../api/auth';
import { ChangePasswordDto, FindPasswordDto } from '../../api/auth/type';

export const useFindPasswordMutation = (onSuccess?: () => void) => {
  return useMutation(
    (findPasswordDto: FindPasswordDto) => findPassword(findPasswordDto),
    {
      onSuccess: (_) => {
        toast.success('이메일이 발송되었습니다.');
        onSuccess && onSuccess();
      },
      onError: (err: AxiosError) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('이메일 발송에 실패했습니다.');
        }
      },
    }
  );
};

export const useChangePasswordMutation = ({
  onSuccess,
}: {
  onSuccess: () => void;
}) => {
  return useMutation(
    (changePasswordDto: ChangePasswordDto) => changePassword(changePasswordDto),
    {
      onSuccess: (_) => {
        onSuccess && onSuccess();
        toast.success('비밀번호가 변경되었습니다.');
      },
      onError: (err: AxiosError) => {
        if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('비밀번호 변경에 실패했습니다.');
        }
      },
    }
  );
};
