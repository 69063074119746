import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { map, omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { KOREAN_WORKER } from '../../../api/payment/type';
import { Button } from '../../../components/Button';
import { Select } from '../../../components/Select';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { useModal } from '../../../components/modal/Modal';
import {
  useRemoveUserByAdminMutation,
  useUpdateUserByAdminMutation,
  useUserByAdmin,
} from '../../../hooks/user';

interface FormValues {
  email: string;
  password: string;
  name: string;
  phone: string;
  worker: string;
}

export const UserInfo = () => {
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('이메일을 입력해주세요.')
      .matches(
        /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        '올바른 이메일 형식이 아닙니다.'
      ),
    password: Yup.string().test(
      'Invalid Password',
      '비밀번호를 6 ~ 15자로 작성해주세요.',
      (password) => isValidPassword(password)
    ),
    name: Yup.string().required('이름를 입력해주세요'),
    phone: Yup.string()
      .required('휴대전화번호를 입력해주세요')
      .min(10, '올바른 휴대전화번호가 아닙니다.')
      .max(11, '올바른 휴대전화번호가 아닙니다.'),
    worker: Yup.string().required('근무타입을 선택해주세요'),
  });
  const { id: userId } = useParams<{ id: string }>();
  const { data: user } = useUserByAdmin(+(userId as string) || 0);
  const [adminMemo, setAdminMemo] = useState<string>('');
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
  });
  const { removeUserModal } = useModal();
  const { mutate: updateUserByAdminMutate } = useUpdateUserByAdminMutation();
  const { mutate: removeUserByAdminMutate } = useRemoveUserByAdminMutation();
  const isValidPassword = (password: string | undefined) => {
    if (!password) {
      return true;
    }
    if (password.length < 6 || password.length > 15) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    reset(user);
    setAdminMemo(user.adminMemo);
  }, [reset, user]);

  if (!user) {
    return <></>;
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-4 sm:px-6 md:grid-cols-2 md:px-8">
        <TextField
          label="아이디"
          type="email"
          helper={errors.email?.message}
          {...register('email')}
          compulsory
          disabled
        />
        <TextField
          label="비밀번호"
          type="password"
          helper={errors.password?.message}
          {...register('password')}
        />
        <TextField
          label="이름"
          helper={errors.name?.message}
          {...register('name')}
          compulsory
        />
        <TextField
          label="전화번호"
          compulsory
          type="number"
          helper={errors.phone?.message}
          {...register('phone')}
        />

        <div className="flex flex-col space-y-2">
          <Select label="근무타입" compulsory {...register('worker')}>
            <option disabled selected hidden value="">
              근무타입을 선택해주세요.
            </option>
            {map(Object.entries(KOREAN_WORKER), ([w, name]) => (
              <option key={w} value={w}>
                {name}
              </option>
            ))}
          </Select>
        </div>
      </div>
      <div className="flex items-center px-8">
        <span className="text-sm text-gray-600">
          가입일시 : {format(new Date(user.createdAt), 'yyyy-MM-dd HH:mm')}
        </span>
      </div>
      <div className="px-8 pt-14">
        <TextArea
          label="관리자 메모"
          placeholder="메모할 내용을 입력해주세요."
          labelClassname="text-left"
          onChange={(e) => setAdminMemo(e.target.value)}
          value={adminMemo}
          name="adminMemo"
        />
      </div>
      {user.deletedAt && (
        <p className="px-8 text-right text-sm text-gray-400">
          탈퇴일자: {format(new Date(user.deletedAt), 'yyyy-MM-dd')}
        </p>
      )}

      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          text="탈퇴"
          className="filled-red-500 h-10 text-sm hover:bg-red-600"
          onClick={() => {
            removeUserModal(() => {
              removeUserByAdminMutate(+(userId || 0));
            });
          }}
          disabled={user.isDeleted}
        />
        <Button
          text="Save"
          className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
          onClick={handleSubmit((data) => {
            const newData = omit(data, [
              'createdAt',
              'updatedAt',
              'role',
              'adminMemo',
            ]);

            updateUserByAdminMutate({
              ...newData,
              id: Number(userId),
              adminMemo,
            });
          })}
        />
      </div>
    </>
  );
};
