import { stringify } from 'qs';
import { adminApi } from '../../plugins/axios';
import { PATHS } from './paths';
import {
  CreatePartTimeJobDateBulkDto,
  CreatePartTimeJobDateDto,
  DeletePartTimeJobDateDto,
  UpdatePartTimeJobDateDto,
} from './type';
export function createPartTimeJobDate(
  createPartTimeJobDateDto: CreatePartTimeJobDateDto
) {
  return adminApi.post(PATHS.ROOT, createPartTimeJobDateDto);
}

export function createPartTimeJobDateBulk(
  createPartTimeJobDateBulkDto: CreatePartTimeJobDateBulkDto
) {
  const queryString = stringify(
    {
      workDate: createPartTimeJobDateBulkDto.existedWorkDate,
    },
    { addQueryPrefix: true, encode: false }
  );
  delete createPartTimeJobDateBulkDto.existedWorkDate;
  return adminApi.post(
    `${PATHS.bulk(createPartTimeJobDateBulkDto.partTimeJobId)}${queryString}`,
    createPartTimeJobDateBulkDto.createPartTimeJobDateDtos
  );
}

export function updatePartTimeJobDate(
  updatePartTimeJobDateDto: UpdatePartTimeJobDateDto
) {
  const { id } = updatePartTimeJobDateDto;
  return adminApi.patch(PATHS.getOneById(id), updatePartTimeJobDateDto);
}

export function deletePartTimeJobDate(
  deletePartTimeJobDateDto: DeletePartTimeJobDateDto
) {
  const { workDate, partTimeJobId } = deletePartTimeJobDateDto;
  const queryString = stringify(
    { workDate },
    { addQueryPrefix: true, encode: false }
  );
  return adminApi.delete(`${PATHS.partTimeJobId(partTimeJobId)}${queryString}`);
}
