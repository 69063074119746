import React from 'react';
import { Button } from '../../../../components/Button';

export const ResetPasswordSuccessPage = () => {
  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <div className="flex w-full max-w-md flex-col space-y-4">
          <h1 className="mb-5 text-24 font-semibold text-gray-900">
            비밀번호 변경이 완료되었습니다!
          </h1>
          <div className="mt-10 pb-10">새로운 비밀번호로 로그인해주세요.</div>
          <Button
            text="로그인하러 가기"
            className="filled-brand-1 "
            to="/login"
          />
        </div>
      </div>
    </>
  );
};
