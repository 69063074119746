import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { PATHS as PTJPATH } from '../../api/part-time-job/paths';
import { createLike, deleteLike } from '../../api/user-part-time-job';
import { PATHS } from '../../api/user-part-time-job/paths';
import { CreateLikePartTimeJobDto } from '../../api/user-part-time-job/type';

export const useCreateLike = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation(
    (createLikePartTimeJobDto: CreateLikePartTimeJobDto) =>
      createLike(createLikePartTimeJobDto),
    {
      onSuccess: (_, createLikePartTimeJobDto) => {
        onSuccess && onSuccess();
        toast.success('찜하기 성공');
        queryClient.invalidateQueries(
          PATHS.partTimeJobId(createLikePartTimeJobDto.partTimeJobId)
        );
        queryClient.invalidateQueries(PTJPATH.ROOT);
        queryClient.invalidateQueries(
          PTJPATH.getOneById(createLikePartTimeJobDto.partTimeJobId)
        );
      },
      onError: () => {
        toast.error('공고 찜하기에 실패했습니다.');
      },
    }
  );
};

export const useDeleteLike = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation((partTimeJobId: number) => deleteLike(partTimeJobId), {
    onSuccess: (_, partTimeJobId) => {
      onSuccess && onSuccess();
      toast.success('찜하기 취소');
      queryClient.invalidateQueries(PATHS.partTimeJobId(partTimeJobId));
      queryClient.invalidateQueries(PTJPATH.ROOT);
      queryClient.invalidateQueries(PTJPATH.LIKED);
      queryClient.invalidateQueries(PTJPATH.getOneById(partTimeJobId));
    },
    onError: () => {
      toast.error('찜하기 취소에 실패했습니다.');
    },
  });
};
