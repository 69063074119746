import React, { FC, useEffect, useState } from 'react';
import { some } from 'lodash';
import { Certificate } from '../../api/certificate/type';
import { ModalType } from '../../constance';
import {
  useCreateCertificateAdminMutation,
  useCreateCertificateMutation,
  useUpdateCertificateAdminMutation,
  useUpdateCertificateMutation,
} from '../../hooks/certificate';
import { meState } from '../../plugins/ridge';
import { Button } from '../Button';
import { TextArea } from '../TextArea';
import { AnimationLayout } from './AnimationLayout';

interface CertificateModalProps {
  onClose: () => void;
  open: boolean;
  modalType: ModalType;
  certificateId: string;
  resumeId: string;
  certificate?: Certificate;
}

interface CertificateValue {
  description: string;
}

export const CertificateModal: FC<CertificateModalProps> = ({
  onClose,
  open,
  modalType,
  certificateId,
  resumeId,
  certificate,
}) => {
  const me = meState.useValue();
  if (!me) {
    return <></>;
  }
  const [certificateState, setCertificateState] = useState<CertificateValue>({
    description: '',
  });
  const [validate, setValidate] = useState<boolean>(false);
  const { description } = certificateState;

  const _init = () => {
    setCertificateState({
      description: '',
    });
    onClose();
  };
  const { mutate: createCertificateMutate } =
    useCreateCertificateMutation(_init);
  const { mutate: createCertificateAdminMuate } =
    useCreateCertificateAdminMutation(_init);

  const { mutate: updateCertificateMutate } =
    useUpdateCertificateMutation(_init);
  const { mutate: updateCertificateAdminMutate } =
    useUpdateCertificateAdminMutation(_init);

  const _onChange = (e: any) => {
    setCertificateState({
      ...certificateState,
      [e.target.name]: e.target.value,
    });
  };
  const _onClick = () => {
    if (modalType === ModalType.ADD) {
      const data = { ...certificateState, resumeId };
      if (me.role === 'ADMIN') {
        createCertificateAdminMuate(data);
      } else {
        createCertificateMutate(data);
      }
    }
    if (modalType === ModalType.EDIT) {
      const data = {
        id: +certificateId,
        ...certificateState,
        resumeId,
      };
      if (me.role === 'ADMIN') {
        updateCertificateAdminMutate(data);
      } else {
        updateCertificateMutate(data);
      }
    }
  };

  const isValidate = () => {
    setValidate(!some(certificateState, (e) => e === ''));
  };
  useEffect(() => {
    isValidate();
  }, [certificateState]);

  useEffect(() => {
    if (modalType === ModalType.EDIT && certificate) {
      setCertificateState(certificate);
    }
  }, [certificate]);

  useEffect(() => {
    if (!open) {
      _init();
    }
  }, [open]);

  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 w-full max-w-3xl transform space-y-3 overflow-hidden rounded-lg bg-white p-6 shadow-xl transition-all">
          <div className="flex justify-between">
            <h4 className="text-gray-800">
              특기사항 {modalType === ModalType.ADD ? '추가' : '수정'}
            </h4>
          </div>
          <TextArea
            label="자격증 및 특기사항"
            compulsory
            placeholder="상세내용을 입력해주세요."
            labelClassname="text-left"
            value={description}
            name="description"
            onChange={(e) => _onChange(e)}
          />
          <div className="text-left">
            <Button
              text="저장하기"
              disabled={!validate}
              onClick={_onClick}
              className={`mt-4 w-full text-white  md:w-60 ${
                !validate ? 'bg-gray-400' : 'bg-brand-1'
              }`}
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
