import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
//@ts-ignore
import BootPay from 'bootpay-js';
import { omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { mobileAuth } from '../../../api/auth';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { MobileTopBar } from '../../../components/MobileTopBar';
import { TextField } from '../../../components/TextField';
import { useMe, useUpdateMeMutation } from '../../../hooks/user';

interface FormValues {
  email: string;
  password: string;
  passwordConfirm: string;
  name: string;
  adminMemo: string;
}

export const EditPage = () => {
  const { data: me } = useMe();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isEqualPhoneNumber, setIsEqualPhoneNumber] = useState<boolean>(true);
  const [phone, setPhone] = useState<string>('');
  const { mutate: updateMeMutate } = useUpdateMeMutation(() =>
    navigate('/mypage')
  );
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('이메일을 입력해주세요.')
      .matches(
        /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        '올바른 이메일 형식이 아닙니다.'
      ),
    password: Yup.string()
      .required('패스워드를 입력해주세요.')
      .test(
        'Invalid Password',
        '비밀번호를 6 ~ 15자로 작성해주세요.',
        (password) => isValidPassword(password)
      ),
    passwordConfirm: Yup.string()
      .required('패스워드를 입력해주세요.')
      .test(
        'The password does not match',
        '비밀번호가 다릅니다.',
        (passwordConfirm) => isEqualPassword(passwordConfirm)
      ),
    name: Yup.string().required('이름를 입력해주세요'),
  });
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
  });

  const isValidPassword = (password: string | undefined) => {
    if (!password) {
      return true;
    }
    if (password.length < 6 || password.length > 15) {
      return false;
    }
    return true;
  };

  const isEqualPassword = (passwordConfirm: string | undefined): boolean => {
    if (!passwordConfirm) {
      if (watch('password')) {
        return false;
      } else {
        return true;
      }
    }
    return passwordConfirm === watch('password');
  };
  const checkEqualPhoneNumber = (newPhone: string) => {
    if (!me) {
      return;
    }
    if (newPhone !== me.phone) {
      setIsEqualPhoneNumber(false);
    } else {
      setIsEqualPhoneNumber(true);
    }
  };

  useEffect(() => {
    checkEqualPhoneNumber(phone);
  }, [checkEqualPhoneNumber, me, phone]);

  useEffect(() => {
    if (!me) {
      return;
    }

    reset(me);
    setPhone(me.phone);
  }, [reset, me]);

  if (!me) {
    return <></>;
  }

  return (
    <>
      <MobileTopBar title="내 정보 수정" />
      <div className="mb-36 mt-32 md:grid md:place-items-center">
        <div className="w-full max-w-4xl px-5 md:px-0">
          <div className="hidden space-y-5 md:block">
            <h3 className="text-28 ">내 정보 수정</h3>
          </div>
          <div className="mt-5 grid grid-cols-1 gap-x-7 gap-y-10 md:grid-cols-2">
            <TextField
              label="이름"
              compulsory
              placeholder="이름을 입력해주세요."
              helper={errors.name?.message}
              {...register('name')}
            />
            <TextField
              label="아이디(이메일)"
              type="email"
              compulsory
              disabled
              placeholder="이메일을 입력해주세요."
              helper={errors.email?.message}
              {...register('email')}
            />
            <TextField
              label="비밀번호"
              type="password"
              compulsory
              placeholder="6자 이상의 비밀번호를 입력해주세요."
              helper={errors.password?.message}
              {...register('password')}
            />
            <TextField
              label="비밀번호 확인"
              type="password"
              compulsory
              placeholder="6자 이상의 비밀번호를 입력해주세요."
              helper={errors.passwordConfirm?.message}
              {...register('passwordConfirm')}
            />

            <div className="flex flex-col space-y-2 pb-14">
              <Label compulsory>휴대폰 인증</Label>
              <div className="flex items-center space-x-2">
                <TextField
                  className="w-full"
                  disabled
                  value={phone && phone.slice(0, 3)}
                />
                <TextField
                  className="w-full"
                  disabled
                  value={
                    phone && phone.length === 11
                      ? phone.slice(3, 7)
                      : phone.slice(3, 6)
                  }
                />
                <TextField
                  className="w-full"
                  disabled
                  value={
                    phone && phone.length === 11
                      ? phone.slice(7)
                      : phone.slice(6)
                  }
                />
                <Button
                  disabled={isVerified}
                  type="button"
                  text={`${isVerified ? '인증완료' : '수정'}`}
                  className="outlined-brand-1 h-12 flex-none !px-4 text-brand-1 md:!px-6"
                  onClick={() => {
                    BootPay.request({
                      price: 0, // 0으로 해야 한다.
                      application_id: '629881e9e38c3000235add8f',
                      name: '본인인증', //결제창에서 보여질 이름
                      pg: 'danal',
                      method: 'auth', // 빌링키를 받기 위한 결제 수단
                      show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
                      order_id: uuidv4(), //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
                      extra: {
                        age_limit: '15',
                      },
                    }).done((data: any) => {
                      mobileAuth({ receiptId: data?.receipt_id });
                      setIsVerified(true);
                      setPhone(data.phone);
                    });
                  }}
                />
              </div>
            </div>

            <div className="col-start-1">
              <Button
                text="저장하기"
                disabled={!isEqualPhoneNumber && !isVerified}
                className="filled-brand-1 w-full"
                onClick={handleSubmit((data) => {
                  const newData = omit(data, ['role', 'passwordConfirm']);
                  updateMeMutate({ ...newData, id: Number(me.id), phone });
                })}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
