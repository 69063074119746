import React, { FC } from 'react';
import PartnersModalImg1 from '../../assets/img/partner-modal-img1.jpg';
import PartnersModalImg2 from '../../assets/img/partner-modal-img2.jpg';
import PartnersModalImg3 from '../../assets/img/partner-modal-img3.jpg';
import PartnersModalImg4 from '../../assets/img/partner-modal-img4.jpg';
import PartnersModalImg5 from '../../assets/img/partner-modal-img5.jpg';
import PartnersModalImg6 from '../../assets/img/partner-modal-img6.jpg';
import PartnersModalImg7 from '../../assets/img/partner-modal-img7.jpg';
import PartnersModalImg8 from '../../assets/img/partner-modal-img8.jpg';
import PartnersModalImg9 from '../../assets/img/partner-modal-img9.jpg';
import { Icon } from '../Icon';
import { AnimationLayout } from './AnimationLayout';

export const MODAL_DATA = [
  {
    id: 1,
    img: PartnersModalImg1,
    title: 'NOBLE VALENTI',
    location: '삼성, 대치점',
    content: `꿈꿔왔던 그 이상의 웨딩 노블발렌티.\n영국의 대저택 컨셉의 노블발렌티는 빅토리아 양식의 웅장하고 클래식한 느낌의 세리머니홀과 모던 클래식 컨셉의 개별 공간, 프라이빗하고 싱그러운 가든으로 구성되어 있습니다.\n주인공은 물론이고 게스트 역시 소중한 날의 가치를 공유할 수 있는 소통의 공간, 바로 노블발렌티가 추구하는 채플웨딩입니다.\n세상에 단 하나 뿐인 나만의 웨딩, 세레머니 홀 내에는 노블발렌티의 심볼인 대형 "종"이 달려있어 축복의 의미를 예식 시작을 성스럽게 울려줍니다.\n식사 위주의 결혼식 문화에서 탈피하여 다양한 컨텐츠의 유럽식 정통 채플웨딩 스타일을 한국의 정서에 맞추어 기획합니다.\n신문화에 대한 고객의 부담감을 배려한 다양하고 합리적인 프로그램을 제시하여 세련된 감각의 채플웨딩 스타일을 완성합니다.`,
    address: `(삼성점 : 서울시 강남구 영동대로 325 S TOWER B1층 - 삼성역 3번출구)\n(대치점 : 서울시 강남구 봉은사로 637 –봉은사역 4번출구)`,
  },
  {
    id: 2,
    img: PartnersModalImg2,
    title: 'G TOWER CONVENTION',
    location: '구로 디지털 단지',
    content: `대한민국 디지털 심장인 G밸리타운의 랜드마크, 지타워에 최고의 웨딩 명소를 선보입니다.\nG-TOWER CONVENTION 은 웨딩의 Game Changer 를 표방하고 NetMarble과의 파트너쉽을 통해서 메이저호텔보다 더 고품격 웨딩을 구현하면서도 합리적인 가격으로 한차원 더 높은 웨딩문화를 선도해 나갈 것입니다.\n30m의 버진 로드, 20m x 5.5m 규모의 최첨단 고화질 시어터 스크린과 생생한 현장 영상중계 그리고 하이 테크 음향 시스템,\n7m 상공에서 영롱하게 빛나는 아름다운 크리스탈 샹들리에, 신부대기실, 로비, 포토 테이블, 신랑 및 혼주 전용 포토월 그리고 컨벤션홀 내부에 가득한 플라워는 상상 그 이상의 경험이 될 것입니다.`,
    address: `(주소 : 서울 구로구 디지털로 26길 38 - 구로디지털단지역 2번출구)`,
  },
  {
    id: 3,
    img: PartnersModalImg3,
    title: 'THE LAVILLE',
    location: '삼성',
    content: `The Laville은 이러한 평화로움을 품은 마을을 뜻하는 프랑스어 "ville"에서 유래하였습니다.\n도심 속 리조트형 웨딩을 실현하는 더라빌은 삼성동에서 유일하게 숲으로 둘러싸인 아름다운 장소입니다.\n 도심 한복판에 아름다운 숲의 절경과 새소리가 어우러진 더라빌은 여유로움이 있는 웨딩을 추구합니다.\n라빌언덕에 올라서면 한눈에 들어오는 도심의 모습과 자연경관은 고객님의 마음을 더욱 편안하게 해드릴 것입니다.\n복잡한 웨딩에서 벗어나 리조트의 컨셉에 맞는 여유로움과 품격있는 연회서비스는 고객님의 평생에 한번뿐인 성스러운 결혼식을 더욱 빛나게 할 것입니다.\n서울 강남의 도심속 10,000평 규모의 더라빌만의 프라이빗한 독립된 자연공간으로 낮에는 따사로운 햇살과 바람을 맞을 수 있고, 저녁에는 아름다운 야경을 한 눈에 볼 수 있습니다.`,
    address: `(주소 : 서울 강남구 봉은사로 531 더라빌 아트센터 - 봉은사역 1번 출구)`,
  },
  {
    id: 4,
    img: PartnersModalImg4,
    title: 'LUVEL',
    location: '강동',
    content: `사랑이라는 어원에서 출발한 루벨은 "프렌치 가든에서 펼쳐지는 두 사람의 왈츠"라는 슬로건을 기반으로 탄생했습니다.\n"신랑신부의 퍼스트 댄스"이자 "여럿이서 함께 추는 무도곡"을 의미하는 낭만적인 왈츠의 선율은 신랑신부 두사람과 하객들 모두 주인공이 되어 즐길 수 있는 웨딩데이를 만듭니다.\n왈츠 선율을 닮은 리본의 아름다운 곡선은 루벨의 디테일이 되며, 리드미컬한 연회의 분위기를 고조시킵니다.\n창유리를 통해 들어오는 햇살, 싱그러운 녹색 식물과 꽃, 바람에 흩날리는 샤커튼은 루벨만의 특별하고 품격 있는 야외 웨딩 분위기를 자아냅니다.\n도심 속 프렌치 포멀 가든 웨딩을 선도하는 루벨이 신랑신부의 사랑의 맹세가 이루어지는 로맨틱한 순간을 축복합니다.\n지하철 5호선 강동역 1번 출구와 바로 연결된 이스트센트럴 타워의 최상층에 위치하며 최적의 교통을 자랑하는 입지는 물론 최대 350대까지 수용 가능한 여유로운 주차공간은 하객들에게 편안함을 선사합니다.`,
    address: `(주소 : 서울시 강동구 천호대로 1077 이스트센트럴 타워 35~36층 - 강동역 1번 출구)`,
  },
  {
    id: 5,
    img: PartnersModalImg5,
    title: 'THE CHAPEL',
    location: '청담',
    content: `매 순간 경이로움을 선사하는 명작(名作)의 힘.\n세대를 관통하고 시대를 초월하는 클래식이란 본질에 대한 깊은 고민을 기반으로 아름다움을 펼쳐낼 때 완성됩니다.\n신의 축복과 함께 영원한 사랑을 염원하는 채플 웨딩.\n고풍스러운 분위기와 절제된 아름다움 가운데 진정한 사랑의 깊이를 되새길 수 있어 종교를 뛰어넘어 경건하고 뜻 깊은 예식을 원하는 현명한 신랑신부의 선택이 되어 왔습니다.\n모두가 인정하는 격조 높은 클래스는 웨딩, 본연의 가치에 집중하는 것에서 출발해 상상 그 이상의 환희를 선사하기에 더욱 특별합니다.\n여유롭고 이국적인 휴양지의 설렘과 자연에서 영감을 얻은 싱그러운 분위기, 우아하고 로맨틱한 예식을 도심 속에서도 프라이빗하게 만끽할 수 있는 진정한 한국형 채플 웨딩.\n국내 최고 높이의 천장고 아래 펼쳐지는 드라마틱한 버진로드와 압도적인 웅장함, 곳곳에 더해진 사랑스러운 축복의 메시지까지 모두의 축복 속에 완성되는 위대한 러브스토리의 절정, 더채플 웨딩의 주인공이 되어주세요.`,
    address: `(주소 : 서울 강남구 선릉로 757 – 강남구청역 3-1번 출구)`,
  },
  {
    id: 6,
    img: PartnersModalImg6,
    title: '락구정',
    location: '서울대입구',
    content: `2013년 오픈한 락구정은 최고의 대학 서울대학교 내에서 저염식, 건강식을 기본으로 정갈하고 담백한 상차림을 담았습니다.\n소중한 분들과의 모임, 성공적인 비즈니스, 동료와 가족이 함께 즐길 수 있는 다양한 메뉴를 락구정에서 준비했습니다.\n최고의 대학에서 전통의 한식을 현대적인 감감으로 재해석한 특별한 메뉴로 한식당을 오픈한 이후, 학내의 각종 행사 및 국제 학술대회, 컨퍼런스, 홈커밍데이 등 다양한 행사로 고객의 만족을 최우선으로 진행해왔습니다.\n건강을 지키고, 환경을 생각하는 저희 락구정 직원 일동은 최고의 만족과 최상의 서비스를 제공하기 위하여 최선의 노력을 다할 것을 약속합니다.`,
    address: `(주소 : 서울 관악구 관악로 1 – 서울대입구역 3번 출구)`,
  },
  {
    id: 7,
    img: PartnersModalImg7,
    title: '삼청각',
    location: '한성대입구',
    content: `한국의 멋과 맛 그리고 전통, 문화, 예술을 경험하다.\n삼청(三淸)은 산청(山淸), 수청(水淸), 인청(人淸) 산이 맑고, 물이 맑고, 인심이 좋다는 의미를 담고 있습니다.\n북악산의 아름다운 경관속에 5개의 한옥과 2개의 정자, 3개의 마당이 어우러진 삼청각은 도심 속에서 찾기 힘든 특색있는 서울의 대표 전통문화예술 복합공간입니다.\n2022년 6월 27일 새롭게 개관한 삼청각은 전통문화예술을 배우고, 즐기고, 체험할 수 있는 시민들의 공간으로 재탄생하였습니다.\n전통의 창조적 계승으로 품격있는 한국의 멋과 맛을 경험할 수 있는 삼청각에서는 자연을 벗하며 고즈넉한 한옥에서 여유로운 풍류를 즐기실 수 있습니다.`,
    address: `(주소 : 서울특별시 성북구 대사관로 3 – 한성대입구역 6번 출구)`,
  },
  {
    id: 8,
    img: PartnersModalImg8,
    title: 'CRYSTAL JADE',
    location: '도곡',
    content: `세계적으로 인정받는 글로벌 차이니즈 레스토랑 브랜드, 크리스탈 제이드는 1991년부터 이어지는 요리 철학을 기반으로 최상의 품질과 격조 높은 서비스를 합리적인 가격으로 제공하고 있습니다.\n120개 이상의 레스토랑에서 현지의 맛을 전달하며 전 세계 미식가들에게 사랑받고 있습니다.\n다채로운 중국 요리 8가지 컨셉 매장에서 현지에서 초청된 분야별 전문 셰프들의 손에서 펼쳐지는 중국 미식 여행을 즐길 수 있습니다.\n크리스탈 제이드는 중국 정통의 맛을 재현하기 위해 70여명의 현지 요리사들과 협력하며 지역적 특색을 살린 200여 가지의 중국 정통 요리를 제공합니다.\n신선한 식재료 선택을 위해 연 4회의 품질 테스트를 진행하며, 시즌 메뉴와 중국 현지 식자재를 활용한 건강한 중식 요리를 선보입니다.\n크리스탈 제이드는 세련된 홀과 VIP 고객 전담 직원을 통해 1:1 스페셜 서비스를 제공하며 정확한 메뉴 안내와 고객의 입맛에 맞춘 코스 구성, 품격 있는 서비스를 경험할 수 있습니다.`,
    address: `(주소 : 서울 강남구 언주로30길 39 린스퀘어 지하1층 – 도곡역 4번 출구)`,
  },
  {
    id: 9,
    img: PartnersModalImg9,
    title: 'TAKE HOTEL',
    location: '광명',
    content: `h_Garden은 현대백화점 그룹을 상징하는 h와 야외 정원과도 같이 편안하고 생기 넘치는 웰빙 다이닝 공간을 의미하는 Garden의 만남으로 탄생하였습니다.\n세계 각국의 다양한 뷔페 메뉴를 비롯, 계절별로 선보이는 계절 메뉴와 특별한 날을 위한 스페셜 메뉴, 일품 요리 및 세트 메뉴에 이르기까지 최고의 셰프들이 디자인한 창의적인 요리들을 제공합니다.\n현대백화점 계열사 현대그린푸드가 운영하며, 특1급 호텔 및 컨벤션 경력을 가진 셰프의 숙련된 기술과 현대 그린푸드의 최첨단 물류시스템을 통한 최상의 재료등을 통해 고객들에게 미식의 즐거움을 선사합니다.\n기업연회, 웨딩, 각종 연회 행사 경험을 가지고 있는 현대그린푸드 케이터링팀은 엄선된 재료로 최상의 음식을 제공하며, 대중적인 한식,중식,일식과 더불어 현대그린푸드 외식사업부만의 특별한 레시피로 다양한 맛, 새로운 메뉴로 다가갑니다.\n각종 모든 행사의 전 과정을 담당 지배인과 고객의 1:1 협의를 통해 원활한 행사 진행 할 수 있도록 맞춤형 서비스를 제공합니다.`,
    address: `(주소 : 경기 광명시 신기로 22 테이크호텔 4층 에이치가든 – 광명역 1번 출구)`,
  },
];

interface PartnersModalProps {
  onClose: () => void;
  open: boolean;
  imgId: number;
}

export const PartnersModal: FC<PartnersModalProps> = ({
  onClose,
  open,
  imgId,
}) => {
  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="relative my-8 w-full max-w-4xl transform overflow-hidden bg-white shadow-xl transition-all">
          <button
            type="button"
            className="absolute right-3 top-3 text-white md:text-black"
            onClick={onClose}
          >
            <Icon.X className="h-6 w-6" />
          </button>

          <div className="flex flex-col md:flex-row">
            <img
              src={MODAL_DATA[imgId].img}
              alt=""
              className="h-[200px] w-full object-cover md:h-[670px] md:w-fit"
            />

            <div className="mt-0 h-[350px] space-y-6 overflow-y-scroll p-5 text-left md:mt-10 md:h-full md:space-y-10 md:overflow-y-auto md:p-8 lg:space-y-20">
              <div className="space-y-1">
                <p className="text-[20px] font-bold md:text-[34px]">
                  {MODAL_DATA[imgId].title}
                </p>
                <p className="text-[13px] text-slate-400 md:text-[16px]">
                  {MODAL_DATA[imgId].location}
                </p>
              </div>

              <p className="whitespace-pre-wrap text-[13px] text-slate-700 md:text-[14px]">
                {MODAL_DATA[imgId].content}
                <br />
                <br />
                {MODAL_DATA[imgId].address}
              </p>
            </div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
