import { FC, HTMLAttributes } from 'react';

export interface ContractDescProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  desc?: string;
}

export const ContractDesc: FC<ContractDescProps> = ({ title, desc }) => {
  return (
    <div className="text-15">
      <p className="font-semibold text-gray-700">{title}</p>
      <p>{desc}</p>
    </div>
  );
};
