import React from 'react';
import { Button } from '../../../components/Button';
import { useMyResume } from '../../../hooks/resume';

export const SignUpSuccessPage = () => {
  const { data: myResume } = useMyResume();
  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <div className="flex w-full max-w-md flex-col space-y-4">
          <h1 className="mb-5 text-24 font-semibold text-gray-900">
            회원가입이 완료되었습니다!
          </h1>
          <div className="font-medium">
            지원서 작성이 완료되어야 <br className="block md:hidden" />
            아르바이트를 신청할 수 있습니다. <br />
            지원서를 작성하시겠습니까?
          </div>
          <Button
            text="지원서 작성하러 가기"
            className="filled-brand-1"
            to={myResume ? `/resume/${myResume.id}` : '/main'}
          />
          <Button
            text="홈으로 가기"
            className=" border border-brand-1 bg-white text-brand-1"
            to="/"
          />
        </div>
      </div>
    </>
  );
};
