import { FC } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Application } from '../api/application/type';
import { usePartTimeJobById } from '../hooks/part-time-job';
import { Icon } from './Icon';
import { useModal } from './modal/Modal';

interface HistoryListProps {
  application: Application;
}

export const HistoryList: FC<HistoryListProps> = ({ application }) => {
  const navigate = useNavigate();
  const { deletedPartTimeJob } = useModal();
  const { data: partTimeJob } = usePartTimeJobById(application.partTimeJobId);
  if (!application || !partTimeJob) {
    return <></>;
  }

  const {
    companyName,
    recruitDescription,
    startWorkTime,
    endWorkTime,
    workDate,
    appliedDate,
    partTimeJobId,
    partTimeJobDateId,
    jobStatus,
  } = application;

  return (
    <>
      <div className="py-10">
        <div className="mb-2 flex justify-between">
          <div className="text-15 font-medium text-gray-800">{companyName}</div>

          <h5
            className={`${
              jobStatus === 'APPROVED' ? `text-blue-500` : `text-red-500`
            } text-right font-medium`}
          >
            {jobStatus === 'APPROVED' ? `승인 완료` : `승인 대기`}
          </h5>
        </div>
        <div className="flex flex-col items-start md:flex-row md:justify-between">
          <button
            className="flex flex-col space-y-2"
            onClick={() => {
              if (!partTimeJobDateId) {
                deletedPartTimeJob();
              } else {
                navigate(`/part-time-job/${partTimeJobId}`);
              }
            }}
          >
            <div className="flex">
              <h6 className="max-w-[250px] truncate text-18 font-bold md:max-w-[400px]">
                {partTimeJob.title}
              </h6>
              <Icon.ChevronRight className="text-gray-400" />
            </div>
            <p className="text-left text-16 font-semibold text-gray-900">
              {recruitDescription}
            </p>
          </button>
          <p className="flex-none pt-1 pb-2 text-13 text-gray-600 md:pb-0">
            알바 신청일 : {format(new Date(appliedDate), 'yyyy-MM-dd')}
          </p>
        </div>
        <div className="mt-4 flex space-x-2">
          <div className="flex h-8 items-center space-x-1 rounded-md bg-sub-2 p-2 ">
            <Icon.Calendar className="wh-4 flex-none  text-brand-1" />
            <p className="truncate text-13 font-medium ">
              {format(new Date(workDate), 'yyyy-MM-dd')}
            </p>
          </div>
          <div className="flex h-8 items-center space-x-1 rounded-md bg-sub-2 p-2">
            <Icon.Clock className="wh-4 flex-none  text-brand-1" />
            <p className="truncate text-13 font-medium ">{`${format(
              new Date(startWorkTime),
              'HH:mm'
            )} ~ ${format(new Date(endWorkTime), 'HH:mm')}`}</p>
          </div>
        </div>
      </div>
    </>
  );
};
