import { Company } from '../company/type';
import {
  CreatePartTimeJobDateDto,
  PartTimeJobDate,
} from '../part-time-job-date/type';

export enum SELECT_GENDER {
  BOTH = 'BOTH',
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

interface UserPartTimeJob {
  userId: number;
  partTimeJobId: number;
}

export interface PartTimeJob {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string; // 공고 제목
  mainImgUrl: string; // 공고 메인 이미지
  recruitNumber: number; // 모집 인원
  workType: string; //근무 타입
  recruitDescription: string; // 모집 내용
  gender: SELECT_GENDER; // 성별
  hourPay: number; // 시급
  applicationMethod: string; // 지원 방법
  workForm: string; // 업무형태
  averageWorkingHours: string; // 평균 근무시간
  description: string; // 상세 정보
  userPartTimeJobs?: UserPartTimeJob[]; // 유저 좋아요
  partTimeJobDates: Record<string, PartTimeJobDate[]>;
  company: Company;
}

export interface CreatePartTimeJobDto {
  title: string; // 공고 제목
  mainImgUrl: string; // 공고 메인 이미지
  recruitNumber: number; // 모집 인원
  workType: string; //근무 타입
  recruitDescription: string; // 모집 내용
  gender: SELECT_GENDER; // 성별
  hourPay: number; // 시급
  applicationMethod: string; // 지원 방법
  workForm: string; // 업무형태
  averageWorkingHours: string; // 평균 근무시간
  description: string; // 상세 정보
  partTimeJobDates: CreatePartTimeJobDateDto[]; // 날짜
  companyId: number;
}

export interface UpdatePartTimeJobDto {
  id: number;
  title?: string; // 공고 제목
  mainImgUrl?: string; // 공고 메인 이미지
  recruitNumber?: number; // 모집 인원
  workType?: string; //근무 타입
  recruitDescription?: string; // 모집 내용
  gender?: SELECT_GENDER; // 성별
  hourPay?: number; // 시급
  applicationMethod?: string; // 지원 방법
  workForm?: string; // 업무형태
  averageWorkingHours?: string; // 평균 근무시간
  description?: string; // 상세 정보
}
