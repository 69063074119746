import { TIME_STAMP } from '../admin/pages/company/types';
import { CreateContractDto } from '../api/contract/type';
import { ContractTimeList } from '../pages/contract/ContractAddPage';
import { ContractTable } from './ContractTable';
import { Select } from './Select';
import { TextField } from './TextField';

const HOURS = new Array(24).fill(0).map((_, i) => i);
const MINUTES = new Array(60).fill(0).map((_, i) => i);

interface ContractDateTableProps {
  contractState: CreateContractDto;
  setContractState: (contractState: CreateContractDto) => void;
  timeList: ContractTimeList;
  setTimeList: (time: ContractTimeList) => void;
  disable: boolean;
}

export default function ContractDateTable({
  contractState,
  setContractState,
  timeList,
  setTimeList,
  disable,
}: ContractDateTableProps) {
  const { contractDate } = contractState;
  return (
    <>
      <div>
        <div className=" grid h-64 w-full grid-cols-6  divide-y divide-x rounded-md border">
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              계약일시
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4">
            <div className="flex items-center space-x-2 text-15 text-gray-700">
              <TextField
                compulsory
                placeholder="근무한 날짜를 선택해주세요."
                type="date"
                max="9999-12-31"
                value={contractDate}
                onChange={(e) => {
                  setContractState({
                    ...contractState,
                    contractDate: e.target.value,
                  });
                }}
              />

              <p>[1일]</p>
            </div>
          </div>
          <div className="grid place-items-center bg-sub-3 text-15 text-gray-700">
            <p className="after:ml-0.5 after:text-brand-1 after:content-['*']">
              근무시간
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <div className="flex items-center space-x-3">
              <div className="flex items-center space-x-2 text-15 text-gray-700">
                <Select
                  className="w-32"
                  disabled={disable}
                  onChange={(e) =>
                    setTimeList({ ...timeList, startHour: +e.target.value })
                  }
                >
                  <option disabled selected hidden>
                    시간
                  </option>

                  {HOURS.map((hour) => (
                    <option key={hour} value={hour}>
                      {TIME_STAMP[hour]}
                    </option>
                  ))}
                </Select>
                <Select
                  disabled={disable}
                  className="w-32"
                  onChange={(e) =>
                    setTimeList({
                      ...timeList,
                      startMin: +e.target.value,
                    })
                  }
                >
                  <option disabled selected hidden value="">
                    분
                  </option>

                  {MINUTES.map((min) => (
                    <option key={min} value={min}>
                      {TIME_STAMP[min]}
                    </option>
                  ))}
                </Select>

                <p>{' ~ '}</p>

                <div className="flex items-center space-x-2 text-15 text-gray-700">
                  <Select
                    disabled={disable}
                    className="w-32"
                    onChange={(e) =>
                      setTimeList({
                        ...timeList,
                        endHour: +e.target.value,
                      })
                    }
                  >
                    <option disabled selected hidden value="">
                      시간
                    </option>

                    {HOURS.map((hour) => (
                      <option key={hour} value={hour}>
                        {TIME_STAMP[hour]}
                      </option>
                    ))}
                  </Select>
                  <Select
                    disabled={disable}
                    className="w-32"
                    onChange={(e) =>
                      setTimeList({
                        ...timeList,
                        endMin: +e.target.value,
                      })
                    }
                  >
                    <option disabled selected hidden value="">
                      분
                    </option>

                    {MINUTES.map((min) => (
                      <option key={min} value={min}>
                        {TIME_STAMP[min]}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          </div>
          <ContractTable
            title="휴게시간"
            desc={`8시간 미만시 30분제공/ 8시간 이상시 1시간 제공\n※ 단, 휴게시간은 행사 성격에 따라 변경될 수 있으며, 휴게시간은 임금에 산정하지 않는다.`}
          />
        </div>
      </div>
    </>
  );
}
