import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { UpdateWageDto } from './type';

export function getAllWagesByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get(`/${PATHS.getByUserId(userId)}${queryString}`)
    .then(({ data }) => data);
}

export function bulkCreateOrUpdateWagesByUserId(
  wageDtos: UpdateWageDto[],
  userId: number
) {
  return adminApi
    .post(PATHS.getByUserId(userId), wageDtos)
    .then(({ data }) => data);
}

export function getMyWages() {
  return api.get(PATHS.ME).then(({ data }) => data);
}
