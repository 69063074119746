import React, { FC } from 'react';
import { Button } from '../Button';
import { AnimationLayout } from './AnimationLayout';

interface ContractSubmitModalProps {
  onClose: () => void;
  onClick: () => void;
  open: boolean;
}

export const ContractSubmitModal: FC<ContractSubmitModalProps> = ({
  onClose,
  open,
  onClick,
}) => {
  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 w-full max-w-md transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <h3 className="font-semibold">계약서 제출(급여신청)</h3>
          <p className="whitespace-pre-wrap font-medium text-gray-700">
            제출한 계약서는 수정이 불가합니다. <br />
            제출하시겠습니까?
          </p>

          <div className="space-y-3 pt-8">
            <Button
              onClick={onClick}
              text="계약서 제출하기"
              className=" w-full bg-brand-1 text-white"
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
