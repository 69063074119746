import React from 'react';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  BankCode,
  GENDER,
  KOREAN_BANK_NAME,
  KOREAN_GENDER,
  KOREAN_SCHOOL_STATUS,
  SCHOOL_STATUS,
} from '../../../api/resume/type';
import { AppliedCareerList } from '../../../components/AppliedCareerList';
import { AppliedCertificateList } from '../../../components/AppliedCertificateList';
import { Button } from '../../../components/Button';
import { FileUpload } from '../../../components/FileUpload';
import { IdPhoto } from '../../../components/IdPhoto';
import { Label } from '../../../components/Label';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import { useAppliedResume } from '../../../hooks/applied-resume';

export const AppliedResume = () => {
  const { id: appliedResumeId } = useParams<{ id: string }>();
  const { data: appliedResume } = useAppliedResume(
    appliedResumeId ? appliedResumeId : ''
  );

  if (!appliedResume) {
    return <></>;
  }

  const {
    userImage,
    userName,
    email,
    birth,
    gender,
    personalId,
    address,
    addressDetail,
    depositor,
    bankCode,
    bankAccount,
    accountImgUrl,
    schoolName,
    schoolStatus,
    major,
    healthImgUrl,
    agreementFileUrl,
    resumeFileUrl,
    coverLetterFileUrl,
  } = appliedResume;
  return (
    <>
      <div className="mb-36 mt-11 md:grid md:place-items-center">
        <div className="flex w-full max-w-4xl flex-col px-5 md:px-0">
          <div className="hidden space-y-5 md:block">
            <h3 className="text-28 ">알바 신청자 지원서</h3>
          </div>

          <div className="pb-20">
            <h3 className="mb-9">지원서 사진(필수)</h3>
            <Label compulsory>사진</Label>
            <IdPhoto
              accept={['.png', '.jpg', '.jpeg']}
              src={userImage}
              value={userImage}
              disabled
            />
          </div>
          <div className="py-20">
            <h3 className="mb-9">계좌 정보 (필수)</h3>
            <div className="grid grid-cols-2 gap-8">
              <TextField label="예금주" compulsory value={depositor} disabled />
              <div className="col-start-1">
                <Select label="은행" compulsory value={bankCode} disabled>
                  <option disabled selected hidden value="">
                    은행을 선택해주세요.
                  </option>

                  {map(BankCode, (bank, index) => (
                    <option key={index} value={bank}>
                      {KOREAN_BANK_NAME[bank]}
                    </option>
                  ))}
                </Select>
              </div>

              <TextField
                label="계좌번호"
                type="number"
                compulsory
                disabled
                value={bankAccount}
              />
              <FileUpload
                label="통장사본"
                compulsory
                desc="통장 사본은 모바일 캡쳐본으로 대체 가능합니다."
                accept={['.pdf', '.png', '.jpeg', '.jpg']}
                src={accountImgUrl}
                isValueFile={false}
                fileName={accountImgUrl.split(/_(.*)/s).slice(1)[0]}
                disabled
              />
            </div>
          </div>
          <div className="py-20">
            <h3 className="mb-9">기본 정보 (필수)</h3>
            <div className="grid grid-cols-2 gap-8">
              <TextField
                label="이름"
                compulsory
                placeholder="이름을 입력해주세요."
                value={userName}
                disabled
              />
              <TextField
                label="이메일"
                compulsory
                placeholder="이메일을 입력해주세요."
                disabled
                value={email}
              />
              <TextField
                label="생년월일"
                compulsory
                placeholder="생년월일을 선택해주세요."
                disabled
                value={birth}
              />
              <Select label="성별" compulsory value={gender} disabled>
                <option disabled selected hidden value="">
                  성별을 선택해주세요.
                </option>

                {map(GENDER, (_gender, index) => (
                  <option key={index} value={_gender}>
                    {KOREAN_GENDER[_gender]}
                  </option>
                ))}
              </Select>
              <div className="space-y-2">
                <Label text="주민등록번호" compulsory />
                <div className="flex items-center space-x-3">
                  <TextField
                    type="number"
                    placeholder="ex) 990524"
                    value={personalId.slice(0, 6)}
                    disabled
                  />
                  <span className=""> - </span>
                  <TextField
                    type="number"
                    placeholder="1234567"
                    value={personalId.slice(6)}
                    disabled
                  />
                </div>
              </div>
              <div className="col-span-2 col-start-1 space-y-2">
                <Label compulsory>주소</Label>
                <div className="flex items-center space-x-2">
                  <input
                    className="textfield flex-1 rounded-md"
                    placeholder="주소를 검색해주세요."
                    value={address}
                    disabled
                  />
                  <Button
                    type="button"
                    text="주소 검색"
                    disabled
                    className="h-12 w-max  border-brand-1 text-brand-1"
                  />
                </div>
                <TextField
                  placeholder="상세주소 입력하기"
                  value={addressDetail}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="py-20">
            <h3 className="mb-9">최종학력</h3>
            <div className="grid grid-cols-2 gap-8">
              <TextField
                label="학교"
                placeholder="학교명을 입력해주세요."
                value={schoolName}
                disabled
              />

              <div>
                <Select label="학적" value={schoolStatus} disabled>
                  <option disabled selected hidden value="">
                    학적을 선택해주세요.
                  </option>
                  {map(SCHOOL_STATUS, (status, index) => (
                    <option key={index} value={status}>
                      {KOREAN_SCHOOL_STATUS[status]}
                    </option>
                  ))}
                </Select>
              </div>

              <TextField
                label="전공"
                placeholder="전공을 입력해주세요."
                value={major}
                disabled
              />
            </div>
          </div>
          <div className="py-20">
            <h3 className="mb-9">근무 확인사항</h3>
            <div className="grid grid-cols-1 gap-8">
              <FileUpload
                label="보건증"
                desc="최근 6개월 이내 발급된 보건증을 업로드해주세요."
                accept={['.pdf', '.png', '.jpeg', '.jpg']}
                src={healthImgUrl}
                isValueFile={false}
                fileName={healthImgUrl.split(/_(.*)/s).slice(1)[0]}
                disabled
              />
              <FileUpload
                label="부모님 동의서"
                desc="부모님 동의서를 업로드해주세요."
                accept={['.pdf', '.png', '.jpeg', '.jpg', '.doc', '.hwp']}
                src={agreementFileUrl}
                isValueFile={false}
                fileName={agreementFileUrl.split(/_(.*)/s).slice(1)[0]}
                disabled
              />
            </div>
          </div>
          <div className="py-20">
            <h3 className="mb-9">이력서</h3>
            <div className="grid grid-cols-1 gap-8">
              <FileUpload
                label="나의 이력서"
                desc="이력서를 등록해주세요."
                accept={['.pdf', '.doc', '.hwp']}
                src={resumeFileUrl}
                isValueFile={false}
                fileName={resumeFileUrl.split(/_(.*)/s).slice(1)[0]}
                disabled
              />
              <FileUpload
                label="나의 자기소개서"
                desc="자기소개서를 등록해주세요."
                accept={['.pdf', '.doc', '.hwp']}
                src={coverLetterFileUrl}
                isValueFile={false}
                fileName={coverLetterFileUrl.split(/_(.*)/s).slice(1)[0]}
                disabled
              />
            </div>
          </div>
          {appliedResume && (
            <>
              <div className="py-20">
                <AppliedCareerList
                  appliedCareers={appliedResume.appliedResumeCareers}
                />
              </div>
              <div className="pt-20">
                <AppliedCertificateList
                  appliedCertificates={appliedResume.appliedResumeCertificates}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
