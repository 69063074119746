import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Company } from '../../../api/company/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Table } from '../../../components/Table';
import { TextField } from '../../../components/TextField';
import { useCompanyWagesByUserId } from '../../../hooks/company';
import { useCreateOrUpdateWages } from '../../../hooks/wage';

export const HoursPayList = () => {
  const [wageInfo, setWageInfo] = useState<Record<number, number>>({});
  const { id: userId } = useParams<{ id: string }>();
  const { data: companies } = useCompanyWagesByUserId(userId ? +userId : 0);

  const { mutate: createOrUpdateWages } = useCreateOrUpdateWages(
    userId ? +userId : 0
  );

  useEffect(() => {
    if (companies?.length) {
      const _wageInfo = structuredClone(wageInfo);
      for (const company of companies) {
        if (company?.wages && company.wages[0]) {
          _wageInfo[company.id] = company.wages[0].price;
        }
      }
      setWageInfo(_wageInfo);
    }
  }, [companies]);

  return (
    <>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>업장명</Table.Th>
              <Table.Th>최소금액</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {companies &&
              companies.map((company: Company) => (
                <Table.Row key={company.id}>
                  <Table.Td>{company.id}</Table.Td>
                  <Table.Td>{company.companyName}</Table.Td>
                  <Table.Td>
                    <div className="flex items-center space-x-2">
                      <TextField
                        type="number"
                        value={
                          wageInfo[company.id] === undefined
                            ? 9620
                            : wageInfo[company.id]
                            ? String(wageInfo[company.id])
                            : ''
                        }
                        onChange={(e) => {
                          const _wageInfo = structuredClone(wageInfo);
                          _wageInfo[company.id] = Number(e.target.value);
                          setWageInfo(_wageInfo);
                        }}
                        defaultValue={9620}
                        min={0}
                      />
                      <div>원</div>
                    </div>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>

      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          text="Save"
          className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
          onClick={() => {
            if (!Object.keys(wageInfo).length) {
              toast.error('변경할 업장 시급을 입력해주세요.');
              return;
            }
            createOrUpdateWages(
              Object.entries(wageInfo).map(([companyId, price]) => ({
                companyId: Number(companyId),
                price,
              }))
            );
          }}
        />
      </div>
    </>
  );
};
