import { FC, HTMLAttributes } from 'react';
import { Icon } from './Icon';

export interface PartnershipCardProps extends HTMLAttributes<HTMLDivElement> {
  companyName: string;
  description: string;
  image: string;
  address: string;
}

export const PartnershipCard: FC<PartnershipCardProps> = ({
  companyName,
  description,
  image,
  address,
}) => {
  return (
    <div className="space-y-3">
      <div className="relative aspect-square overflow-hidden rounded-md">
        <img
          className="absolute h-full w-full object-cover"
          src={image}
          crossOrigin="anonymous"
          alt=""
        />
      </div>

      <div className="mt-2 flex space-x-1">
        <Icon.Home className="wh-5 mt-1 flex-none text-gray-600 " />
        <h4>{companyName}</h4>
      </div>
      <p className="text-15 text-gray-700 ">{description}</p>
      <div className="flex items-center space-x-1">
        <Icon.MapPin className="wh-4 flex-none  text-brand-1" />
        <p className="truncate text-15 font-medium ">{address}</p>
      </div>
    </div>
  );
};
