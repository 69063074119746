import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { TextField } from '../../../components/TextField';
import FroalaEditor from '../../../components/froala/FroalaEditor';
import {
  useDeleteNoticeMutation,
  useNoticeByAdminId,
  useUpdateNoticeMutation,
} from '../../../hooks/notice';
import AdminH1 from '../../components/AdminH1';

export const NoticeDetail = () => {
  const { mutate: updateNoticeMutate } = useUpdateNoticeMutation(() =>
    navigate('/admin/notice')
  );
  const { mutate: deleteNoticeMutate } = useDeleteNoticeMutation();
  const { id: noticeId } = useParams<{ id: string }>();
  if (!noticeId) {
    return <></>;
  }
  const { data: notice } = useNoticeByAdminId(+noticeId);
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const onDelete = () => {
    if (!notice) {
      return;
    }
    const deleteConfirm = window.confirm('공지를 삭제하시겠습니까?');
    if (deleteConfirm) {
      deleteNoticeMutate(notice.id);
      navigate(-1);
    }
  };

  useEffect(() => {
    if (notice) {
      setTitle(notice.title);
      setDescription(notice.description);
    }
  }, [notice]);

  if (!notice) {
    return null;
  }

  const onUpdate = () => {
    if (!title) {
      toast.error('필수값을 입력해주세요.');
    } else {
      updateNoticeMutate({
        id: +noticeId,
        title,
        description,
      });
      navigate(-1);
    }
  };

  return (
    <>
      <div className="py-2">
        <AdminH1>공지사항 상세</AdminH1>
      </div>
      <div className="space-y-5 border-b pb-5">
        <div className="space-y-1">
          <TextField
            compulsory
            label="제목"
            className="text-24"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <Label text="공지사항 상세" compulsory />
      <FroalaEditor
        defaultValue={notice?.description}
        onChange={(model) => {
          setDescription(model);
        }}
      />

      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          text="Delete"
          onClick={onDelete}
          className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
        />
        <Button
          text="Save"
          className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
          onClick={onUpdate}
        />
      </div>
    </>
  );
};
