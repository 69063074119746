import React, { FC, useState } from 'react';
import { map } from 'lodash';
import { Certificate } from '../api/certificate/type';
import { ModalType } from '../constance';
import { useAllCertificates } from '../hooks/certificate';
import { Button } from './Button';
import { CertificateModal } from './modal/CertificateModal';
import { DeleteCertificateModal } from './modal/DeleteCertificateModal';

interface CertificateListProps {
  resumeId: string;
  isAdmin: boolean;
}

export const CertificateList: FC<CertificateListProps> = ({
  resumeId,
  isAdmin,
}) => {
  const [certificateId, setCertificateId] = useState('');
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [selectCertificate, setSelectCertificate] = useState<Certificate>();
  const [showDeleteCertificateModal, setShowDeleteCertificateModal] =
    useState(false);
  const { data: allCeritificate } = useAllCertificates(resumeId, isAdmin);
  return (
    <>
      <CertificateModal
        certificate={selectCertificate}
        open={showCertificateModal}
        onClose={() => {
          setShowCertificateModal(false);
          setSelectCertificate(undefined);
        }}
        modalType={modalType}
        certificateId={certificateId}
        resumeId={resumeId}
      />

      <DeleteCertificateModal
        open={showDeleteCertificateModal}
        onClose={() => setShowDeleteCertificateModal(false)}
        certificateId={certificateId}
        resumeId={resumeId}
      />
      <h3 className="mb-9">특기사항</h3>
      {allCeritificate &&
        map(allCeritificate, (certificate: Certificate) => (
          <div className="mb-8" key={certificate.id}>
            <div className="grid grid-cols-1 gap-8">
              <div className="space-y-2">
                <p className="text-13 text-gray-700">자격증 및 특기사항</p>
                <p>{certificate.description}</p>
              </div>
            </div>
            <div className="mt-5 flex justify-end space-x-2 ">
              <Button
                type="button"
                text={'수정'}
                className="h-10 bg-blue-50 text-blue-500"
                onClick={() => {
                  setSelectCertificate(certificate);
                  setCertificateId(String(certificate.id));
                  setModalType(ModalType.EDIT);
                  setShowCertificateModal(true);
                }}
              />
              <Button
                type="button"
                text={'삭제'}
                className="h-10 bg-red-50 text-red-500"
                onClick={() => {
                  setCertificateId(String(certificate.id));
                  setShowDeleteCertificateModal(true);
                }}
              />
            </div>
          </div>
        ))}
      <Button
        type="button"
        text={'+ 추가하기'}
        className="w-44 bg-blue-50 text-blue-500"
        onClick={() => {
          setModalType(ModalType.ADD);
          setShowCertificateModal(true);
        }}
      />
    </>
  );
};
