import React, { FC, Fragment, useState } from 'react';
import { map } from 'lodash';
import { Career } from '../api/career/type';
import { ModalType } from '../constance';
import { useAllCareers } from '../hooks/career';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { Button } from './Button';
import { CareerModal } from './modal/CareerModal';
import { DeleteCareerModal } from './modal/DeleteCareerModal';

interface CareerListProps {
  resumeId: string;
  isAdmin: boolean;
}

export const CareerList: FC<CareerListProps> = ({ resumeId, isAdmin }) => {
  const [selectCareer, setSelectCareer] = useState<Career>();
  const [careerId, setCareerId] = useState('');
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [showCareerModal, setShowCareerModal] = useState(false);
  const [showDeleteCareerModal, setShowDeleteCareerModal] = useState(false);
  const { data: allCareers } = useAllCareers(resumeId, isAdmin);
  return (
    <>
      <CareerModal
        career={selectCareer}
        open={showCareerModal}
        onClose={() => {
          setShowCareerModal(false);
          setSelectCareer(undefined);
        }}
        modalType={modalType}
        careerId={careerId}
        resumeId={resumeId}
      />
      <DeleteCareerModal
        open={showDeleteCareerModal}
        onClose={() => setShowDeleteCareerModal(false)}
        careerId={careerId}
        resumeId={resumeId}
      />
      <h3 className="mb-9">경력사항</h3>
      {allCareers &&
        map(allCareers, (career: Career) => {
          return (
            <Fragment key={career.id}>
              <div className="mb-8">
                <div className="grid grid-cols-1 gap-5">
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">업체명</p>
                    <p>{career.company}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">직무내용</p>
                    <p>{career.position}</p>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">근무기간</p>
                    <span>
                      {utcToLocalFormat(
                        career.workingStartAt.toLocaleString(),
                        MomentFormat.YYYYMMDD
                      )}
                    </span>
                    <span>{`  ~  `}</span>
                    <span>
                      {utcToLocalFormat(
                        career.workingEndAt.toLocaleString(),
                        MomentFormat.YYYYMMDD
                      )}
                    </span>
                  </div>
                  <div className="space-y-2">
                    <p className="text-13 text-gray-700">상세내용</p>
                    <p>{career.description}</p>
                  </div>
                </div>
                <div className="mt-5 flex justify-end space-x-2 ">
                  <Button
                    type="button"
                    text={'수정'}
                    className="h-10 bg-blue-50 text-blue-500"
                    onClick={() => {
                      setSelectCareer(career);
                      setCareerId(String(career.id));
                      setModalType(ModalType.EDIT);
                      setShowCareerModal(true);
                    }}
                  />
                  <Button
                    type="button"
                    text={'삭제'}
                    className="h-10 bg-red-50 text-red-500"
                    onClick={() => {
                      setCareerId(String(career.id));
                      setShowDeleteCareerModal(true);
                    }}
                  />
                </div>
              </div>
            </Fragment>
          );
        })}
      <Button
        type="button"
        text={'+ 추가하기'}
        className="w-44 bg-blue-50 text-blue-500"
        onClick={() => {
          setModalType(ModalType.ADD);
          setShowCareerModal(true);
        }}
      />
    </>
  );
};
