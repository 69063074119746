import React, { ButtonHTMLAttributes, FC } from 'react';

interface TabProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  title: string;
  titleClassName?: string;
}

export const Tab: FC<TabProps> = ({
  selected,
  title,
  titleClassName,
  ...props
}) => {
  return (
    <button
      className={`z-10 pt-4 pb-2 ${
        selected ? 'border-b-2 border-brand-1 text-brand-1' : 'text-gray-400'
      }`}
      {...props}
    >
      <div className={titleClassName}> {title}</div>
    </button>
  );
};
