import { stringify } from 'qs';
import { adminApi } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import {
  CreatePartnersIntroductionDto,
  UpdatePartnersIntroductionDto,
} from './type';

export function createPartnersIntroduction(
  createPartnersIntroductionDto: CreatePartnersIntroductionDto
) {
  return adminApi.post(PATHS.ROOT, createPartnersIntroductionDto);
}

export function updatePartnersIntroduction(
  updatePartnersIntroductionDto: UpdatePartnersIntroductionDto
) {
  const { id } = updatePartnersIntroductionDto;
  return adminApi.patch(PATHS.getOneById(id), updatePartnersIntroductionDto);
}

export function deletePartnersIntroduction(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllPartnersIntroductionByAdmin(
  paginationDto: PaginationDto
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}
