import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createPartTimeJobDate,
  createPartTimeJobDateBulk,
  deletePartTimeJobDate,
  updatePartTimeJobDate,
} from '../../api/part-time-job-date';
import { PATHS } from '../../api/part-time-job-date/paths';
import {
  CreatePartTimeJobDateBulkDto,
  CreatePartTimeJobDateDto,
  DeletePartTimeJobDateDto,
  PartTimeJobDate,
  UpdatePartTimeJobDateDto,
} from '../../api/part-time-job-date/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';

export function useAllPartTimeJobDateByPartTimeJobId(partTimeJobId: number) {
  return useQuery<Record<string, PartTimeJobDate[]>, AxiosError>(
    PATHS.partTimeJobId(partTimeJobId),
    adminFetcher
  );
}

export function useAllPartTimeJobDateByAdmin(queryString: string) {
  return useQuery<PartTimeJobDate, AxiosError>(
    `${PATHS.ROOT}${queryString}`,
    adminFetcher
  );
}

export function usePartTimeJobDateById(id: number) {
  return useQuery<PartTimeJobDate, AxiosError>(PATHS.getOneById(id), fetcher, {
    enabled: !!id,
  });
}

export const useCreatePartTimeJobDateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (createPartTimeJobDateDto: CreatePartTimeJobDateDto) =>
      createPartTimeJobDate(createPartTimeJobDateDto),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('근무시간 생성 실패');
      },
    }
  );
};

export const useCreatePartTimeJobDateBulkMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createPartTimeJobDateBulkDto: CreatePartTimeJobDateBulkDto) =>
      createPartTimeJobDateBulk(createPartTimeJobDateBulkDto),
    {
      onSuccess: (_, createPartTimeJobDateBulkDto) => {
        onSuccess && onSuccess();
        toast.success('근무시간 생성 성공');
        queryClient.invalidateQueries(
          PATHS.bulk(createPartTimeJobDateBulkDto.partTimeJobId)
        );
        queryClient.invalidateQueries(
          PATHS.partTimeJobId(createPartTimeJobDateBulkDto.partTimeJobId)
        );
      },
      onError: () => {
        toast.error('근무시간 생성 실패');
      },
    }
  );
};

export const useUpdatePartTimeJobDateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (updatePartTimeJobDateDto: UpdatePartTimeJobDateDto) =>
      updatePartTimeJobDate(updatePartTimeJobDateDto),
    {
      onSuccess: (_, updatePartTimeJobDateDto) => {
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getOneById(updatePartTimeJobDateDto.id)
        );
      },
      onError: () => {
        toast.error('근무시간 수정 실패');
      },
    }
  );
};

export const useDeletePartTimeJobDateMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (deletePartTimeJobDateDto: DeletePartTimeJobDateDto) =>
      deletePartTimeJobDate(deletePartTimeJobDateDto),
    {
      onSuccess: (_, deletePartTimeJobDateDto) => {
        onSuccess && onSuccess();
        toast.success('근무일 삭제 성공');
        queryClient.invalidateQueries(
          PATHS.partTimeJobId(deletePartTimeJobDateDto.partTimeJobId)
        );
      },
      onError: () => {
        toast.error('근무일 삭제 실패');
      },
    }
  );
};
