import { FC, ImgHTMLAttributes, useId } from 'react';
import { join } from 'lodash';
import { Icon } from './Icon';
export interface IdPhotoProps extends ImgHTMLAttributes<HTMLImageElement> {
  accept?: string[];
  helper?: string;
  value?: FileList | string;
  setValue?: (value: FileList | string) => void;
  disabled?: boolean;
}

export const IdPhoto: FC<IdPhotoProps> = ({
  src,
  alt = 'avatar',
  accept,
  helper,
  setValue,
  disabled,
}) => {
  const id = useId();

  return (
    <>
      <div className=" disabled relative mt-1 grid h-48 w-40 place-items-center rounded-md border">
        <label htmlFor={id}>
          {src ? (
            <>
              <img
                className="h-48 w-40 rounded-md object-cover object-center"
                src={src}
                alt={alt}
                crossOrigin="anonymous"
              />
              <div className="absolute -right-4 -bottom-4 cursor-pointer rounded-full bg-sub-1 p-2.5">
                <Icon.EditPen className="wh-5 stroke-white" />
              </div>
            </>
          ) : (
            <div className="flex cursor-pointer flex-col items-center space-y-2 p-4">
              <div className="rounded-full bg-green-500 p-1.5">
                <Icon.Plus className="wh-5 text-white" />
              </div>
              <p className="text-center text-14 text-gray-600">
                지원서 사진을
                <br /> 업로드해주세요! (1장)
              </p>
              <p className="text-12 text-gray-600">PNG, JPG, JPEG 가능</p>
            </div>
          )}
          {!disabled && (
            <input
              onChange={(e) => {
                const files = e.target.files;
                if (!setValue || !files) return;
                if (files.length === 0) return;
                setValue(files);
              }}
              type="file"
              accept={join(accept, ',')}
              id={id}
              className="hidden"
            />
          )}
        </label>
      </div>
      {helper && <p className="text-error text-sm text-red-500">{helper}</p>}
    </>
  );
};
