import { Fragment, useEffect, useId, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { format, getDay } from 'date-fns';
import { filter, last, map, findIndex } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { uploadFile } from '../../../api/file';
import { SELECT_GENDER } from '../../../api/part-time-job/type';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/Label';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import FroalaEditor from '../../../components/froala/FroalaEditor';
import { ModalType } from '../../../constance';
import { WORK_FORM } from '../../../constants';
import { useCreatePartTimeJobMutation } from '../../../hooks/part-time-job';
import { getKoreanDay } from '../../../utils';
import { CompanyPartTimeModal } from '../../components/modal/CompanyPartTimeModal';
import { DateList, TimeList, TIME_STAMP } from './types';
import { convertDateList } from './util';

export interface PartTimeJobFormValue {
  title: string;
  mainImgUrl: FileList | string;
  recruitNumber: number;
  workType: string;
  recruitDescription: string;
  gender: SELECT_GENDER;
  hourPay: number;
  applicationMethod: string;
  workForm: string;
  averageWorkingHours: string;
  description: string;
}

export const KOREAN_SELECT_GENDER = {
  [SELECT_GENDER.BOTH]: '성별무관',
  [SELECT_GENDER.MALE]: '남성',
  [SELECT_GENDER.FEMALE]: '여성',
};

export const ComepanyPartTimeJobAdd = () => {
  const navigate = useNavigate();
  const id = useId();
  const { id: companyId } = useParams<{ id: string }>();
  const [src, setSrc] = useState<string>('');
  const [showCompanyPartTimeModal, setShowCompanyPartTimeModal] =
    useState(false);
  const [dateList, setDateList] = useState<DateList[]>([]);
  const [editDate, setEditDate] = useState<DateList>();
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [isMainImgUrlEmpty, setIsMainImgUrlEmpty] = useState(false);
  const [triggerError, setTriggerError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const formSchema = Yup.object().shape({
    title: Yup.string().required('공고 제목을 입력해주세요.'),
    mainImgUrl: Yup.mixed<FileList>(),
    recruitNumber: Yup.number()
      .typeError('모집인원을 입력해주세요.')
      .moreThan(-1, '0 이상의 값을 입력하세요.'),
    workType: Yup.string(),
    recruitDescription: Yup.string().required(
      '모집 내용을 간략하게 입력해주세요.'
    ),
    gender: Yup.mixed<SELECT_GENDER>()
      .oneOf(Object.values(SELECT_GENDER), '모집성별을 선택해주세요.')
      .required('모집성별을 선택해주세요.'),
    hourPay: Yup.number()
      .typeError('금액을 입력해주세요')
      .moreThan(0, '0 이상의 값을 입력하세요.'),
    applicationMethod: Yup.string(),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PartTimeJobFormValue>({
    resolver: yupResolver(formSchema),
    defaultValues: { description: '' },
  });

  const _editDateList = (editDate: DateList) => {
    const updateDateList = [...dateList];
    const index = findIndex(
      updateDateList,
      (dateList) => dateList.id === editDate.id
    );
    if (index !== -1) {
      updateDateList[index] = editDate;
      setDateList(updateDateList);
    }
  };

  const _addDateList = (date: string, timeList: TimeList[]) => {
    setDateList((prev) => [
      ...prev,
      {
        id: (last(dateList)?.id || 0) + 1,
        workDate: date,
        timeList,
      },
    ]);
  };

  const _deleteDateList = (deleteId: number) => {
    setDateList(filter(dateList, (item) => item.id !== deleteId));
  };

  const { mutate: createPartTimeJobMuate } = useCreatePartTimeJobMutation(() =>
    navigate(-1)
  );

  const mainImgUrl = watch('mainImgUrl');

  useEffect(() => {
    if (!mainImgUrl || mainImgUrl.length === 0) {
      return;
    }
    if (mainImgUrl instanceof FileList) {
      setSrc(mainImgUrl[0] && URL.createObjectURL(mainImgUrl[0]));
    }
  }, [mainImgUrl]);

  useEffect(() => {
    if (triggerError) {
      if (!mainImgUrl) {
        setIsMainImgUrlEmpty(true);
      } else {
        setIsMainImgUrlEmpty(false);
      }
    }
  }, [mainImgUrl, triggerError]);
  return (
    <>
      <CompanyPartTimeModal
        open={showCompanyPartTimeModal}
        onClick={(workDate: string, timeList: TimeList[]) => {
          _addDateList(workDate, timeList);
        }}
        onDeleteClick={(deleteId: number) => _deleteDateList(deleteId)}
        onEdit={(editDate: DateList) => {
          _editDateList(editDate);
        }}
        onClose={() => {
          setShowCompanyPartTimeModal(false);
          editDate && setEditDate(undefined);
        }}
        editDate={editDate}
        modalType={modalType}
      />
      <form
        onSubmit={handleSubmit(async (data) => {
          setIsSubmitted(true);
          if (!companyId) {
            setIsSubmitted(false);
            return;
          }
          if (dateList.length === 0 || !mainImgUrl) {
            if (dateList.length === 0) {
              toast.error('근무일을 추가해주세요.');
            }
            if (!mainImgUrl) {
              toast.error('공고 메인 이미지를 등록해주세요.');
              setTriggerError(true);
            }
            setIsSubmitted(false);
            return;
          }
          const partTimeJobDates = convertDateList(dateList);

          let newMainImgUrl = '';

          if (data.mainImgUrl instanceof FileList && data.mainImgUrl[0]) {
            await uploadFile(data.mainImgUrl[0]).then(
              (res: AxiosResponse<string>) => (newMainImgUrl = res.data)
            );
          } else if (typeof data.mainImgUrl === 'string') {
            newMainImgUrl = data.mainImgUrl;
          }

          const newData = {
            ...data,
            partTimeJobDates,
            companyId: +companyId,
            mainImgUrl: newMainImgUrl,
          };
          createPartTimeJobMuate(newData);
        })}
      >
        <h3 className="mb-5 after:ml-0.5 after:text-brand-1 after:content-['*']">
          제목
        </h3>
        <TextField
          placeholder="공고 제목을 입력해주세요."
          helper={errors.title?.message}
          {...register('title')}
        />
        <h3 className="mt-10 mb-5 after:ml-0.5 after:text-brand-1 after:content-['*']">
          공고 메인 이미지
        </h3>
        <div className="mt-1 grid aspect-[5/2] w-full place-items-center rounded-md border">
          <label htmlFor={id} className="relative w-full">
            {src ? (
              <>
                <img
                  className="aspect-[5/2] w-full rounded-md object-cover object-center"
                  src={src}
                  crossOrigin="anonymous"
                  alt=""
                />
                <div className="absolute -right-4 -bottom-4 cursor-pointer rounded-full bg-sub-1 p-2.5">
                  <Icon.EditPen className="wh-5 stroke-white" />
                </div>
              </>
            ) : (
              <div className="flex cursor-pointer flex-col items-center space-y-2 p-4">
                <div className="rounded-full bg-green-500 p-1.5">
                  <Icon.Plus className="wh-5 text-white" />
                </div>
                <p className="text-center text-14 text-gray-600">
                  이미지를
                  <br /> 업로드해주세요! (1장)
                </p>
                <p className="text-12 text-gray-600">PNG, JPG, JPEG 가능</p>
              </div>
            )}
            <input
              onChange={(e) => {
                const files = e.target.files;
                if (!files || files.length === 0) return;
                if (files.length === 0) return;
                setValue('mainImgUrl', files);
              }}
              type="file"
              accept=".png, .jpg, .jpeg"
              id={id}
              className="hidden"
            />
          </label>
        </div>
        <p className="text-error text-center text-sm text-red-500">
          {isMainImgUrlEmpty &&
            !mainImgUrl &&
            '공고 메인 이미지를 등록해주세요.'}
        </p>

        <h3 className="mt-10 mb-5">근무일</h3>

        <div className="flex justify-between py-5">
          <Button
            type="button"
            text="날짜 추가하기"
            className="w-40 bg-gray-800 text-white"
            onClick={() => {
              setModalType(ModalType.ADD);
              setShowCompanyPartTimeModal(true);
              setEditDate(undefined);
            }}
          />
        </div>

        {map(dateList, (date) => {
          const newWorkDate = new Date(date.workDate);
          const day = getKoreanDay(getDay(newWorkDate));

          return (
            <Fragment key={date.id}>
              <div>
                <Label text="날짜" />
                <p className="text-20 font-medium">
                  {format(newWorkDate, 'yyyy-MM-dd')}
                  {`  (${day})`}
                </p>
              </div>

              <div className="grid grid-cols-2 gap-8">
                <div className="text-left">
                  <Label text="시간" />
                  <div className="items-center space-y-3 text-16 font-medium">
                    {map(date.timeList, (time) => {
                      const { startTime, endTime } = time;
                      const { hour: startHour, min: startMin } = startTime;
                      const { hour: endHour, min: endMin } = endTime;
                      return (
                        <div
                          key={[date.id, time.index].join('_')}
                          className="flex space-x-4"
                        >
                          <p>
                            {TIME_STAMP[startHour]} : {TIME_STAMP[startMin]}
                          </p>
                          <p>~</p>
                          <p>
                            {TIME_STAMP[endHour]} : {TIME_STAMP[endMin]}
                          </p>
                        </div>
                      );
                    })}

                    <Button
                      onClick={() => {
                        setModalType(ModalType.EDIT);
                        setEditDate(JSON.parse(JSON.stringify(date)));
                        setShowCompanyPartTimeModal(true);
                      }}
                      type="button"
                      text="수정"
                      className=" bg-blue-50 text-blue-500"
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}

        <h3 className="mt-10 mb-5">채용정보</h3>
        <div className="grid grid-cols-2 gap-5">
          <TextField
            label="모집인원"
            type="number"
            compulsory
            placeholder="모집인원을 입력해주세요."
            helper={errors.recruitNumber?.message}
            {...register('recruitNumber')}
          />
          <TextField
            label="근무타입"
            placeholder="근무타입을 입력해주세요."
            helper={errors.workType?.message}
            {...register('workType')}
          />
          <TextField
            label="모집내용"
            compulsory
            placeholder="모집내용을 간략하게 입력해주세요."
            helper={errors.recruitDescription?.message}
            {...register('recruitDescription')}
          />
          <Select
            label="성별"
            compulsory
            placeholder="모집성별을 선택해주세요."
            helper={errors.gender?.message}
            {...register('gender')}
          >
            <option disabled selected hidden value="">
              성별을 선택해주세요.
            </option>

            {map(SELECT_GENDER, (gender, index) => (
              <option key={index} value={gender}>
                {KOREAN_SELECT_GENDER[gender]}
              </option>
            ))}
          </Select>
          <TextField
            label="시급"
            compulsory
            type="number"
            placeholder="금액을 입력해주세요."
            helper={errors.hourPay?.message}
            {...register('hourPay')}
          />
          <TextField
            label="지원방법"
            placeholder="지원방법을 입력해주세요."
            helper={errors.applicationMethod?.message}
            {...register('applicationMethod')}
          />
          <Select
            label="업무형태"
            helper={errors.workForm?.message}
            {...register('workForm')}
          >
            <option selected hidden>
              업무형태를 입력해주세요.
            </option>
            <option value="">전체</option>
            {map(WORK_FORM, (workType, index) => (
              <option key={index} value={workType}>
                {workType}
              </option>
            ))}
          </Select>
          <TextField
            label="평균 근무시간"
            placeholder="평균 근무시간을 입력해주세요."
            helper={errors.averageWorkingHours?.message}
            {...register('averageWorkingHours')}
          />
        </div>

        <h3 className="mt-10 mb-5">상세정보</h3>
        <FroalaEditor
          defaultValue={''}
          onChange={(model) => {
            setValue('description', model);
          }}
        />
        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            type="button"
            text="Delete"
            className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
            onClick={() => navigate(-1)}
          />
          <Button
            text="Save"
            className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
            disabled={isSubmitted}
          />
        </div>
      </form>
    </>
  );
};
