import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { TextField } from '../../../components/TextField';
import { useFindPasswordMutation } from '../../../hooks/auth';

export const FindPasswordPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const { mutate: findPassword } = useFindPasswordMutation(() =>
    navigate(`/find-password/send-email?email=${email}`)
  );

  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <form className="flex w-full max-w-md flex-col space-y-4">
          <h1 className="mb-5 text-32 font-semibold text-gray-900">
            비밀번호 찾기
          </h1>

          <form className="mt-6 space-y-6">
            <div className="mb-14">
              <TextField
                label="가입 시 사용한 이메일"
                compulsory
                placeholder="가입시 사용한 이메일을 입력해주세요."
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="mt-2 text-15 text-gray-500">
                *이메일이 수신 되지 않거나, 이메일주소가 기억나지 않을 경우
                고객센터로 문의 주시기 바랍니다.
              </p>
            </div>
            <Button
              type="button"
              text="이메일 발송"
              className="filled-brand-1 w-full"
              onClick={() => findPassword({ email })}
            />
          </form>
        </form>
      </div>
    </>
  );
};
