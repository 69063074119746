import React, { FC, useEffect, useId, useState } from 'react';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../api/file';
import { PartnersIntroduction } from '../../../api/partners-introduction/type';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/Label';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { AnimationLayout } from '../../../components/modal/AnimationLayout';
import {
  useUpdatePartnersIntroductionMutation,
  useDeletePartnersIntroductionMutation,
} from '../../../hooks/partners-introduction';

interface PartnersIntroModalProps {
  onClose: () => void;
  partner: PartnersIntroduction | null;
}

export const PartnersIntroModal: FC<PartnersIntroModalProps> = ({
  onClose,
  partner,
}) => {
  const id = useId();
  const { mutate: updatePartnersIntroductionMutate } =
    useUpdatePartnersIntroductionMutation(onClose);
  const { mutate: deletePartnersIntroductionMutate } =
    useDeletePartnersIntroductionMutation(onClose);
  const [companyName, setCompanyName] = useState('');
  const [image, setImage] = useState<FileList | string>();
  const [src, setSrc] = useState<string>('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');

  const onDelete = () => {
    const deleteConfirm = window.confirm('협력사 소개를 삭제하시겠습니까?');
    if (!partner) {
      return;
    }
    if (deleteConfirm) {
      deletePartnersIntroductionMutate(partner.id);
    }
  };

  const onUpdate = async () => {
    if (!partner) {
      toast.error('협력사 소개 정보가 없습니다.');
      return;
    }
    if (!companyName || !image || image.length === 0) {
      toast.error('필수값을 입력해주세요.');
      return;
    }
    let imageUrl = '';

    if (image instanceof FileList && image[0]) {
      await uploadFile(image[0]).then(
        (res: AxiosResponse<string>) => (imageUrl = res.data)
      );
    } else if (typeof image === 'string') {
      imageUrl = image;
    }

    updatePartnersIntroductionMutate({
      id: +partner.id,
      companyName,
      image: imageUrl,
      description,
      address,
    });
  };

  useEffect(() => {
    if (partner) {
      setCompanyName(partner.companyName);
      setDescription(partner.description);
      setImage(partner.image);
      setAddress(partner.address);
    }
  }, [partner]);

  useEffect(() => {
    if (!image || image.length === 0) {
      return;
    }
    if (image instanceof FileList) {
      setSrc(image[0] && URL.createObjectURL(image[0]));
    } else {
      setSrc(image);
    }
  }, [image]);

  return (
    <AnimationLayout open={!!partner} onClose={onClose} isAdmin>
      <div className="my-8 max-h-[600px] w-full max-w-xl transform flex-col space-y-3 overflow-hidden overflow-y-auto rounded-lg bg-white p-8 shadow-xl transition-all">
        <div className="flex justify-between">
          <h4 className="text-gray-800">협력사 소개 상세</h4>
          <button
            type="button"
            className="right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
            onClick={onClose}
          >
            <Icon.X className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="grid gap-y-4 px-12">
          <Label compulsory className="mt-1 text-left">
            이미지
          </Label>

          <div className="grid aspect-square w-80 place-items-center rounded-md border">
            <label htmlFor={id} className="relative w-full">
              {src ? (
                <>
                  <img
                    className="aspect-square w-full rounded-md object-cover object-center"
                    src={src}
                    crossOrigin="anonymous"
                    alt=""
                  />
                  <div className="absolute -right-4 -bottom-4 cursor-pointer rounded-full bg-sub-1 p-2.5">
                    <Icon.EditPen className="wh-5 stroke-white" />
                  </div>
                </>
              ) : (
                <div className="flex cursor-pointer flex-col items-center space-y-2 p-4">
                  <div className="rounded-full bg-green-500 p-1.5">
                    <Icon.Plus className="wh-5 text-white" />
                  </div>
                  <p className="text-center text-14 text-gray-600">
                    이미지를
                    <br /> 업로드해주세요! (1장)
                  </p>
                  <p className="text-12 text-gray-600">PNG, JPG, JPEG 가능</p>
                </div>
              )}
              <input
                onChange={(e) => {
                  const files = e.target.files;
                  if (!files || files.length === 0) return;
                  if (files.length === 0) return;
                  setImage(files);
                }}
                type="file"
                accept=".png, .jpg, .jpeg"
                id={id}
                className="hidden"
              />
            </label>
          </div>
          <TextField
            label="회사명"
            compulsory
            placeholder="회사명을 입력해주세요."
            labelClassname="text-left"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
          <TextArea
            label="회사 소개"
            placeholder="소개할 내용을 입력해주세요."
            labelClassname="text-left"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <TextField
            label="주소"
            compulsory
            placeholder="주소를 입력해주세요."
            labelClassname="text-left"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />

          <div>
            <Button
              text="저장하기"
              onClick={onUpdate}
              className="mt-4 w-full bg-gray-800 text-white"
            />
            <Button
              text="삭제하기"
              onClick={onDelete}
              className="mt-4 w-full border-2 border-gray-500 bg-white text-gray-800"
            />
          </div>
        </div>
      </div>
    </AnimationLayout>
  );
};
