import React, { FC } from 'react';
import { some } from 'lodash';
import { Payment } from '../../api/payment/type';
import { AnimationLayout } from './AnimationLayout';

interface PaymentModalProps {
  onClose: () => void;
  open: boolean;
  payment: Payment;
}

export const PaymentModal: FC<PaymentModalProps> = ({
  open,
  onClose,
  payment,
}) => {
  const {
    totalWorkTime,
    restTime,
    totalAmount,
    hourPay,
    overtimePay,
    nightPay,
    holidayPay,
    otherPay,
    finalAmount,
    insurance,
    incomeTax,
    fee,
    deductedAmount,
  } = payment;

  const haveExtraPay = some(
    [overtimePay, nightPay, holidayPay, otherPay],
    (pay) => pay
  );
  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 h-[600px] w-full max-w-3xl transform space-y-3 overflow-hidden overflow-y-auto rounded-lg bg-white p-6 text-left shadow-xl transition-all md:h-full ">
          <div className="flex justify-between">
            <h4 className="text-gray-800">급여상세내역</h4>
          </div>
          <div className="mt-2">
            <div className="grid grid-cols-1 gap-y-0 gap-x-0 md:grid-cols-2 md:gap-x-4 ">
              <div>
                <div className="mb-3 block space-y-2 rounded-md bg-sub-2 p-6 md:hidden ">
                  <p className="flex justify-between text-13 text-gray-700">
                    <span>지급합계 - 공제항목</span>
                    <span>
                      {totalAmount}-{deductedAmount}
                    </span>
                  </p>
                  <div className=" flex justify-between">
                    <h3 className="font-medium">실지급액</h3>
                    <h3 className="text-red-500">{finalAmount} 원</h3>
                  </div>
                </div>
                <div className="space-y-2 rounded-md bg-sub-3 p-6">
                  <p className="text-13 text-gray-700">
                    {`(총 근무시간 - 휴게시간) X 기본시급 ${
                      haveExtraPay ? '+ 수당' : ''
                    } `}
                  </p>
                  <div className="flex justify-between">
                    <h3 className="font-medium">지급합계</h3>
                    <h3>{totalAmount} 원</h3>
                  </div>
                </div>
                <div className="space-y-1 px-6 pt-7 ">
                  <p>근무시간</p>
                  <div className="flex justify-between">
                    <p className="text-gray-600">총 근무시간</p>
                    <p>{totalWorkTime + restTime} 시간</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-gray-600">휴게시간(공제)</p>
                    <p>{restTime} 시간</p>
                  </div>
                </div>
                <div className="space-y-1 px-6 pt-7 ">
                  <p className="text-gray-700">시급</p>
                  <div className="flex justify-between">
                    <p className="text-gray-600">기본시급</p>
                    <p>{hourPay} 원</p>
                  </div>

                  {overtimePay !== 0 && (
                    <div className="flex justify-between">
                      <p className="text-gray-600">초과근무 수당</p>
                      <p>{overtimePay} 원</p>
                    </div>
                  )}

                  {nightPay !== 0 && (
                    <div className="flex justify-between">
                      <p className="text-gray-600">야간근무 수당</p>
                      <p>{nightPay} 원</p>
                    </div>
                  )}
                  {holidayPay !== 0 && (
                    <div className="flex justify-between">
                      <p className="text-gray-600">주휴 수당</p>
                      <p>{holidayPay} 원</p>
                    </div>
                  )}
                  {otherPay !== 0 && (
                    <div className="flex justify-between">
                      <p className="text-gray-600">기타 수당</p>
                      <p>{otherPay} 원</p>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="hidden space-y-2 rounded-md bg-sub-2 p-6 md:block">
                  <p className="flex justify-between text-13 text-gray-700">
                    <span>지급합계 - 공제항목</span>
                    <span>
                      {totalAmount} - {deductedAmount}
                    </span>
                  </p>
                  <div className=" flex justify-between">
                    <h3 className="font-medium">실지급액</h3>
                    <h3 className="text-red-500">{finalAmount} 원</h3>
                  </div>
                </div>
                <div className="space-y-1 px-6 pt-7 ">
                  <p>공제항목</p>
                  <div className="flex justify-between">
                    <p className="text-gray-600">4대보험</p>
                    <p>{insurance} 원</p>
                  </div>
                  <div className="flex justify-between">
                    <p className="text-gray-600">소득세</p>
                    <p>{incomeTax} 원</p>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <p className="text-gray-600">타행수수료</p>
                      <p>{fee} 원</p>
                    </div>
                    <p className="text-left text-13 text-gray-600 md:text-right">
                      (신한은행 외)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-left"></div>
        </div>
      </AnimationLayout>
    </>
  );
};
