import { AxiosError } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import {
  bulkCreateOrUpdateWagesByUserId,
  getAllWagesByUserId,
  getMyWages,
} from '../../api/wage';
import { PATHS } from '../../api/wage/paths';
import { UpdateWageDto, Wage } from '../../api/wage/type';
import { Paginated, PaginationDto } from '../../types';

export function useAllWagesByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  return useQuery<Paginated<Wage>, AxiosError>(
    [PATHS.getByUserId(userId), paginationDto],
    () => getAllWagesByUserId(paginationDto, userId),
    { keepPreviousData: true }
  );
}

export function useCreateOrUpdateWages(userId: number) {
  return useMutation(
    (wageDtos: UpdateWageDto[]) =>
      bulkCreateOrUpdateWagesByUserId(wageDtos, userId),
    {
      onSuccess: () => {
        toast.success('업장 시급이 등록되었습니다.');
      },
      onError: () => {
        toast.error('업장 시급 등록에 실패했습니다.');
      },
    }
  );
}

export function useMyWages() {
  return useQuery<Wage[], AxiosError>([PATHS.ME], () => getMyWages(), {
    keepPreviousData: true,
  });
}
