import { api } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateLikePartTimeJobDto } from './type';

export function createLike(createLikePartTimeJobDto: CreateLikePartTimeJobDto) {
  const { partTimeJobId } = createLikePartTimeJobDto;

  return api.post(PATHS.partTimeJobId(partTimeJobId), createLikePartTimeJobDto);
}

export function deleteLike(partTimeJobId: number) {
  return api.delete(PATHS.partTimeJobId(partTimeJobId));
}
