import { stringify } from 'qs';
import { adminApi } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import {
  CreateCompanyIntroductionDto,
  UpdateCompanyIntroductionDto,
} from './type';

export function createCompanyIntroduction(
  createCompanyIntroductionDto: CreateCompanyIntroductionDto
) {
  return adminApi.post(PATHS.ROOT, createCompanyIntroductionDto);
}

export function updateCompanyIntroduction(
  updateCompanyIntroductionDto: UpdateCompanyIntroductionDto
) {
  const { id } = updateCompanyIntroductionDto;
  return adminApi.patch(PATHS.getOneById(id), updateCompanyIntroductionDto);
}

export function deleteCompanyIntroduction(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllCompanyIntroductionsByAdmin(
  paginationDto: PaginationDto
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}
