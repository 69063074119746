import { map } from 'lodash';
import { Company } from '../api/company/type';

interface PartTimeJobAskProps {
  company: Company;
}
export const PartTimeJobAsk = ({ company }: PartTimeJobAskProps) => {
  const {
    contactPerson,
    email,
    hompageUrl,
    phone,
    customerService,
    wayToCome,
  } = company;

  const info = [
    '담당자',
    '이메일',
    '홈페이지',
    '연락처',
    '문의전화',
    '오시는 길',
  ];
  const infoData = [
    contactPerson,
    email,
    hompageUrl,
    phone,
    customerService,
    wayToCome,
  ];
  return (
    <>
      <div className="mt-12">
        <h4>문의처</h4>
        <div className="flex space-x-8 pt-6">
          <div className="flex flex-none flex-col space-y-3.5 text-gray-700">
            {map(info, (data: string, i) => (
              <div key={i} className="flex items-center space-x-1">
                <p>{data}</p>
              </div>
            ))}
          </div>
          <div className="flex flex-col space-y-3.5">
            {map(infoData, (data, i) =>
              data ? <p key={i}>{data}</p> : <p key={i}> - </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
