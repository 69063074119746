interface SearchPartTimeJobProps {
  searchData: string;
  setSearchData: (data: string) => void;
  placeholder?: string;
  type?: string;
  label?: string;
}

export const SearchPartTimeJob = ({
  searchData,
  setSearchData,
  placeholder = '',
  type = 'text',
  label = '',
}: SearchPartTimeJobProps) => {
  return (
    <>
      <div className="flex flex-col justify-start space-x-2">
        <input
          className="textfield"
          placeholder={placeholder}
          value={searchData}
          onChange={(e) => setSearchData(e.target.value)}
          type={type}
        />
      </div>
    </>
  );
};
