import { Button } from '../Button';

export const FirstSection = () => {
  return (
    <div className="relative flex h-[700px] items-center justify-center overflow-hidden md:h-[800px]">
      <video
        autoPlay
        loop
        muted
        playsInline
        className="absolute inset-0 -z-10 min-h-full w-auto min-w-full max-w-none"
      >
        <source
          src="https://the-scv.s3.ap-northeast-2.amazonaws.com//8m.mp4"
          type="video/mp4"
        />
      </video>

      <div className="mx-auto w-full max-w-screen-xl space-y-5 px-5 md:space-y-40">
        <div className="space-y-14">
          <div className="space-y-5">
            <p className="text-[26px] font-bold text-white md:text-[40px]">
              신뢰할 수 있는 가치를 보여주다
            </p>
            <p className="text-[14px] font-medium text-white md:text-[16px]">
              The SCV는 고객사의 입장에서 소통하며 <br className="md:hidden" />
              신뢰를 바탕으로 행동합니다.
              <br />
              저희와 같이 해주는 모든 근로자를 인재로서 존중하며 {``}
              <br className="md:hidden" />
              발전할 수 있도록 노력합니다.
            </p>
          </div>
          <Button
            text="자세히보기"
            className="border border-white bg-white/10 px-8 text-[12px] font-medium text-white md:px-10 md:text-[15px]"
            onClick={() =>
              document.getElementById('section2')?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              })
            }
          />
        </div>

        <p className="text-[13px] font-medium text-white/70 md:text-[16px]">
          Trusted by big brands
        </p>
      </div>
    </div>
  );
};

export default { FirstSection };
