import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { TextField } from '../../../components/TextField';
import FroalaEditor from '../../../components/froala/FroalaEditor';
import { useCreateNoticeMutation } from '../../../hooks/notice';
import AdminH1 from '../../components/AdminH1';

export const NoticeAdd = () => {
  const navigate = useNavigate();
  const { mutate: createNoticeMutate } = useCreateNoticeMutation(() =>
    navigate(-1)
  );
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const onAdd = () => {
    if (!title) {
      toast.error('필수값을 입력해주세요.');
    } else {
      createNoticeMutate({
        title,
        description,
      });
    }
  };

  return (
    <>
      <div className="py-2">
        <AdminH1>공지사항 추가</AdminH1>
      </div>
      <div className="space-y-5 border-b pb-5">
        <div className="space-y-1">
          <TextField
            compulsory
            label="제목"
            className="text-24"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
      </div>
      <Label text="공지사항 상세" compulsory />
      <FroalaEditor
        defaultValue={''}
        onChange={(model) => {
          setDescription(model);
        }}
      />

      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          text="Cancle"
          onClick={() => navigate(-1)}
          className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
        />
        <Button
          text="Save"
          className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
          onClick={onAdd}
        />
      </div>
    </>
  );
};
