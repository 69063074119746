import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './paths';
import { CreateResumeDto, UpdateResumeDto } from './type';

export function createResume(createResumeDto: CreateResumeDto) {
  return api.post(PATHS.ROOT, createResumeDto);
}

export function updateResume(updateResumeDto: UpdateResumeDto) {
  return api.patch(PATHS.ME, updateResumeDto);
}

export function updateResumeByAdmin(updateResumeDto: UpdateResumeDto) {
  const { id } = updateResumeDto;
  return adminApi.patch(PATHS.getOneById(id), updateResumeDto);
}
