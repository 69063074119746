import { forwardRef, HTMLAttributes, useId } from 'react';
import { join, map, split, upperCase } from 'lodash';
import { downloadFile } from '../utils';
import { Button } from './Button';
import { Icon } from './Icon';
import { Label } from './Label';
import { useModal } from './modal/Modal';

export interface FileUploadProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  desc?: string;
  labelClassname?: string;
  helper?: string;
  accept?: string[];
  compulsory?: boolean;
  src?: string;
  config?: any;
  resetValue?: () => void;
  fileName?: string;
  isValueFile?: boolean;
  disabled?: boolean;
  hasFormFile?: boolean;
}

export const FileUpload = forwardRef<HTMLDivElement, FileUploadProps>(
  ({
    compulsory,
    label,
    desc,
    labelClassname = '',
    helper,
    accept,
    src = '',
    config = {},
    resetValue = () => {},
    fileName = '',
    isValueFile = false,
    disabled,
    hasFormFile,
  }) => {
    const id = useId();
    const { deleteFile } = useModal();

    return (
      <>
        <div className={`${src ? `col-span-2` : ``} label-col`}>
          <div className="flex flex-col space-y-2">
            <div className="flex items-center justify-between">
              <div className="flex flex-col space-y-2">
                {label && (
                  <Label
                    text={label}
                    className={`${labelClassname} font-medium text-gray-700	`}
                    compulsory={compulsory}
                  />
                )}
                <p className="text-13 text-gray-700">{desc && <p>{desc}</p>}</p>
              </div>

              {hasFormFile && (
                <div className="flex items-center space-x-2 text-13 text-gray-700">
                  <span>양식 :</span>
                  <a
                    className="flex cursor-pointer items-center space-x-2 rounded-md border border-gray-300 px-2 py-1"
                    href="https://the-scv.s3.ap-northeast-2.amazonaws.com/assets/parental_consent_form.jpeg"
                    target="_blank"
                    rel="noreferrer"
                    download={'부모님 동의서 양식'}
                  >
                    <Icon.File className="text-gray-600 " />
                    <span>다운로드</span>
                  </a>
                </div>
              )}
            </div>
            {src ? (
              <div
                className={`grid h-20 cursor-pointer place-items-center rounded-md border md:w-full`}
              >
                <div className="w-full items-center justify-between px-4 md:flex md:px-8 ">
                  <div className="flex space-x-2 ">
                    <Icon.File className="text-gray-600 " />
                    <div className="text-10 md:text-16">{fileName}</div>
                  </div>
                  <div className="flex items-center space-x-2 py-1 md:py-0 ">
                    {!isValueFile && (
                      <Button
                        type="button"
                        text={'다운'}
                        className="h-10 w-24 bg-green-50 font-bold text-green-500 md:w-max"
                        onClick={() => downloadFile(src)}
                      />
                    )}
                    {!disabled && (
                      <>
                        <label htmlFor={id}>
                          <div className="button flex h-10 cursor-pointer items-center justify-center bg-blue-50 px-8 text-15 font-bold text-blue-500">
                            수정
                          </div>
                        </label>
                        <Button
                          type="button"
                          text={'삭제'}
                          className="h-10 bg-red-50 text-red-500"
                          onClick={() => deleteFile(() => resetValue())}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <label htmlFor={id} className="w-min">
                <div
                  className={` grid h-20 w-64 cursor-pointer place-items-center rounded-md border`}
                >
                  <div className="flex place-items-center space-x-2 text-gray-600	">
                    <Icon.Upload className="wh-5" />
                    <div className="w-28">
                      <div className="">업로드 (최대 1개)</div>
                      <p className="break-words text-12 text-gray-500">
                        {join(
                          map(accept, (v) => upperCase(split(v, '.')[1])),
                          ','
                        )}
                        가능
                      </p>
                    </div>
                  </div>
                </div>
              </label>
            )}
            {!disabled && (
              <input
                {...config}
                type="file"
                accept={accept}
                id={id}
                className="hidden"
              />
            )}
          </div>

          {helper && (
            <p className="text-error text-sm text-red-500">{helper}</p>
          )}
        </div>
      </>
    );
  }
);
