import { useState } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_LG } from '../../../constants';
import { useAllPartTimeJobsByAdmin } from '../../../hooks/part-time-job';
import AdminH1 from '../../components/AdminH1';

export const PartTimeJobList = () => {
  const [page, setPage] = useState(1);
  const { data: allPartTimeJobsByAdmin } = useAllPartTimeJobsByAdmin({
    page,
    itemsPerPage: ITEMS_PER_PAGE_LG,
  });

  return (
    <>
      <div className="flex items-center justify-between py-2 px-8">
        <AdminH1>알바 신청자 관리</AdminH1>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>회사명</Table.Th>
              <Table.Th>공고명</Table.Th>
              <Table.Th>모집인원</Table.Th>
              <Table.Th>모집내용</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allPartTimeJobsByAdmin &&
              map(allPartTimeJobsByAdmin.items, (job) => (
                <Table.Row key={job.id}>
                  <Table.Td>{job.id}</Table.Td>
                  <Table.Td>{job.company.companyName}</Table.Td>
                  <Table.Td>{job.title}</Table.Td>
                  <Table.Td>{job.recruitNumber}</Table.Td>
                  <Table.Td>{job.recruitDescription}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/part-time-job/${job.id}/manage`}
                    >
                      신청자 관리
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allPartTimeJobsByAdmin?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_LG}
          setPage={setPage}
          totalItemCount={
            allPartTimeJobsByAdmin?.pagination.totalItemCount || 0
          }
          page={page}
        />
      )}
    </>
  );
};
