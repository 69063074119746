import { Swiper, SwiperSlide } from 'swiper/react';
import Image1 from '../../assets/img/section2-img-1.jpg';
import Image2 from '../../assets/img/section2-img-2.jpg';
import Image3 from '../../assets/img/section2-img-3.jpg';
import Image4 from '../../assets/img/section2-img-4.jpg';
import { FirstSectionImgCard } from './FirstSectionImgCard';

import 'swiper/css';
import 'swiper/css/pagination';

const IMG_DATA = [
  {
    id: 1,
    img: Image1,
    title: '뛰어난 인재 확보',
    content:
      '전문적이고 능력이 뛰난 인재, 책임감 강하고 성실한 인재를 확보하여, 언제든지 고객기업에 담당자로서 인원 충원 및 교육, 업무를 진행할 수  있도록 준비하고 있습니다.',
  },
  {
    id: 2,
    img: Image2,
    title: '인재 교육 & 육성',
    content:
      '교육기관을 구축해 교육 담당자를 배정하여, 전문적인 인재 교육 및 육성을 진행 및 보완중이며, 책임감있고 성실한 인력 확보에 힘쓰고 있습니다.',
  },
  {
    id: 3,
    img: Image3,
    title: '파트너쉽 강화',
    content: `고객 기업마다 확실한 적임자를 배정으로\n해당 기업에 맞는 전문적이고 책임감있는 인재를 육성하여 기업 발전과 이익을 위해 노력하고 있습니다.`,
  },
  {
    id: 4,
    img: Image4,
    title: '소통 중시',
    content:
      '업무 진행에 필요한 업무 내용을 고객사 담당자와의 교류와 당사 직원간의 소통을 통해 업무 효율 증가를 중시하고 있습니다.',
  },
];

export const SecondSection = () => (
  <div
    className="mx-auto w-full max-w-screen-xl space-y-5 px-5 md:space-y-10"
    id="section2"
  >
    <p className="text-[26px] font-semibold md:text-[36px]">OUR MISSION</p>

    <Swiper
      slidesPerView="auto"
      spaceBetween={20}
      slidesOffsetBefore={0}
      slidesOffsetAfter={0}
      freeMode
    >
      {IMG_DATA.map((i) => (
        <SwiperSlide key={i.id} className="w-fit">
          <FirstSectionImgCard
            img={i.img}
            title={i.title}
            content={i.content}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
);

export default { SecondSection };
