import { useState } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllCompanyIntroductionsByAdmin } from '../../../hooks/company-introduction';
import { utcToLocalFormat, MomentFormat } from '../../../plugins/moment';
import { sortDescString } from '../../../utils';
import AdminH1 from '../../components/AdminH1';

export const CompanyIntro = () => {
  const [page, setPage] = useState(1);
  const { data: allCompanyIntroductionsByAdmin } =
    useAllCompanyIntroductionsByAdmin({
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
      sort: sortDescString,
    });

  return (
    <>
      <div className="flex items-center justify-between py-2 px-8">
        <AdminH1>회사 소개</AdminH1>
        <Button
          className="w-30 h-12 bg-gray-800 text-sm text-white shadow-sm "
          to="/admin/introduction/company/add"
        >
          추가하기
        </Button>
      </div>

      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>작성일</Table.Th>
              <Table.Th>제목</Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allCompanyIntroductionsByAdmin &&
              map(allCompanyIntroductionsByAdmin.items, (companyIntro) => (
                <Table.Row key={companyIntro.id}>
                  <Table.Td>{companyIntro.id}</Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(
                      companyIntro.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDD
                    )}
                  </Table.Td>
                  <Table.Td>{companyIntro.title}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/introduction/company/${companyIntro.id}`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allCompanyIntroductionsByAdmin?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={
            allCompanyIntroductionsByAdmin?.pagination.totalItemCount || 0
          }
          page={page}
        />
      )}
    </>
  );
};
