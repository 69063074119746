import React, { FC } from 'react';
import { Button } from '../Button';
import { AnimationLayout } from './AnimationLayout';

interface ContractRequiredModalProps {
  onClose: () => void;
  open: boolean;
}

export const ContractRequiredModal: FC<ContractRequiredModalProps> = ({
  onClose,
  open,
}) => {
  return (
    <>
      <AnimationLayout open={open} onClose={onClose}>
        <div className="my-8 w-full max-w-md transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          <h3 className="font-semibold">필수 입력사항 확인</h3>
          <p className="whitespace-pre-wrap font-medium text-gray-700">
            근무장소, 계약일시, 근무시간, 급여구분, 기본시급, 확인동의는 <br />
            필수로 작성해야 합니다.
          </p>

          <div className="space-y-3 pt-8">
            <Button
              onClick={onClose}
              text="계약서 마저 작성하기"
              className=" w-full bg-brand-1 text-white"
            />
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
