import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  approveApplications,
  createApplication,
  getAllApplicationByUserId,
  getAllApplicationsByDateId,
  getAllMyApplications,
  notWorkApplications,
  updateApplication,
  withdrawApplications,
  workApplications,
} from '../../api/application';
import { PATHS } from '../../api/application/paths';
import {
  Application,
  CreateApplicationDto,
  UpdateApplicationDto,
} from '../../api/application/type';
import { fetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllMyApplications(paginationDto: PaginationDto) {
  return useQuery<Paginated<Application>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllMyApplications(paginationDto),
    { keepPreviousData: true }
  );
}

export function useAllApplicationByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  return useQuery<Paginated<Application>, AxiosError>(
    [PATHS.userId(userId), paginationDto],
    () => getAllApplicationByUserId(paginationDto, userId),
    {
      keepPreviousData: true,
    }
  );
}

export function useAllApplicationByPTJId(partTimeJobId: number) {
  return useQuery<Application[], AxiosError>(
    PATHS.partTimeJobId(partTimeJobId),
    fetcher,
    { enabled: !!partTimeJobId }
  );
}

export function useAllApplicationAdminByPTJId(
  paginationDto: PaginationDto,
  partTimeJobId: number
) {
  return useQuery<Paginated<Application>, AxiosError>(
    [PATHS.partTimeJobId(partTimeJobId), paginationDto],
    () => getAllApplicationsByDateId(paginationDto, partTimeJobId),
    { enabled: !!partTimeJobId }
  );
}

export function useAllApplicationsByDateId(
  paginationDto: PaginationDto,
  partTimeJobId: number
) {
  return useQuery<Paginated<Application>, AxiosError>(
    [PATHS.partTimeJobId(partTimeJobId), paginationDto],
    () => getAllApplicationsByDateId(paginationDto, partTimeJobId),
    { keepPreviousData: true, enabled: !!partTimeJobId }
  );
}

export const useCreateApplicationMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createApplicationDto: CreateApplicationDto) =>
      createApplication(createApplicationDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('지원이 완료되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('지원에 실패했습니다.');
      },
    }
  );
};

export const useUpdateApplicationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateApplicationDto: UpdateApplicationDto) =>
      updateApplication(updateApplicationDto),
    {
      onSuccess: () => {
        toast.success('수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('실패했습니다.');
      },
    }
  );
};

export const useApproveApplications = (onSuccess?: () => void) => {
  return useMutation(
    (updateApplicationIds: number[]) =>
      approveApplications(updateApplicationIds),
    {
      onSuccess: (_) => {
        onSuccess && onSuccess();
        toast.success('알바 승인이 완료되었습니다.');
      },
      onError: () => {
        toast.error('알바 승인에 실패했습니다.');
      },
    }
  );
};

export const useWithdrawApplications = (onSuccess?: () => void) => {
  return useMutation(
    (updateApplicationIds: number[]) =>
      withdrawApplications(updateApplicationIds),
    {
      onSuccess: (_) => {
        onSuccess && onSuccess();
        toast.success('알바 승인 취소가 완료되었습니다.');
      },
      onError: () => {
        toast.error('알바 승인 취소에 실패했습니다.');
      },
    }
  );
};

export const useWorkApplications = (onSuccess?: () => void) => {
  return useMutation(
    (updateApplicationIds: number[]) => workApplications(updateApplicationIds),
    {
      onSuccess: (_) => {
        onSuccess && onSuccess();
        toast.success('출근 처리가 완료되었습니다.');
      },
      onError: () => {
        toast.error('출근 처리에 실패했습니다.');
      },
    }
  );
};

export const useNotWorkApplications = (onSuccess?: () => void) => {
  return useMutation(
    (updateApplicationIds: number[]) =>
      notWorkApplications(updateApplicationIds),
    {
      onSuccess: (_) => {
        onSuccess && onSuccess();
        toast.success('결근 처리가 완료되었습니다.');
      },
      onError: () => {
        toast.error('결근 처리에 실패했습니다.');
      },
    }
  );
};
