import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreateContractDto, UpdateContractDto } from './type';

export function createContract(createContractDto: CreateContractDto) {
  return api.post(PATHS.ROOT, createContractDto);
}

export function updateContract(updateContractDto: UpdateContractDto) {
  const { id } = updateContractDto;
  return adminApi.patch(PATHS.getOneById(id), updateContractDto);
}

export function deleteContract(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllContract(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAllContractByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}
export function getMyContract(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.ME}${queryString}`).then(({ data }) => data);
}

export function getAllContractByUserId(
  paginationDto: PaginationDto,
  userId: number
) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi
    .get(`${PATHS.userId(userId)}${queryString}`)
    .then(({ data }) => data);
}
