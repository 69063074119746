import { useState } from 'react';
import { format } from 'date-fns';
import { filter, map } from 'lodash';
import { useParams } from 'react-router-dom';
import { downloadPTJExcel } from '../../../api/application';
import {
  Alarm,
  Application,
  JobStatus,
  KOREAN_ALARM,
  KOREAN_JOB_STATUS,
} from '../../../api/application/type';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Checkbox } from '../../../components/Checkbox';
import { Icon } from '../../../components/Icon';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_LG } from '../../../constants';
import {
  useApproveApplications,
  useWithdrawApplications,
  useAllApplicationAdminByPTJId,
} from '../../../hooks/application';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';

export const PartTimeJobManageList = () => {
  const [page, setPage] = useState<number>(1);
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState<number[]>([]);
  const { id: partTimeJobId, day: clickedDay } = useParams<{
    id: string;
    day: string;
  }>();

  const {
    data: allApplications,
    refetch,
    isLoading,
  } = useAllApplicationAdminByPTJId(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_LG,
      ...(clickedDay ? { filter: { workDate: clickedDay } } : {}),
    },
    Number(partTimeJobId)
  );
  const _init = () => {
    setIsCheckAll(false);
    setIsCheck([]);
    refetch();
  };
  const { mutate: approveApplcationsMutate } = useApproveApplications(_init);
  const { mutate: withdrawApplcationsMutate } = useWithdrawApplications(_init);

  const handleSelectAll = () => {
    if (!allApplications) {
      return;
    }
    setIsCheckAll(!isCheckAll);
    setIsCheck(map(allApplications.items, (app) => app.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (
    app: Application,
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (!app || !event) {
      return;
    }

    const target = event.target as HTMLInputElement;
    const checked = target.checked;
    setIsCheck([...isCheck, app.id]);
    if (!checked) {
      setIsCheck(filter(isCheck, (item) => item !== app.id));
    }
  };

  return (
    <>
      <div className="flex items-center justify-end space-x-2 py-2 px-8">
        <Button
          className="shadow-xs w-30 h-12 bg-red-500 text-sm text-white"
          onClick={() => {
            if (isCheck.length === 0) {
              return;
            }
            withdrawApplcationsMutate(isCheck);
          }}
        >
          알바 취소하기
        </Button>
        <Button
          className="shadow-xs w-30 h-12 bg-gray-800 text-sm text-white"
          onClick={() => {
            if (isCheck.length === 0) {
              return;
            }
            approveApplcationsMutate(isCheck);
          }}
        >
          알바 승인하기
        </Button>
        <Button
          className="shadow-xs w-30 h-12 bg-green-700 text-sm text-white"
          onClick={() => {
            downloadPTJExcel(
              isCheck
                ? {
                    filter: {
                      ids: isCheck,
                    },
                  }
                : {}
            ).then((res) => {
              const url = URL.createObjectURL(res.data);
              const link = document.createElement('a');
              link.href = url;
              link.download = `아르바이트_신청자_관리_${format(
                new Date(),
                'yyyyMMddHHmmss'
              )}`;
              link.click();
              URL.revokeObjectURL(url);
            });
          }}
        >
          엑셀 다운로드
        </Button>
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>
                <Checkbox onClick={handleSelectAll} isChecked={isCheckAll} />
              </Table.Th>
              <Table.Th>이름</Table.Th>
              <Table.Th>지원서</Table.Th>
              <Table.Th>지원 시간</Table.Th>
              <Table.Th>주민번호</Table.Th>
              <Table.Th>누적 결근</Table.Th>
              <Table.Th>누적 지원</Table.Th>
              <Table.Th>휴대폰번호</Table.Th>
              <Table.Th>알림톡</Table.Th>
              <Table.Th>알바승인상태</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {isLoading && (
              <Table.Row aria-rowspan={11}>
                <div className="p-8 text-lg font-bold">LOADING...</div>
              </Table.Row>
            )}
            {!allApplications?.items?.length && !isLoading && (
              <Table.Row aria-rowspan={11}>
                <div className="whitespace-pre p-8 text-lg font-semibold">
                  해당 일자의 데이터가 없습니다.
                </div>
              </Table.Row>
            )}
            {allApplications &&
              map(allApplications.items, (app) => (
                <Table.Row key={app.id}>
                  <Table.Td>{app.id}</Table.Td>
                  <Table.Td>
                    <Checkbox
                      isChecked={isCheck.includes(app.id)}
                      onClick={(e) => {
                        handleClick(app, e);
                      }}
                    />
                  </Table.Td>
                  <Table.Td>{app.userName}</Table.Td>
                  <Table.Td>
                    <Icon.File
                      onClick={() =>
                        window.open(
                          `/admin/applied-resume/${app.appliedResumeId}`,
                          '_blank',
                          'noopener'
                        )
                      }
                      className=" w-min cursor-pointer text-gray-500"
                    />
                  </Table.Td>
                  <Table.Td>
                    {utcToLocalFormat(
                      app.partTimeJobDate.workingStartAt.toLocaleString(),
                      MomentFormat.HHmm
                    )}{' '}
                    ~{' '}
                    {utcToLocalFormat(
                      app.partTimeJobDate.workingEndAt.toLocaleString(),
                      MomentFormat.HHmm
                    )}
                  </Table.Td>
                  <Table.Td>{app.userPersonalId}</Table.Td>
                  <Table.Td>{app.user?.notWorkCount || 0}</Table.Td>
                  <Table.Td>{app.user?.applicationCount || 0}</Table.Td>
                  <Table.Td>{app.userPhone}</Table.Td>
                  <Table.Td
                    className={`${
                      app.alarm === Alarm.DELIVERED
                        ? `text-blue-500`
                        : `text-red-500`
                    } font-medium`}
                  >
                    {KOREAN_ALARM[app.alarm]}
                  </Table.Td>

                  <Table.Td
                    className={`${
                      app.jobStatus === JobStatus.APPROVED
                        ? `text-blue-500`
                        : `text-red-500`
                    } font-medium`}
                  >
                    {KOREAN_JOB_STATUS[app.jobStatus]}
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allApplications && allApplications?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_LG}
          setPage={setPage}
          totalItemCount={allApplications?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
