import { useState } from 'react';
import { format } from 'date-fns';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import {
  KOREAN_ATTENDANCE,
  KOREAN_JOB_STATUS,
} from '../../../api/application/type';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllApplicationByUserId } from '../../../hooks/application';

export const ApplicationList = () => {
  const { id: userId } = useParams<{ id: string }>();
  const [page, setPage] = useState(1);
  const { data: allApplications } = useAllApplicationByUserId(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
    },
    userId ? +userId : 0
  );

  return (
    <>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>근무지</Table.Th>
              <Table.Th>모집내용</Table.Th>
              <Table.Th>근무일</Table.Th>
              <Table.Th>근무시간</Table.Th>
              <Table.Th>신청일</Table.Th>
              <Table.Th>알바승인 상태</Table.Th>
              <Table.Th>출근여부</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allApplications &&
              map(allApplications.items, (application) => {
                const { startWorkTime, endWorkTime } = application;

                return (
                  <Table.Row key={application.id}>
                    <Table.Td>{application.id}</Table.Td>
                    <Table.Td>{application.companyName}</Table.Td>
                    <Table.Td>{application.recruitDescription}</Table.Td>
                    <Table.Td>
                      {format(new Date(application.workDate), 'yyyy.MM.dd')}
                    </Table.Td>
                    <Table.Td>{`${format(
                      new Date(startWorkTime),
                      'HH:mm'
                    )} ~ ${format(new Date(endWorkTime), 'HH:mm')}`}</Table.Td>
                    <Table.Td>
                      {format(new Date(application.appliedDate), 'yyyy.MM.dd')}
                    </Table.Td>
                    <Table.Td
                      className={`${
                        application.jobStatus === 'PENDING'
                          ? 'text-red-500'
                          : 'text-blue-500'
                      }`}
                    >
                      {KOREAN_JOB_STATUS[application.jobStatus]}
                    </Table.Td>
                    <Table.Td
                      className={`${
                        application.attendance === 'WORK' && 'text-blue-500'
                      } ${
                        application.attendance === 'NOT_WORK' && 'text-red-500'
                      }`}
                    >
                      {KOREAN_ATTENDANCE[application.attendance]}
                    </Table.Td>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Card>
      {allApplications?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={allApplications?.pagination.totalItemCount || 0}
          page={page}
        />
      )}
    </>
  );
};
