import React from 'react';
import Section3Img1 from '../../assets/img/section3-img-1.png';
import Section3Img2 from '../../assets/img/section3-img-2.png';
import Section3Img3 from '../../assets/img/section3-img-3.png';
import { ThirdSectionImgCard } from './ThirdSectionImgCard';

const CARD_DATA = [
  {
    id: 1,
    title: '인력운영에 필요한 비용절감',
    content: `회사 내 전문인력을 체계적으로 운영하며\n고객사의 비용을 절감시켜드립니다.`,
    img: Section3Img1,
  },
  {
    id: 2,
    title: '업무 진행 능률 극대화',
    content: `장기적으로 전문인력을 투입하여 업무진행을 보다\n효율적이며 능률적으로 진행합니다.`,
    img: Section3Img2,
  },
  {
    id: 3,
    title: '신규 인원 업무 진행 능력 향상',
    content: `전문인력으로 신규 인원을 전담하여 교육 및 전문화를 \n빠르게 양성하여 업무 진행 능력을 향상시킵니다.`,
    img: Section3Img3,
  },
];

export const ThirdSection = () => {
  return (
    <div
      className="mx-auto w-full max-w-screen-xl space-y-5 px-5 md:space-y-10"
      id="section3"
    >
      <p className="text-[26px] font-semibold md:text-[36px]">OUR BUSINESS</p>

      <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3">
        {CARD_DATA.map((card) => (
          <ThirdSectionImgCard
            key={card.id}
            title={card.title}
            content={card.content}
            img={card.img}
          />
        ))}
      </div>
    </div>
  );
};

export default { ThirdSection };
