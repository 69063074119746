import React, { FC } from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { useAuth } from '../../hooks';
import { Icon } from '../Icon';
import { HamburgerButton } from './HamburgerButton';

interface TopNavBarProps {
  isWhite?: boolean;
}

export const TopNavBar: FC<TopNavBarProps> = ({ isWhite = false }) => {
  const [isTop, setIsTop] = useState(true);
  const [dropdown, setDropdown] = useState(false);
  const { authenticated, logout } = useAuth();
  const handleScroll = () => {
    if (!window) return;

    if (window.scrollY === 0) {
      setIsTop(true);
    } else if (isTop) {
      setIsTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    // 모바일 화면에서 dropdown이 열려있을 때 스크롤 방지
    if (dropdown) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [dropdown]);

  return (
    <div id="topNavBar" className="relative">
      <div
        className={twMerge(
          'fixed inset-x-0 top-0 z-30 hidden h-[80px] w-full py-[20px] px-5 md:block',
          isTop ? 'bg-white/10' : 'border-b border-gray-100 bg-white',
          isWhite && 'border-b border-gray-100 bg-white'
        )}
      >
        <div className="mx-auto flex w-full max-w-screen-xl items-center justify-between">
          <Link to="/">
            <Icon.Logo
              className={twMerge(
                isTop ? 'fill-white stroke-white' : 'fill-black',
                isWhite && 'fill-black stroke-black'
              )}
            />
          </Link>

          <div
            className={twMerge(
              'text-[16px] font-semibold md:block',
              isTop ? 'text-white' : 'text-black',
              isWhite && 'text-black'
            )}
          >
            <div className="flex items-center space-x-8">
              <a href="/part-time-job">알바공고</a>
              {authenticated ? (
                <>
                  <a href="/mypage">마이페이지</a>
                  <button
                    className={`rounded-md border py-2 px-3 text-[14px] hover:bg-littleblack ${
                      isWhite ? 'border-black' : 'border-white'
                    }`}
                    onClick={logout}
                  >
                    로그아웃
                  </button>
                </>
              ) : (
                <a
                  href="/login"
                  className="rounded-md bg-black py-2 px-3 text-[14px] text-white hover:bg-gray-800"
                >
                  로그인
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* 모바일 탑바 */}
      <div className="relative">
        <div
          className={twMerge(
            'fixed inset-x-0 top-0 z-30 flex w-full items-center justify-between px-5 py-5 md:hidden',
            isTop ? 'bg-white/10' : 'border-b border-gray-100 bg-white',
            (dropdown || isWhite) && 'border-b border-gray-100 bg-white'
          )}
        >
          <Link to="/">
            <Icon.Logo
              className={twMerge(
                'w-20',
                isTop ? 'fill-white stroke-white' : 'fill-black',
                (dropdown || isWhite) && 'fill-black stroke-black'
              )}
            />
          </Link>

          <HamburgerButton
            className={twMerge(
              'absolute right-5 z-30',
              isTop ? 'stroke-white' : 'stroke-black'
            )}
            isWhite={isWhite}
            open={dropdown}
            setOpen={() => {
              setDropdown(!dropdown);
            }}
          />
        </div>
      </div>

      <div className="h-fit md:hidden">
        <div
          className={`${
            dropdown ? 'block' : 'hidden'
          } fixed top-[4.3rem] z-50 h-full w-full bg-black opacity-60`}
          onClick={() => {
            setDropdown(!dropdown);
          }}
        />
        <div
          className={`${
            dropdown
              ? 'h-screen opacity-100 duration-500 ease-in-out'
              : 'duration-250 pointer-events-none opacity-0 ease-in'
          } fixed top-[3.7rem] right-0 z-50 flex h-fit w-full flex-col bg-white px-5`}
        >
          <div className="">
            <div className="flex flex-col divide-y divide-gray-200 text-center text-[16px] font-semibold">
              <button
                className="py-4"
                onClick={() => {
                  document.getElementById('section2')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                  });
                  setDropdown(false);
                }}
              >
                Mission
              </button>

              <button
                className="py-4"
                onClick={() => {
                  document.getElementById('section3')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                  setDropdown(false);
                }}
              >
                Business
              </button>

              <button
                className="py-4"
                onClick={() => {
                  document.getElementById('section4')?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'nearest',
                  });
                  setDropdown(false);
                }}
              >
                History
              </button>

              <a href="/part-time-job" className="py-4">
                알바공고
              </a>

              {authenticated ? (
                <>
                  <a href="/mypage" className="py-4">
                    마이페이지
                  </a>
                  <div className="flex justify-end py-4">
                    <button
                      className={`rounded-md border py-2 px-3 text-[14px] hover:bg-littleblack ${
                        isWhite ? 'border-black' : 'border-white'
                      }`}
                      onClick={logout}
                    >
                      로그아웃
                    </button>
                  </div>
                </>
              ) : (
                <div className="flex justify-end py-4">
                  <a
                    href="/login"
                    className="rounded-md bg-black py-2 px-3 text-[14px] text-white hover:bg-gray-800"
                  >
                    로그인
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
