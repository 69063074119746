import React, { useEffect } from 'react';
import { useState } from 'react';
import { format, getMonth, setMonth } from 'date-fns';
import { find, includes } from 'lodash';
import moment from 'moment';
import Calendar from 'react-calendar';
import { useNavigate, useParams } from 'react-router-dom';
import { PartTimeJobDate } from '../../../api/part-time-job-date/type';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { MobileTopBar } from '../../../components/MobileTopBar';
import { SelectInfo } from '../../../components/SelectInfo';
import { TimeSelector } from '../../../components/TimeSelector';
import { PartTimeApplyModal } from '../../../components/modal/PartTimeApplyModal';
import { useAllApplicationByPTJId } from '../../../hooks/application';
import { usePartTimeJobById } from '../../../hooks/part-time-job';

export const PartTimeJobApplyPage = () => {
  const { id: partTimeJobId } = useParams<{ id: string }>();
  const [showpartTimeModal, setShowpartTimeModal] = useState(false);
  const [clickedDay, setClickedDay] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [workDates, setWorkDates] = useState<PartTimeJobDate[]>([]);
  const [isApplied, setIsApplied] = useState<boolean>(false);

  const [selectedTime, setSelectedTime] = useState<
    PartTimeJobDate | undefined
  >();
  const navigate = useNavigate();
  const { data: ptj } = usePartTimeJobById(partTimeJobId ? +partTimeJobId : 0);
  const { data: myApplications } = useAllApplicationByPTJId(
    partTimeJobId ? +partTimeJobId : 0
  );

  const isIncludesDate = (
    dates: Record<string, PartTimeJobDate[]>,
    date: Date
  ) => {
    if (includes(Object.keys(dates), format(date, 'yyyy-MM-dd'))) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!ptj || !clickedDay) {
      return;
    }

    setWorkDates(
      ptj.partTimeJobDates[
        format(clickedDay ? clickedDay : new Date(), 'yyyy-MM-dd')
      ]
    );
    setSelectedTime(undefined);
  }, [setWorkDates, ptj, clickedDay]);

  useEffect(() => {
    if (!clickedDay || !selectedTime || !myApplications) {
      return;
    }
    const findDate = find(myApplications, (app) => {
      return app.partTimeJobDateId === selectedTime.id;
    });

    if (findDate) {
      setIsApplied(true);
    } else {
      setIsApplied(false);
    }
  }, [myApplications, setIsApplied, selectedTime]);

  if (!ptj) {
    return <></>;
  }

  return (
    <>
      <PartTimeApplyModal
        open={showpartTimeModal}
        onClose={() => setShowpartTimeModal(false)}
        title={ptj.title}
        recruitDescription={ptj.recruitDescription}
        company={ptj.company}
        clickedDay={clickedDay}
        selectedTime={selectedTime}
        partTimeJobId={ptj.id}
      />

      <MobileTopBar title="지원하기" />
      <div className="grid place-items-center">
        <div className="mt-32 w-full max-w-3xl gap-5 px-5 md:px-0 ">
          <div className="">
            <button
              className="hidden items-center space-x-2 md:flex"
              onClick={() => navigate(-1)}
            >
              <Icon.ArrowLeft />
            </button>
            <div className="border-b pt-4 pb-8 md:text-center">
              <div className="space-y-2">
                <h3 className="font-medium">{ptj.title}</h3>
                <h5>
                  <span className="text-gray-700">모집내용 | </span>
                  <span>{ptj.recruitDescription}</span>
                </h5>
                <p className="font-medium">{ptj.company.companyName}</p>
              </div>
            </div>
          </div>
          <div className="mt-10 grid grid-cols-1 gap-10 md:grid-cols-2">
            <div>
              <p className="text-gray-700">날짜 선택</p>
              <div className="mt-2">
                <div className="rounded-[10px] bg-sub-3">
                  <Calendar
                    className="text-md mx-auto flex w-full flex-col items-center justify-between space-y-3 pt-5 text-center"
                    activeStartDate={startDate}
                    formatDay={(locale, date) => moment(date).format('D')}
                    onClickDay={(value) => {
                      setClickedDay(new Date(value));
                    }}
                    onActiveStartDateChange={({ action }) => {
                      const currentMonth = getMonth(startDate);
                      const nextMonth = setMonth(startDate, currentMonth + 1);
                      const prevMonth = setMonth(startDate, currentMonth - 1);
                      if (action === 'next') {
                        setStartDate(nextMonth);
                      } else if (action === 'prev') {
                        setStartDate(prevMonth);
                      }
                    }}
                    tileContent={({ date }) => {
                      const currentDate = new Date();
                      const isToday =
                        format(date, 'yyyy-MM-dd') ===
                        format(currentDate, 'yyyy-MM-dd');
                      if (isToday) {
                        return (
                          <div
                            className={`absolute -top-[2.7px] left-[42.7%] ${
                              isToday && 'text-sub-1'
                            }`}
                          >
                            •
                          </div>
                        );
                      } else {
                        return null;
                      }
                    }}
                    defaultView="month"
                    minDetail="month"
                    maxDetail="month"
                    next2Label={null}
                    prev2Label={null}
                    tileClassName={({ date }) => {
                      if (!isIncludesDate(ptj.partTimeJobDates, date)) {
                        return 'text-gray-400';
                      }
                      if (
                        format(new Date(), 'yyyy-MM-dd') >
                        format(date, 'yyyy-MM-dd')
                      ) {
                        return 'text-gray-400';
                      }
                      const dateString = date.toDateString();
                      const clickedDayString = clickedDay?.toDateString();

                      if (dateString === clickedDayString) {
                        return '';
                      }

                      return 'text-bold';
                    }}
                    value={clickedDay}
                    tileDisabled={({ date }) => {
                      if (!isIncludesDate(ptj.partTimeJobDates, date)) {
                        return true;
                      }

                      if (
                        format(new Date(), 'yyyy-MM-dd') >
                        format(date, 'yyyy-MM-dd')
                      ) {
                        return true;
                      }
                      return false;
                    }}
                  />
                </div>
              </div>
            </div>
            {workDates.length !== 0 ? (
              <div>
                <p className="text-gray-700">시간 선택</p>
                <TimeSelector
                  workDates={workDates}
                  selectedTime={selectedTime}
                  setSelectedTime={(time) => setSelectedTime(time)}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center rounded-md bg-sub-3">
                날짜를 먼저 선택해 주세요.
              </div>
            )}
          </div>
          <div className="mt-10 space-y-6">
            <SelectInfo
              company={ptj.company}
              workDate={clickedDay}
              time={selectedTime}
            />
            <Button
              text={`${isApplied ? '지원완료' : '지원하기'}`}
              className="w-full bg-brand-1 text-white"
              disabled={!selectedTime || !clickedDay || isApplied}
              onClick={() => setShowpartTimeModal(true)}
            />
          </div>
        </div>
      </div>
    </>
  );
};
