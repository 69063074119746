import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCompanyIntroduction,
  deleteCompanyIntroduction,
  getAllCompanyIntroductionsByAdmin,
  updateCompanyIntroduction,
} from '../../api/company-introduction';
import { PATHS } from '../../api/company-introduction/paths';
import {
  CompanyIntroduction,
  CreateCompanyIntroductionDto,
  UpdateCompanyIntroductionDto,
} from '../../api/company-introduction/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllCompanyIntroductions() {
  return useQuery<CompanyIntroduction[], AxiosError>(PATHS.ROOT, fetcher, {
    keepPreviousData: true,
  });
}

export function useAllCompanyIntroductionsByAdmin(
  paginationDto: PaginationDto
) {
  return useQuery<Paginated<CompanyIntroduction>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllCompanyIntroductionsByAdmin(paginationDto),
    { keepPreviousData: true }
  );
}

export function useCompanyIntroductionById(id: number) {
  return useQuery<CompanyIntroduction, AxiosError>(
    PATHS.getOneById(id),
    fetcher,
    { enabled: !!id }
  );
}

export function useCompanyIntroductionByAdminId(id: number) {
  return useQuery<CompanyIntroduction, AxiosError>(
    PATHS.getOneById(id),
    adminFetcher,
    { enabled: !!id }
  );
}

export const useCreateCompanyIntroductionMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCompanyIntroductionDto: CreateCompanyIntroductionDto) =>
      createCompanyIntroduction(createCompanyIntroductionDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('회사소개 추가 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('회사소개 추가 실패');
      },
    }
  );
};

export const useUpdateCompanyIntroductionMutation = (
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCompanyIntroductionDto: UpdateCompanyIntroductionDto) =>
      updateCompanyIntroduction(updateCompanyIntroductionDto),
    {
      onSuccess: (_, updateCompanyIntroductionDto) => {
        onSuccess && onSuccess();
        toast.success('회사소개 수정 성공');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getOneById(updateCompanyIntroductionDto.id)
        );
      },
      onError: () => {
        toast.error('회사소개 수정 실패');
      },
    }
  );
};

export const useDeleteCompanyIntroductionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCompanyIntroduction(id), {
    onSuccess: (_, id) => {
      toast.success('회사소개 삭제 성공');
      queryClient.invalidateQueries(PATHS.ROOT);
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('회사소개 삭제 실패');
    },
  });
};
