import { useEffect, useId, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../api/file';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/Label';
import { TextArea } from '../../../components/TextArea';
import { TextField } from '../../../components/TextField';
import { useCreateCompanyIntroductionMutation } from '../../../hooks/company-introduction';
import AdminH1 from '../../components/AdminH1';

export const CompanyIntroAdd = () => {
  const navigate = useNavigate();
  const id = useId();
  const { mutate: createCompanyIntroductionMutate } =
    useCreateCompanyIntroductionMutation(() => navigate(-1));
  const [title, setTitle] = useState('');
  const [image, setImage] = useState<FileList>();
  const [description, setDescription] = useState('');
  const [src, setSrc] = useState<string>('');

  const onAdd = async () => {
    if (!title || !image || image.length === 0) {
      toast.error('필수값을 입력해주세요.');
      return;
    }

    let imageUrl = '';

    if (image instanceof FileList && image[0]) {
      await uploadFile(image[0]).then(
        (res: AxiosResponse<string>) => (imageUrl = res.data)
      );
    } else if (typeof image === 'string') {
      imageUrl = image;
    }

    createCompanyIntroductionMutate({
      title,
      image: imageUrl,
      description,
    });
  };

  useEffect(() => {
    if (!image || image.length === 0) {
      return;
    }
    if (image instanceof FileList) {
      setSrc(image[0] && URL.createObjectURL(image[0]));
    }
  }, [image]);

  return (
    <>
      <div className="mx-10 space-y-4 ">
        <div className="py-2">
          <AdminH1>회사 소개 추가</AdminH1>
        </div>
        <TextField
          compulsory
          label="제목"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Label compulsory>배경 이미지</Label>
        <div className="aspect-w-16 aspect-h-8 mt-1 grid w-full place-items-center rounded-md border">
          <label htmlFor={id} className="relative w-full">
            {src ? (
              <>
                <img
                  className="aspect-w-16 aspect-h-8 w-full rounded-md object-cover object-center"
                  src={src}
                  crossOrigin="anonymous"
                  alt=""
                />
                <div className="absolute -right-4 -bottom-4 cursor-pointer rounded-full bg-sub-1 p-2.5">
                  <Icon.EditPen className="wh-5 stroke-white" />
                </div>
              </>
            ) : (
              <div className="flex cursor-pointer flex-col items-center space-y-2 p-4">
                <div className="rounded-full bg-green-500 p-1.5">
                  <Icon.Plus className="wh-5 text-white" />
                </div>
                <p className="text-center text-14 text-gray-600">
                  이미지를
                  <br /> 업로드해주세요! (1장)
                </p>
                <p className="text-12 text-gray-600">PNG, JPG, JPEG 가능</p>
              </div>
            )}
            <input
              onChange={(e) => {
                const files = e.target.files;
                if (!files || files.length === 0) return;
                if (files.length === 0) return;
                setImage(files);
              }}
              type="file"
              accept=".png, .jpg, .jpeg"
              id={id}
              className="hidden"
            />
          </label>
        </div>

        <TextArea
          className=" h-48 w-full"
          label="소개내용 상세"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
          <Button
            text="Cancle"
            onClick={() => navigate(-1)}
            className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
          />
          <Button
            text="Save"
            className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
            onClick={onAdd}
          />
        </div>
      </div>
    </>
  );
};
