import { FC } from 'react';
import { map } from 'lodash';
import { PartTimeJobDate } from '../api/part-time-job-date/type';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { PartTimeCheck } from './PartTimeCheck';

export interface TimeSelectorProps {
  workDates: PartTimeJobDate[];
  setSelectedTime: (time: PartTimeJobDate) => void;
  selectedTime: PartTimeJobDate | undefined;
}

export const TimeSelector: FC<TimeSelectorProps> = ({
  workDates,
  setSelectedTime,
  selectedTime,
}) => {
  return (
    <div className="mt-2">
      <div className="grid grid-cols-1 divide-y rounded-md bg-sub-3">
        {map(workDates, (time) => {
          const { workingStartAt, workingEndAt } = time;
          return (
            <div key={time.id} className="flex items-center space-x-2 p-3 ">
              <PartTimeCheck
                setSelectedTime={(time) => setSelectedTime(time)}
                selectedTime={selectedTime}
                time={time}
              />
              <div>
                {utcToLocalFormat(
                  workingStartAt.toLocaleString(),
                  MomentFormat.HHmm
                )}{' '}
                ~{' '}
                {utcToLocalFormat(
                  workingEndAt.toLocaleString(),
                  MomentFormat.HHmm
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
