import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCareer,
  createCareerByAdmin,
  deleteCareer,
  deleteCareerByAdmin,
  updateCareer,
  updateCareerByAdmin,
} from '../../api/career';
import { PATHS } from '../../api/career/paths';
import {
  Career,
  CreateCareerDto,
  UpdateCareerDto,
} from '../../api/career/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';

export function useAllCareers(resumeId: string, isAdmin: boolean) {
  return useQuery<Career[], AxiosError>(
    `${PATHS.getAllByResumeId(+resumeId)}`,
    isAdmin ? adminFetcher : fetcher
  );
}

export const useCreateCareerMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCareerDto: CreateCareerDto) => createCareer(createCareerDto),
    {
      onSuccess: (_, createCareerDto) => {
        onSuccess && onSuccess();
        toast.success('경력사항이 저장되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+createCareerDto.resumeId)
        );
      },
      onError: () => {
        toast.error('경력사항 추가 실패');
      },
    }
  );
};

export const useCreateCareerAdminMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCareerDto: CreateCareerDto) => createCareerByAdmin(createCareerDto),
    {
      onSuccess: (_, createCareerDto) => {
        onSuccess && onSuccess();
        toast.success('경력사항이 저장되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+createCareerDto.resumeId)
        );
      },
      onError: () => {
        toast.error('경력사항 추가 실패');
      },
    }
  );
};

export const useUpdateCareerMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCareerDto: UpdateCareerDto) => updateCareer(updateCareerDto),
    {
      onSuccess: (_, updateCareerDto) => {
        onSuccess && onSuccess();
        toast.success('경력사항이 저장되었습니다.');
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+updateCareerDto.resumeId)
        );
        queryClient.invalidateQueries(PATHS.getOneById(updateCareerDto.id));
      },
      onError: () => {
        toast.error('경력사항 수정 실패');
      },
    }
  );
};

export const useUpdateCareerAdminMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCareerDto: UpdateCareerDto) => updateCareerByAdmin(updateCareerDto),
    {
      onSuccess: (_, updateCareerDto) => {
        onSuccess && onSuccess();
        toast.success('경력사항이 저장되었습니다.');
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+updateCareerDto.resumeId)
        );
        queryClient.invalidateQueries(PATHS.getOneById(updateCareerDto.id));
      },
      onError: () => {
        toast.error('경력사항 수정 실패');
      },
    }
  );
};

export const useDeleteCareerMutation = (
  resumeId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCareer(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('삭제되었습니다.');
      queryClient.invalidateQueries(PATHS.getAllByResumeId(+resumeId));
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('경력사항 삭제 실패');
    },
  });
};

export const useDeleteCareerAdminMutation = (
  resumeId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCareerByAdmin(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('삭제되었습니다.');
      queryClient.invalidateQueries(PATHS.getAllByResumeId(+resumeId));
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('경력사항 삭제 실패');
    },
  });
};
