import { isEqual } from 'lodash';
import { PartTimeJobDate } from '../api/part-time-job-date/type';
import { ReactComponent as CheckIcon } from '../assets/icons/icon-check.svg';

interface PartTimeCheckProps {
  setSelectedTime: (time: PartTimeJobDate) => void;
  time: PartTimeJobDate;
  selectedTime: PartTimeJobDate | undefined;
}

export const PartTimeCheck = ({
  setSelectedTime,
  time,
  selectedTime,
}: PartTimeCheckProps) => {
  const isTimeEqual = isEqual(time, selectedTime);

  return (
    <>
      <button
        onClick={() => {
          setSelectedTime(time);
        }}
        className={`${
          isTimeEqual ? `filled-brand-1 border-brand-1` : `border-gray-400`
        } grid flex-none place-items-center rounded-full border-2 p-0.5 `}
      >
        <CheckIcon
          className={`${
            isTimeEqual ? ` stroke-white` : `stroke-gray-400`
          } wh-4 stroke-[3]`}
        />
      </button>
    </>
  );
};
