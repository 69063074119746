import { FC } from 'react';

interface ApplyHistoryProps {
  contractCount: number;
  applicationCount: number;
  approvedCount: number;
}

export const ApplyHistory: FC<ApplyHistoryProps> = ({
  contractCount = 0,
  applicationCount = 0,
  approvedCount = 0,
}) => {
  return (
    <>
      <div className="grid grid-cols-3 divide-x rounded-md bg-sub-3 py-6 px-3 md:px-6">
        {[
          { title: '급여지급 신청', number: contractCount },
          { title: '지원한 알바', number: applicationCount },
          { title: '승인된 알바', number: approvedCount },
        ].map((history, index) => (
          <div className="space-y-2 text-center" key={index}>
            <p className="text-13 text-gray-700 md:text-14">{history.title}</p>
            <h3 className="text-24 text-brand-1">{history.number}</h3>
          </div>
        ))}
      </div>
    </>
  );
};
