import React, { useState } from 'react';
import { map } from 'lodash';
import { HistoryList } from '../../../components/HistoryList';
import { Pagination } from '../../../components/Pagination';
import { ITEMS_PER_PAGE_SM } from '../../../constants';
import { useAllMyApplications } from '../../../hooks/application';

export const HistoryPage = () => {
  const [page, setPage] = useState(1);
  const { data: allApplications } = useAllMyApplications({
    page,
    itemsPerPage: ITEMS_PER_PAGE_SM,
  });

  if (allApplications?.items.length === 0) {
    return (
      <>
        <div className="flex justify-center py-20 text-gray-800 ">
          지원한 알바가 없습니다.
        </div>
      </>
    );
  }

  return (
    <>
      <div className="mt-32 grid divide-y">
        {allApplications &&
          map(allApplications.items, (application) => (
            <HistoryList application={application} key={application.id} />
          ))}
      </div>
      {allApplications?.items.length !== 0 && (
        <Pagination
          page={page}
          setPage={setPage}
          itemsPerPage={ITEMS_PER_PAGE_SM}
          totalItemCount={allApplications?.pagination.totalItemCount || 0}
        />
      )}
    </>
  );
};
