import { useEffect, useId, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosResponse } from 'axios';
import { chain, map, omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { uploadFile } from '../../../api/file';
import { SELECT_GENDER } from '../../../api/part-time-job/type';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Select } from '../../../components/Select';
import { TextField } from '../../../components/TextField';
import FroalaEditor from '../../../components/froala/FroalaEditor';
import { WORK_FORM } from '../../../constants';
import {
  useDeletePartTimeJobMutation,
  usePartTimeJobById,
  useUpdatePartTimeJobMutation,
} from '../../../hooks/part-time-job';
import { useAllPartTimeJobDateByPartTimeJobId } from '../../../hooks/part-time-job-date';
import {
  KOREAN_SELECT_GENDER,
  PartTimeJobFormValue,
} from './CompanyPartTimeJobAdd';

export const CompanyPartTimeJobEdit = () => {
  const navigate = useNavigate();
  const id = useId();
  const [src, setSrc] = useState<string>('');
  const [isMainImgUrlEmpty, setIsMainImgUrlEmpty] = useState(false);
  const { id: companyId, partTimeJobId } = useParams<{
    id: string;
    partTimeJobId: string;
  }>();
  const { data: allDate } = useAllPartTimeJobDateByPartTimeJobId(
    partTimeJobId ? +partTimeJobId : 0
  );
  const formSchema = Yup.object().shape({
    title: Yup.string().required('공고 제목을 입력해주세요.'),
    mainImgUrl: Yup.mixed<FileList | string>(),
    recruitNumber: Yup.number()
      .typeError('모집인원을 입력해주세요.')
      .moreThan(-1, '0 이상의 값을 입력하세요.'),
    workType: Yup.string(),
    recruitDescription: Yup.string().required(
      '모집 내용을 간략하게 입력해주세요.'
    ),
    gender: Yup.mixed<SELECT_GENDER>()
      .oneOf(Object.values(SELECT_GENDER), '모집성별을 선택해주세요.')
      .required('모집성별을 선택해주세요.'),
    hourPay: Yup.number()
      .typeError('금액을 입력해주세요')
      .moreThan(0, '0 이상의 값을 입력하세요.'),
    applicationMethod: Yup.string(),
    workForm: Yup.string().required('근무형태를 선택해주세요.'),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<PartTimeJobFormValue>({
    resolver: yupResolver(formSchema),
    defaultValues: { description: '' },
  });
  const { data: partTimeJob } = usePartTimeJobById(
    partTimeJobId ? +partTimeJobId : 0
  );

  const { mutate: updatePartTimeJobMutate } = useUpdatePartTimeJobMutation(() =>
    navigate(`/admin/company/${companyId}/part-time-job`)
  );
  const { mutate: deletePartTimeJobMutate } = useDeletePartTimeJobMutation(() =>
    navigate(`/admin/company/${companyId}/part-time-job`)
  );
  const mainImgUrl = watch('mainImgUrl');
  useEffect(() => reset(partTimeJob), [reset, partTimeJob]);
  useEffect(() => {
    if (!mainImgUrl || mainImgUrl.length === 0) {
      return;
    }
    if (mainImgUrl instanceof FileList) {
      setSrc(mainImgUrl[0] && URL.createObjectURL(mainImgUrl[0]));
    } else {
      setSrc(mainImgUrl);
    }
  }, [mainImgUrl]);

  useEffect(() => {
    if (!mainImgUrl) {
      setIsMainImgUrlEmpty(true);
    } else {
      setIsMainImgUrlEmpty(false);
    }
  }, [mainImgUrl]);

  return (
    <form
      onSubmit={handleSubmit(async (data) => {
        if (!companyId || !partTimeJobId) {
          return;
        }

        if (!mainImgUrl) {
          toast.error('공고 메인 이미지를 등록해주세요.');
          return;
        }

        const newData = omit(data, [
          'company',
          'createdAt',
          'updatedAt',
          'partTimeJobDates',
          'userPartTimeJobs',
          'companyId',
        ]);

        let newMainImgUrl = '';

        if (data.mainImgUrl instanceof FileList && data.mainImgUrl[0]) {
          await uploadFile(data.mainImgUrl[0]).then(
            (res: AxiosResponse<string>) => (newMainImgUrl = res.data)
          );
        } else if (typeof data.mainImgUrl === 'string') {
          newMainImgUrl = data.mainImgUrl;
        }
        updatePartTimeJobMutate({
          ...newData,
          id: +partTimeJobId,
          mainImgUrl: newMainImgUrl,
        });
      })}
    >
      <div className="px-8">
        <h3 className="mb-5 after:ml-0.5 after:text-brand-1 after:content-['*']">
          제목
        </h3>
        <TextField
          placeholder="공고 제목을 입력해주세요."
          helper={errors.title?.message}
          {...register('title')}
        />

        <h3 className="mt-10 mb-5 after:ml-0.5 after:text-brand-1 after:content-['*']">
          공고 메인 이미지
        </h3>
        <div className="mt-1 grid aspect-[5/2] w-full place-items-center rounded-md border">
          <label htmlFor={id} className="relative w-full">
            {src ? (
              <>
                <img
                  className="aspect-[5/2] w-full rounded-md object-cover object-center"
                  src={src}
                  crossOrigin="anonymous"
                  alt=""
                />
                <div className="absolute -right-4 -bottom-4 cursor-pointer rounded-full bg-sub-1 p-2.5">
                  <Icon.EditPen className="wh-5 stroke-white" />
                </div>
              </>
            ) : (
              <div className="flex cursor-pointer flex-col items-center space-y-2 p-4">
                <div className="rounded-full bg-green-500 p-1.5">
                  <Icon.Plus className="wh-5 text-white" />
                </div>
                <p className="text-center text-14 text-gray-600">
                  이미지를
                  <br /> 업로드해주세요! (1장)
                </p>
                <p className="text-12 text-gray-600">PNG, JPG, JPEG 가능</p>
              </div>
            )}
            <input
              onChange={(e) => {
                const files = e.target.files;
                if (!files || files.length === 0) return;
                if (files.length === 0) return;
                setValue('mainImgUrl', files);
              }}
              type="file"
              accept=".png, .jpg, .jpeg"
              id={id}
              className="hidden"
            />
          </label>
        </div>
        <p className="text-error text-center text-sm text-red-500">
          {isMainImgUrlEmpty &&
            !mainImgUrl &&
            '공고 메인 이미지를 등록해주세요.'}
        </p>

        <h3 className="mt-10 mb-5">채용정보</h3>
        <div className="grid grid-cols-2 gap-5">
          <TextField
            label="모집인원"
            type="number"
            compulsory
            placeholder="모집인원을 입력해주세요."
            helper={errors.recruitNumber?.message}
            {...register('recruitNumber')}
          />
          <TextField
            label="근무타입"
            placeholder="근무타입을 입력해주세요."
            helper={errors.workType?.message}
            {...register('workType')}
          />
          <TextField
            label="모집내용"
            compulsory
            placeholder="모집내용을 간략하게 입력해주세요."
            helper={errors.recruitDescription?.message}
            {...register('recruitDescription')}
          />
          <Select
            label="성별"
            compulsory
            placeholder="모집성별을 선택해주세요."
            helper={errors.gender?.message}
            {...register('gender')}
          >
            <option disabled selected hidden value="">
              성별을 선택해주세요.
            </option>

            {map(SELECT_GENDER, (gender, index) => (
              <option key={index} value={gender}>
                {KOREAN_SELECT_GENDER[gender]}
              </option>
            ))}
          </Select>
          <TextField
            label="시급"
            compulsory
            type="number"
            placeholder="금액을 입력해주세요."
            helper={errors.hourPay?.message}
            {...register('hourPay')}
          />
          <TextField
            label="지원방법"
            placeholder="지원방법을 입력해주세요."
            helper={errors.applicationMethod?.message}
            {...register('applicationMethod')}
          />
          <Select
            label="업무형태"
            helper={errors.workForm?.message}
            {...register('workForm')}
          >
            <option selected hidden>
              업무형태를 입력해주세요.
            </option>
            <option value="">전체</option>
            {map(WORK_FORM, (workType, index) => (
              <option key={index} value={workType}>
                {workType}
              </option>
            ))}
          </Select>
          <TextField
            label="평균 근무시간"
            placeholder="평균 근무시간을 입력해주세요."
            helper={errors.averageWorkingHours?.message}
            {...register('averageWorkingHours')}
          />
        </div>

        <h3 className="mt-10 mb-5">상세정보</h3>
        <FroalaEditor
          defaultValue={partTimeJob?.description}
          onChange={(model) => {
            setValue('description', model);
          }}
        />
      </div>
      <div className="flex justify-end space-x-4 px-4 py-4 sm:px-6 md:px-8">
        <Button
          type="button"
          text="Delete"
          className="outlined-gray-600 h-10 text-sm hover:bg-gray-50"
          onClick={() => {
            if (!partTimeJobId) {
              return;
            }

            const isHaveApplication = chain(
              map(allDate, (date) =>
                map(
                  date,
                  (time) => time.applications && time.applications.length > 0
                )
              )
            )
              .flatten()
              .some()
              .value();

            if (isHaveApplication) {
              toast.error('지원자가 있는 공고는 삭제할 수 없습니다.');
              return;
            }

            const confirm = window.confirm(
              '아르바이트 공고를 삭제하시겠습니까?'
            );
            if (!confirm) {
              return;
            }
            deletePartTimeJobMutate(+partTimeJobId);
          }}
        />
        <Button
          text="Save"
          className="filled-gray-800 h-10 text-sm hover:bg-gray-900"
        />
      </div>
    </form>
  );
};
