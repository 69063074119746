import { useEffect, useState } from 'react';
import { DateTime, TIME_STAMP } from '../admin/pages/company/types';
import { Select } from './Select';

interface TimeDropDownProps {
  time: DateTime;
  disabled?: boolean;
  onChange: (time: DateTime) => void;
}

const HOURS = new Array(24).fill(0).map((_, i) => i);
const MINUTES = new Array(60).fill(0).map((_, i) => i);
export const TimeDropdown = ({
  onChange,
  disabled,
  time: initTime,
}: TimeDropDownProps) => {
  const [time, setTime] = useState<{
    hour: number;
    min: number;
  }>(initTime);
  const { hour, min } = time;

  useEffect(() => {
    onChange(time);
  }, [time]);

  return (
    <div className="flex items-center space-x-2 text-15 text-gray-700">
      <Select
        disabled={disabled}
        className="w-32"
        onChange={(e) => {
          setTime({ hour: +e.target.value, min });
        }}
      >
        <option disabled selected hidden value="">
          시간
        </option>

        {HOURS.map((hour) => (
          <option key={hour} value={hour} selected={hour === time.hour}>
            {TIME_STAMP[hour]}
          </option>
        ))}
      </Select>
      <Select
        disabled={disabled}
        className="w-32"
        onChange={(e) => {
          setTime({ hour, min: +e.target.value });
        }}
      >
        <option disabled selected hidden value="">
          분
        </option>

        {MINUTES.map((min) => (
          <option key={min} value={min} selected={min === time.min}>
            {TIME_STAMP[min]}
          </option>
        ))}
      </Select>
    </div>
  );
};
