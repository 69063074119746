import { FC, useState } from 'react';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { Company } from '../../api/company/type';
import { PartTimeJobDate } from '../../api/part-time-job-date/type';
import { useCreateApplicationMutation } from '../../hooks/application';
import { Button } from '../Button';
import { SelectInfo } from '../SelectInfo';
import { AnimationLayout } from './AnimationLayout';

interface PartTimeApplyModalProps {
  onClose: () => void;
  title: string;
  recruitDescription: string;
  open: boolean;
  company: Company;
  clickedDay: Date | null;
  selectedTime: PartTimeJobDate | undefined;
  partTimeJobId: number;
}

export const PartTimeApplyModal: FC<PartTimeApplyModalProps> = ({
  open,
  onClose,
  title,
  recruitDescription,
  company,
  clickedDay,
  selectedTime,
  partTimeJobId,
}) => {
  const navigate = useNavigate();
  const { mutate: createApplicationMutate } = useCreateApplicationMutation(() =>
    navigate(-1)
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!clickedDay || !selectedTime) {
    return <></>;
  }

  const createData = {
    companyName: company.companyName,
    recruitDescription,
    workDate: format(clickedDay, 'yyyy-MM-dd'),
    startWorkTime: new Date(selectedTime.workingStartAt).toISOString(),
    endWorkTime: new Date(selectedTime.workingEndAt).toISOString(),
    partTimeJobId,
    partTimeJobDateId: selectedTime.id,
  };

  return (
    <AnimationLayout open={open} onClose={onClose}>
      <div className="my-8 w-full max-w-md transform space-y-3 overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
        <div className="flex justify-between ">
          <h4 className="text-gray-800">알바 지원하기</h4>
        </div>
        <div className="space-y-6">
          <div className="space-y-1">
            <h3>{title}</h3>
            <p>{recruitDescription}</p>
          </div>
          <SelectInfo
            company={company}
            workDate={clickedDay}
            time={selectedTime}
          />
          <ul className="list-disc px-4 text-15 text-gray-700">
            <li>
              지원 정보를 다시 한번 확인해주세요.
              <br />
              지원취소는 불가합니다.
            </li>
            <li>제출된 지원서는 수정이 불가합니다.</li>
          </ul>

          <Button
            text="지원하기"
            onClick={() => {
              setIsSubmitted(true);
              createApplicationMutate(createData);
            }}
            className="mt-4 w-full bg-brand-1 text-white"
            disabled={isSubmitted}
          />
        </div>
      </div>
    </AnimationLayout>
  );
};
