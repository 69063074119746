import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab } from '../../components/Tab';

export const CompanyManage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  if (!id) {
    return <></>;
  }

  return (
    <>
      <nav className="space-x-6 px-8 pb-4 ">
        <Tab
          title="회사 정보"
          onClick={() => navigate(`/admin/company/${id}/info`)}
          selected={pathname === `/admin/company/${id}/info`}
        />
        <Tab
          title="아르바이트 공고"
          onClick={() => navigate(`/admin/company/${id}/part-time-job`)}
          selected={pathname === `/admin/company/${id}/part-time-job`}
        />
      </nav>

      <Outlet />
    </>
  );
};
