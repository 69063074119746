import { map } from 'lodash';
import { KOREAN_SELECT_GENDER } from '../admin/pages/company/CompanyPartTimeJobAdd';
import { PartTimeJob } from '../api/part-time-job/type';
interface PartTimeJobInfoProps {
  partTimeJob: PartTimeJob;
}

export const PartTimeJobInfo = ({ partTimeJob }: PartTimeJobInfoProps) => {
  const { recruitNumber, workType, gender, applicationMethod, workForm } =
    partTimeJob;
  const partTimeData = [
    { title: '모집인원', desc: recruitNumber },
    { title: '근무타입', desc: workType },
    { title: '성별', desc: KOREAN_SELECT_GENDER[gender] },
    { title: '지원방법', desc: applicationMethod },
    { title: '업무형태', desc: workForm },
  ];
  return (
    <>
      <div className=" grid w-full grid-cols-1 justify-between space-y-4 rounded-none bg-sub-3 p-5 md:grid-cols-4 md:space-y-0 md:rounded-md md:px-20 md:text-center">
        {map(partTimeData, (info) => (
          <div
            key={info.title}
            className="flex flex-col space-y-2 md:text-center "
          >
            <p className="text-gray-700">{info.title}</p>
            <p className="text-17 font-medium">{info.desc ? info.desc : '-'}</p>
          </div>
        ))}
      </div>
    </>
  );
};
