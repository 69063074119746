import { useParams } from 'react-router-dom';
import { CreateContractDto } from '../api/contract/type';
import { SCV } from '../constants';
import { useAllCompanies } from '../hooks/company';
import { useContractByAdminId } from '../hooks/contract';
import { Select } from './Select';

interface ContractAdminInfoTableProps {
  contractState: CreateContractDto;
  setContractState: (contractState: CreateContractDto) => void;
}

export default function ContractAdminInfoTable(
  contractInfoTableProps: ContractAdminInfoTableProps
) {
  const { contractState, setContractState } = contractInfoTableProps;
  const { workPlace } = contractState;
  const { id: contractId } = useParams<{ id: string }>();
  const { data: contract } = useContractByAdminId(+(contractId as string) || 0);
  const { data: allCompaines } = useAllCompanies();

  return (
    <>
      <div className="">
        <div className=" grid h-72 w-full grid-cols-7 divide-y divide-x rounded-md border">
          <div className="row-span-2 grid place-items-center text-15 text-gray-700">
            <p>사용자</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">회사명</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{SCV.companyName}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">연락처</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{SCV.phone}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">소재지</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>{SCV.address}</p>
          </div>
          <div className="row-span-3 grid place-items-center text-15 text-gray-700">
            <p>근로자</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">성명</p>
          </div>
          <div className="col-span-2 grid content-center px-4 ">
            <p>{contract?.userName}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">주민등록번호</p>
          </div>
          <div className="col-span-2 grid content-center px-4">
            <p>{contract?.userPersonalId}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">휴대전화</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>{contract?.userPhone}</p>
          </div>
          <div className="grid place-items-center bg-sub-2 text-15 text-gray-700">
            <p className="">주소</p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <p>
              {contract?.userAddress} {contract?.userAddressDetail}
            </p>
          </div>
          <div className="col-span-2 grid place-items-center bg-sub-2 ">
            <p className="text-15 text-gray-700 after:ml-0.5 after:text-brand-1 after:content-['*']">
              근무장소
            </p>
          </div>
          <div className="col-span-5 grid content-center px-4 ">
            <Select
              className="w-full"
              defaultValue=""
              value={workPlace}
              onChange={(e) =>
                setContractState({
                  ...contractState,
                  workPlace: e.target.value,
                })
              }
            >
              <option value="" key={0} hidden disabled>
                근무장소를 선택해주세요.
              </option>
              {allCompaines?.map((workPlace) => (
                <option key={workPlace.id} value={workPlace.companyName}>
                  {workPlace.companyName}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </>
  );
}
