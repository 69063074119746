import { FC } from 'react';
import React from 'react';
import { format, getDay, isAfter, startOfDay } from 'date-fns';
import { difference, find, map } from 'lodash';
import { PartTimeJobDate } from '../api/part-time-job-date/type';
import { useAllApplicationByPTJId } from '../hooks/application';
import { MomentFormat, utcToLocalFormat } from '../plugins/moment';
import { getKoreanDay } from '../utils';
import { Icon } from './Icon';

export interface PartTimeDateCardProps {
  ptjDate: Record<string, PartTimeJobDate[]>;
  partTimeJobId: number;
}

export const PartTimeDateCard: FC<PartTimeDateCardProps> = ({
  ptjDate,
  partTimeJobId,
}) => {
  const { data: allApplication } = useAllApplicationByPTJId(partTimeJobId);
  const partTimeJobDateIds = map(allApplication, 'partTimeJobDateId');

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        {map(Object.entries(ptjDate), (date, index) => {
          const currentTime = new Date();
          const newDate = new Date(date[0]);
          const day = getKoreanDay(getDay(newDate));
          const isValidDate = format(currentTime, 'yyyy-MM-dd') > date[0];
          const dateIds = map(date[1], 'id');
          const isAllApplication =
            difference(dateIds, partTimeJobDateIds).length === 0;

          if (isAfter(startOfDay(currentTime), startOfDay(new Date(date[0])))) {
            return <></>;
          }

          return (
            <div
              key={index}
              className={`rounded-lg border p-5 ${
                (isValidDate || isAllApplication) &&
                'text-gray-500 line-through'
              }`}
            >
              <div className="mb-4 flex items-center space-x-2">
                <Icon.Calendar className="wh-5 text-brand-1" />
                <p>
                  {format(newDate, 'yyyy-MM-dd')}
                  {`  (${day})`}
                </p>
              </div>

              <div className="flex flex-col space-y-2">
                {map(date[1], (time) => {
                  const { workingStartAt, workingEndAt } = time;
                  const AppliedPtj = find(
                    allApplication,
                    (app) => app.partTimeJobDateId === time.id
                  );
                  return (
                    <div
                      key={time.id}
                      className={`${
                        AppliedPtj && 'text-gray-500 line-through'
                      }`}
                    >
                      •{' '}
                      {utcToLocalFormat(
                        workingStartAt.toLocaleString(),
                        MomentFormat.HHmm
                      )}{' '}
                      ~{' '}
                      {utcToLocalFormat(
                        workingEndAt.toLocaleString(),
                        MomentFormat.HHmm
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
