import React, { useState } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { KOREAN_BANK_NAME } from '../../api/resume/type';
import { Button } from '../../components/Button';
import { Pagination } from '../../components/Pagination';
import { PaymentRequestList } from '../../components/PaymentRequestList';
import { PaymentModal } from '../../components/modal/PaymentModal';
import { ITEMS_PER_PAGE_SM } from '../../constants';
import { useMyContract } from '../../hooks/contract';
import { usePaymentById } from '../../hooks/payment';
import { useMyResume } from '../../hooks/resume';

export const PaymentRequestPage = () => {
  const [page, setPage] = useState(1);
  const [selectedPaymentId, setSelectedPaymentId] = useState(0);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { data: myResume } = useMyResume();
  const { data: payment } = usePaymentById(
    selectedPaymentId ? +selectedPaymentId : 0
  );
  const { data: myContract } = useMyContract({
    page,
    itemsPerPage: ITEMS_PER_PAGE_SM,
  });

  return (
    <>
      {payment && (
        <PaymentModal
          open={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          payment={payment}
        />
      )}
      <div className="mx-5 mt-32 grid place-items-center md:mx-0">
        <div className="mt-8 grid w-full max-w-2xl ">
          <h2 className="mt-4 font-bold leading-normal md:mt-10">
            급여/비용 청구
          </h2>
          <div className="mt-10">
            <h4 className="mb-2">계좌 정보</h4>
            <div>
              급여/비용 청구 전 계좌 정보를 다시 확인해주세요. <br />
              계좌 정보 수정은 마이페이지{'>'}지원서 수정에서 가능합니다.
            </div>
            <div className="mt-6 flex flex-col space-y-3 rounded-lg bg-sub-2 p-8">
              <h5 className="pb-2">
                {myResume?.bankAccount
                  ? `${KOREAN_BANK_NAME[myResume?.bankCode]} ${
                      myResume?.bankAccount
                    }`
                  : `계좌 정보를 입력해주세요.`}
              </h5>

              <div className="font-medium">
                {myResume?.bankAccount ? `${myResume?.depositor}` : ``}
              </div>
              <Link
                to={'/mypage/resume'}
                className="place-self-end font-medium text-brand-1"
              >
                계좌 정보 수정 {'>'}
              </Link>
            </div>
          </div>
          <div className="mt-16 flex flex-col">
            <h4 className="">급여/비용 청구 및 내역</h4>

            <div className="mt-5 rounded-lg bg-gray-50 p-5 text-15">
              <p className="font-medium text-gray-700">
                📌 급여신청 시 유의사항
              </p>
              <ul className="list-disc p-4">
                <li>
                  8시간 이상 근무시 본인 식대 및 휴게비용으로 1시간 공제, 미만시
                  30분 공제되어 지급됩니다.
                </li>
                <li>
                  신한은행 외 타행으로 급여이체 받을 시 이체 수수료 500원이
                  공제되어 지급됩니다.
                </li>
                <li>
                  임금은 근무 후 익 주 월~수요일 19시 지급이며 근무업장 사정에
                  따라 최대 7일, 최소 2시간 지연 입금 될 수 있다.
                </li>
              </ul>
            </div>

            <div className="mt-10 self-end ">
              <Button
                text="근로계약서 작성 (급여신청) >"
                className="bg-brand-1 text-white"
                to="/contract/add"
                disabled={!myResume?.bankAccount}
              />
            </div>
            <div className="grid divide-y">
              {myContract &&
                map(myContract.items, (contract) => (
                  <PaymentRequestList
                    paymentRequest={contract.payment}
                    contract={contract}
                    onClick={(paymentId: number) => {
                      setShowPaymentModal(true);
                      setSelectedPaymentId(paymentId);
                    }}
                  />
                ))}
            </div>
          </div>
          {myContract?.items.length !== 0 && (
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE_SM}
              setPage={setPage}
              totalItemCount={myContract?.pagination.totalItemCount || 0}
              page={page}
            />
          )}
        </div>
      </div>
    </>
  );
};
