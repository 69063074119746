import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
import { meState } from '../../plugins/ridge';
import { Role } from '../../types';

interface FormValues {
  email: string;
  password: string;
}

export const LoginPage = () => {
  const { login } = useAuth();
  const me = meState.useValue();
  const navigate = useNavigate();
  const formSchema = Yup.object().shape({
    email: Yup.string()
      .required('이메일을 입력해주세요.')
      .matches(
        /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
        '올바른 이메일 형식이 아닙니다.'
      ),
    password: Yup.string().required('비밀번호를 입력해주세요'),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(formSchema),
  });

  useEffect(() => {
    if (!me) {
      return;
    }
    const { role } = me;
    if (role === Role.ADMIN) {
      navigate('/admin', { replace: true });
      return;
    }
    navigate('/main', { replace: true });
  }, [me]);
  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <form
          className="flex w-full max-w-md flex-col space-y-4 "
          noValidate
          onSubmit={handleSubmit((data) => login(data))}
        >
          <h1 className="mb-5 text-32 font-semibold text-gray-900">로그인</h1>

          <TextField
            compulsory
            label="아이디"
            type="email"
            placeholder="이메일을 입력해주세요."
            helper={errors.email?.message}
            {...register('email')}
          />
          <TextField
            compulsory
            label="비밀번호"
            type="password"
            placeholder="비밀번호를 입력해주세요."
            helper={errors.password?.message}
            {...register('password')}
          />
          <Link className=" pb-10 text-right text-gray-500" to="/find-password">
            비밀번호를 잊으셨나요?
          </Link>
          <Button text="로그인" className="filled-brand-1" />
          <Button
            text="회원가입"
            className="border border-brand-1 bg-white text-brand-1"
            to="/signup"
          />
        </form>
      </div>
    </>
  );
};
