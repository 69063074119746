import React, { FC, useEffect, useState } from 'react';
import { filter, last } from 'lodash';
import { Button } from '../../../components/Button';
import { Icon } from '../../../components/Icon';
import { Label } from '../../../components/Label';
import { TextField } from '../../../components/TextField';
import { TimeDropdown } from '../../../components/TimeDropdown';
import { AnimationLayout } from '../../../components/modal/AnimationLayout';
import { ModalType } from '../../../constance';
import { DateList, TimeList } from '../../pages/company/types';

interface CompanyPartTimeModalProps {
  onClose: () => void;
  onClick: (workDate: string, timeList: TimeList[]) => void;
  onDeleteClick: (deleteId: number) => void;
  onEdit: (editDate: DateList) => void;
  open: boolean;
  modalType: ModalType;
  editDate?: DateList;
}

const INIT_TIME = {
  startTime: { hour: 0, min: 0 },
  endTime: { hour: 0, min: 0 },
};

export const CompanyPartTimeModal: FC<CompanyPartTimeModalProps> = ({
  onClose,
  open,
  onClick,
  onDeleteClick,
  onEdit,
  editDate,
  modalType,
}) => {
  const [timeList, setTimeList] = useState<TimeList[]>([]);
  const [workDate, setWorkDate] = useState<string>('');

  const _init = () => {
    setWorkDate('');
    setTimeList([]);
  };

  const _addTimeList = () => {
    setTimeList((prev) => [
      ...prev,
      {
        index: (last(timeList)?.index || 0) + 1,
        ...INIT_TIME,
      },
    ]);
  };

  const _deleteTimeList = (id: number) => {
    const deleteTimeList = filter(timeList, (timeList) => {
      return timeList.index !== id;
    });
    setTimeList(deleteTimeList);
  };

  useEffect(() => {
    if (editDate) {
      const { workDate, timeList } = editDate;
      setWorkDate(workDate);
      setTimeList(timeList);
    }
  }, [editDate]);

  useEffect(() => {
    !open && _init();
  }, [open]);

  return (
    <>
      <AnimationLayout open={open} onClose={onClose} isAdmin>
        <div className="my-8 max-h-[600px] w-full max-w-3xl transform space-y-3 overflow-hidden overflow-y-auto rounded-lg bg-white p-6 shadow-xl transition-all">
          <div className="flex justify-between">
            <h4 className="text-gray-800">{`근무일 ${
              modalType === ModalType.ADD ? '추가' : '수정'
            }`}</h4>
            <button
              type="button"
              className="right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
              onClick={onClose}
            >
              <Icon.X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="grid gap-y-4">
            <TextField
              label="날짜"
              type="date"
              max="9999-12-31"
              compulsory
              labelClassname="text-left"
              value={workDate}
              onChange={(e) => setWorkDate(e.target.value)}
            />
            <div className="space-y-3 text-left">
              <Label text="시간" compulsory />
              {timeList.map((time, index: number) => (
                <div
                  key={time.index}
                  className="flex items-center space-x-2 text-15 text-gray-700"
                >
                  <div className="flex items-center space-x-2">
                    <TimeDropdown
                      time={time.startTime}
                      onChange={(startTime: { hour: number; min: number }) => {
                        const updateTimeList = [...timeList];
                        updateTimeList[index].startTime = startTime;
                        setTimeList(updateTimeList);
                      }}
                    />
                    <div> ~ </div>
                    <TimeDropdown
                      time={time.endTime}
                      onChange={(endTime: { hour: number; min: number }) => {
                        const updateTimeList = [...timeList];
                        updateTimeList[index].endTime = endTime;
                        setTimeList(updateTimeList);
                      }}
                    />
                  </div>
                  <button
                    type="button"
                    className="right-3 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:right-5"
                    onClick={() => _deleteTimeList(time.index)}
                  >
                    <Icon.X className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              ))}
            </div>
            <div className="flex text-15 text-gray-700">
              <button onClick={_addTimeList}>+ 시간 추가</button>
            </div>

            <div className="flex space-x-3">
              <Button
                text="저장하기"
                disabled={!workDate || timeList.length === 0}
                onClick={() => {
                  editDate
                    ? onEdit({ ...editDate, timeList, workDate })
                    : onClick(workDate, timeList);
                  onClose();
                }}
                className="mt-4 w-60 bg-gray-800 text-white"
              />
              {editDate && (
                <Button
                  text="삭제하기"
                  onClick={() => {
                    onDeleteClick(editDate.id);
                    onClose();
                  }}
                  className="mt-4 w-60 border-2 border-gray-500 bg-white text-gray-800"
                />
              )}
            </div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};
