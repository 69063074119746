import React, { FC, ReactElement } from 'react';

interface HistoryCardProps {
  yearImg: string;
  history: ReactElement;
}

export const HistoryCard: FC<HistoryCardProps> = ({ yearImg, history }) => {
  return (
    <div className="space-y-7">
      <div className="flex items-center space-x-4">
        <img src={yearImg} alt="" className="w-14" />
        <div className="w-full border border-black/10" />
      </div>

      <div className="text-[14px] font-medium text-[#666666]">{history}</div>
    </div>
  );
};
