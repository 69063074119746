import { Certificate } from 'crypto';
import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCertificate,
  createCertificateByAdmin,
  deleteCertificate,
  deleteCertificateByAdmin,
  updateCertificate,
  updateCertificateByAdmin,
} from '../../api/certificate';
import { PATHS } from '../../api/certificate/paths';
import {
  CreateCertificateDto,
  UpdateCertificateDto,
} from '../../api/certificate/type';
import { adminFetcher, fetcher } from '../../plugins/react-query';

export function useAllCertificates(resumeId: string, isAdmin: boolean) {
  return useQuery<Certificate[], AxiosError>(
    `${PATHS.getAllByResumeId(+resumeId)}`,
    isAdmin ? adminFetcher : fetcher
  );
}

export const useCreateCertificateMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCertificateDto: CreateCertificateDto) =>
      createCertificate(createCertificateDto),
    {
      onSuccess: (_, createCertificateDto) => {
        onSuccess && onSuccess();
        toast.success('특기사항이 저장되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+createCertificateDto.resumeId)
        );
      },
      onError: () => {
        toast.error('특기사항 추가 실패');
      },
    }
  );
};

export const useCreateCertificateAdminMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCertificateDto: CreateCertificateDto) =>
      createCertificateByAdmin(createCertificateDto),
    {
      onSuccess: (_, createCertificateDto) => {
        onSuccess && onSuccess();
        toast.success('특기사항이 저장되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+createCertificateDto.resumeId)
        );
      },
      onError: () => {
        toast.error('특기사항 추가 실패');
      },
    }
  );
};

export const useUpdateCertificateMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCertificateDto: UpdateCertificateDto) =>
      updateCertificate(updateCertificateDto),
    {
      onSuccess: (_, updateCertificateDto) => {
        onSuccess && onSuccess();

        toast.success('특기사항이 저장되었습니다.');
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+updateCertificateDto.resumeId)
        );
        queryClient.invalidateQueries(
          PATHS.getOneById(updateCertificateDto.id)
        );
      },
      onError: () => {
        toast.error('특기사항 수정 실패');
      },
    }
  );
};

export const useUpdateCertificateAdminMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCertificateDto: UpdateCertificateDto) =>
      updateCertificateByAdmin(updateCertificateDto),
    {
      onSuccess: (_, updateCertificateDto) => {
        onSuccess && onSuccess();

        toast.success('특기사항이 저장되었습니다.');
        queryClient.invalidateQueries(
          PATHS.getAllByResumeId(+updateCertificateDto.resumeId)
        );
        queryClient.invalidateQueries(
          PATHS.getOneById(updateCertificateDto.id)
        );
      },
      onError: () => {
        toast.error('특기사항 수정 실패');
      },
    }
  );
};

export const useDeleteCertificateMutation = (
  resumeId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCertificate(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('삭제되었습니다.');
      queryClient.invalidateQueries(PATHS.getAllByResumeId(+resumeId));
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('특기사항 삭제 실패');
    },
  });
};

export const useDeleteCertificateAdminMutation = (
  resumeId: string,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCertificateByAdmin(id), {
    onSuccess: (_, id) => {
      onSuccess && onSuccess();
      toast.success('삭제되었습니다.');
      queryClient.invalidateQueries(PATHS.getAllByResumeId(+resumeId));
      queryClient.invalidateQueries(PATHS.getOneById(id));
    },
    onError: () => {
      toast.error('특기사항 삭제 실패');
    },
  });
};
