import React, { useState } from 'react';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import { MobileTopBar } from '../../components/MobileTopBar';
import { Pagination } from '../../components/Pagination';
import { ITEMS_PER_PAGE_SM } from '../../constants';
import { useAllNotices } from '../../hooks/notice';
import { MomentFormat, utcToLocalFormat } from '../../plugins/moment';

export const NoticePage = () => {
  const [page, setPage] = useState(1);
  const { data: allNotices } = useAllNotices({
    page,
    itemsPerPage: ITEMS_PER_PAGE_SM,
  });

  return (
    <>
      <MobileTopBar title="공지사항" />
      <div className="mt-14 grid place-items-center px-5">
        <div className=" flex w-full max-w-4xl flex-col">
          <h3 className="mt-14 hidden text-28 font-bold leading-normal md:block">
            공지사항
          </h3>
          <div className="mt-4 w-full">
            {allNotices &&
              map(allNotices.items, (notice) => (
                <div
                  key={notice.id}
                  className="space-y-2 border-b border-gray-100 py-5"
                >
                  <div className="text-13 font-medium text-gray-500">
                    {utcToLocalFormat(
                      notice.createdAt.toLocaleString(),
                      MomentFormat.YYYYMMDD
                    )}
                  </div>
                  <div className="text-16 text-gray-900 md:text-20">
                    <Link to={`/notice/${notice.id}`}>
                      <span className="break-words">{notice.title}</span>
                    </Link>
                  </div>
                </div>
              ))}
          </div>
          {allNotices?.items.length !== 0 && (
            <Pagination
              itemsPerPage={ITEMS_PER_PAGE_SM}
              setPage={setPage}
              totalItemCount={allNotices?.pagination.totalItemCount || 0}
              page={page}
            />
          )}
        </div>
      </div>
    </>
  );
};
