import { useState } from 'react';
//@ts-ignore
import BootPay from 'bootpay-js';
import { omit } from 'lodash';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { mobileAuth } from '../../api/auth';
import { Button } from '../../components/Button';
import { Checkbox } from '../../components/Checkbox';
import { Icon } from '../../components/Icon';
import { Label } from '../../components/Label';
import { TextField } from '../../components/TextField';
import { useAuth } from '../../hooks';
interface FormValues {
  name: string;
  email: string;
  password: string;
  passwordConfirm: string;
}

export const SignupPage = () => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const { signup } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onSubmit',
  });

  return (
    <>
      <div className="mx-5 mb-36 mt-32 grid place-items-center md:mx-0">
        <form
          className="flex w-full max-w-md flex-col space-y-4 "
          onSubmit={handleSubmit((data) => {
            const signupData = omit(data, ['passwordConfirm']);

            signup({ ...signupData, phone })
              .then(() => navigate('/signup/success', { replace: true }))
              .catch((err) => {
                if (err?.response?.data?.message) {
                  toast.error(err.response.data.message);
                  if (
                    err.response.data.message === '이미 가입된 전화번호입니다.'
                  ) {
                    setIsVerified(false);
                    setPhone('');
                  }
                } else {
                  toast.error('회원가입에 실패했습니다.');
                }
              });
          })}
        >
          <h1 className="mb-5 text-32 font-semibold text-gray-900">회원가입</h1>
          <TextField
            label="이름"
            type="text"
            compulsory
            placeholder="이름을 입력해주세요."
            helper={errors.name?.message}
            {...register('name', { required: '이름을 입력해주세요' })}
            name="name"
          />

          <TextField
            label="아이디(이메일)"
            type="email"
            compulsory
            placeholder="이메일을 입력해주세요."
            helper={errors.email?.message}
            {...register('email', {
              required: '이메일을 입력해주세요',
              pattern: {
                value: /^[a-zA-Z0-9+-\_.]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: '올바른 이메일 형식이 아닙니다.',
              },
            })}
            name="email"
          />

          <TextField
            label="비밀번호"
            type="password"
            compulsory
            placeholder="6자 이상의 비밀번호를 입력해주세요."
            helper={errors.password?.message}
            {...register('password', {
              required: '6자 이상의 비밀번호를 입력해주세요',
              minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
            })}
            name="password"
          />
          <TextField
            label="비밀번호 확인"
            type="password"
            compulsory
            placeholder="비밀번호를 한번 더 입력해주세요."
            helper={errors.passwordConfirm?.message}
            {...register('passwordConfirm', {
              required: '비밀번호를 한번 더 입력해주세요',
              minLength: { value: 6, message: '최소 6자 이상 가능합니다' },
              maxLength: { value: 15, message: '최대 15자 까지만 가능합니다' },
              validate: (value) => {
                if (value !== watch().password)
                  return '비밀번호가 일치하지 않습니다.';
              },
            })}
            name="passwordConfirm"
          />
          <div className="flex flex-col space-y-2">
            <Label compulsory>휴대폰 인증</Label>
            <div className="flex-cols flex space-x-2">
              <Icon.AlertCircle className="stroke-gray-500" />
              <p className="text-15 text-gray-700">
                15세 이상 인증이 필요합니다.
              </p>
            </div>
          </div>
          {isVerified && (
            <div className="flex space-x-2">
              <TextField
                className="w-full"
                disabled
                value={phone && phone.slice(0, 3)}
              />
              <TextField
                className="w-full"
                disabled
                value={
                  phone && phone.length === 11
                    ? phone.slice(3, 7)
                    : phone.slice(3, 6)
                }
              />
              <TextField
                className="w-full"
                disabled
                value={
                  phone && phone.length === 11 ? phone.slice(7) : phone.slice(6)
                }
              />
            </div>
          )}
          <Button
            disabled={isVerified}
            type="button"
            text={`${isVerified ? '인증완료' : '본인인증'}`}
            className="filled-brand-1"
            onClick={() => {
              BootPay.request({
                price: 0, // 0으로 해야 한다.
                application_id: '629881e9e38c3000235add8f',
                name: '본인인증', //결제창에서 보여질 이름
                pg: 'danal',
                method: 'auth', // 빌링키를 받기 위한 결제 수단
                show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
                order_id: uuidv4(), //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
                extra: {
                  age_limit: '15',
                },
              })
                .error(() => {
                  setIsVerified(false);
                  setPhone('');
                })
                .cancel(() => {
                  setIsVerified(false);
                  setPhone('');
                })
                .done((data: any) => {
                  mobileAuth({ receiptId: data?.receipt_id });
                  setIsVerified(true);
                  setPhone(data.phone);
                });
            }}
          />
          <div className="flex-cols flex space-x-2">
            <Checkbox
              className="m-1 h-5 w-5"
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.currentTarget.checked);
              }}
            />
            <p>
              회원가입을 위한 개인정보제공에 동의합니다. 제공하신 정보는
              개인식별 이외의 용도로 사용되지 않습니다.
            </p>
          </div>
          <Button
            disabled={!isVerified || !isChecked}
            text="회원가입"
            className="filled-brand-1"
          />
        </form>
      </div>
    </>
  );
};
