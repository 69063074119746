import { Fragment, useState } from 'react';
import { format, getDay } from 'date-fns';
import { map } from 'lodash';
import { useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Label } from '../../../components/Label';
import { useAllPartTimeJobDateByPartTimeJobId } from '../../../hooks/part-time-job-date';
import { MomentFormat, utcToLocalFormat } from '../../../plugins/moment';
import { getKoreanDay } from '../../../utils';
import AdminH1 from '../../components/AdminH1';
import { AddDateModal } from '../../components/modal/AddDateModal';
import { EditDateModal } from '../../components/modal/EditDateModal';

export interface EditDateI {
  id: number;
  workDate: string;
  workDateType: string;
  workingStartAt: string;
  workingEndAt: string;
  applications: any;
}

export const CompanyPartTimeJobDate = () => {
  const { partTimeJobId } = useParams<{ partTimeJobId: string }>();
  const [showEditDateModal, setShowEditDateModal] = useState<boolean>(false);
  const [showAddDateModal, setShowAddDateModal] = useState<boolean>(false);
  const [editDateItems, setEditDateItems] = useState<any>();

  const { data: allDate } = useAllPartTimeJobDateByPartTimeJobId(
    partTimeJobId ? +partTimeJobId : 0
  );

  if (!partTimeJobId) {
    return <></>;
  }

  return (
    <>
      <AddDateModal
        onClose={() => {
          setShowAddDateModal(false);
        }}
        open={showAddDateModal}
        partTimeJobId={+partTimeJobId}
      />
      <EditDateModal
        onClose={() => {
          setShowEditDateModal(false);
        }}
        open={showEditDateModal}
        editDateItems={editDateItems}
        partTimeJobId={+partTimeJobId}
      />
      <div className="px-8">
        <AdminH1>근무일</AdminH1>
        <div className="flex justify-between py-5">
          <Button
            type="button"
            text="날짜 추가하기"
            className="w-40 bg-gray-800 text-white"
            onClick={() => {
              setShowAddDateModal(true);
            }}
          />
        </div>
        <div>
          {map(allDate, (date) => {
            const newWorkDate = new Date(date[0].workDate);
            const day = getKoreanDay(getDay(newWorkDate));
            return (
              <div className="mb-8">
                <Fragment key={date[0].id}>
                  <div className="my-3 space-y-2">
                    <div>
                      <Label text="날짜" />
                      <p className="text-20 font-medium">
                        {format(newWorkDate, 'yyyy-MM-dd')}
                        {`  (${day})`}
                      </p>
                    </div>

                    <div className="grid grid-cols-2 gap-8">
                      <div className="text-left">
                        <Label text="시간" />
                        <div className="items-center space-y-3 text-16 font-medium">
                          {map(date, (time) => {
                            const { workingStartAt, workingEndAt } = time;
                            return (
                              <div key={time.id} className="flex space-x-4">
                                <p>
                                  {utcToLocalFormat(
                                    workingStartAt.toLocaleString(),
                                    MomentFormat.HHmm
                                  )}
                                </p>
                                <p>~</p>
                                <p>
                                  {utcToLocalFormat(
                                    workingEndAt.toLocaleString(),
                                    MomentFormat.HHmm
                                  )}
                                </p>
                              </div>
                            );
                          })}
                          <div>
                            <Button
                              type="button"
                              text="수정"
                              onClick={() => {
                                setEditDateItems(date);
                                setShowEditDateModal(true);
                              }}
                              className="mt-2 bg-blue-50 text-blue-500"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fragment>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
