import { AxiosError } from 'axios';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  createCompany,
  deleteCompany,
  getAllCompanies,
  getAllCompaniesByAdmin,
  getCompanyWagesByUserId,
  updateCompany,
} from '../../api/company';
import { PATHS } from '../../api/company/paths';
import {
  Company,
  CreateCompanyDto,
  UpdateCompanyDto,
} from '../../api/company/type';
import { adminFetcher } from '../../plugins/react-query';
import { Paginated, PaginationDto } from '../../types';

export function useAllCompanies() {
  return useQuery<Company[], AxiosError>(
    [PATHS.ROOT],
    () => getAllCompanies(),
    { keepPreviousData: true }
  );
}

export function useCompanyWagesByUserId(userId: number) {
  return useQuery<Company[], AxiosError>(
    [PATHS.getWagesByUserId(userId)],
    () => getCompanyWagesByUserId(userId),
    { enabled: !!userId }
  );
}

export function useAllCompaniesNoPagination() {
  return useQuery<Company[], AxiosError>(PATHS.ALL, adminFetcher);
}

export function useAllCompaniesByAdmin(paginationDto: PaginationDto) {
  return useQuery<Paginated<Company>, AxiosError>(
    [PATHS.ROOT, paginationDto],
    () => getAllCompaniesByAdmin(paginationDto),
    {
      keepPreviousData: true,
    }
  );
}

export function useCompanyById(id: number) {
  return useQuery<Company, AxiosError>(PATHS.getOneById(id), adminFetcher, {
    enabled: !!id,
  });
}

export const useCreateCompanyMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (createCompanyDto: CreateCompanyDto) => createCompany(createCompanyDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('저장되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('저장에 실패했습니다.');
      },
    }
  );
};

export const useUpdateCompanyMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(
    (updateCompanyDto: UpdateCompanyDto) => updateCompany(updateCompanyDto),
    {
      onSuccess: () => {
        onSuccess && onSuccess();
        toast.success('수정되었습니다.');
        queryClient.invalidateQueries(PATHS.ROOT);
      },
      onError: () => {
        toast.error('수정에 실패했습니다.');
      },
    }
  );
};

export const useDeleteCompanyMutation = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation((id: number) => deleteCompany(id), {
    onSuccess: () => {
      onSuccess && onSuccess();
      toast.success('삭제되었습니다.');
      queryClient.invalidateQueries(PATHS.ROOT);
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message || '삭제에 실패했습니다.');
    },
  });
};
