import React, { FC } from 'react';
import { map } from 'lodash';
import { AppliedResumeCertificate } from '../api/applied-resume-certificate/type';

interface AppliedCertificateListProps {
  appliedCertificates: AppliedResumeCertificate[];
}

export const AppliedCertificateList: FC<AppliedCertificateListProps> = ({
  appliedCertificates,
}) => {
  return (
    <>
      <h3 className="mb-9">특기사항</h3>
      {appliedCertificates &&
        map(
          appliedCertificates,
          (appliedCertificate: AppliedResumeCertificate) => (
            <div className="mb-8 border-b pb-8" key={appliedCertificate.id}>
              <div className="grid grid-cols-1 gap-8">
                <div className="space-y-2">
                  <p className="text-13 text-gray-700">자격증 및 특기사항</p>
                  <p>{appliedCertificate.description}</p>
                </div>
              </div>
            </div>
          )
        )}
    </>
  );
};
