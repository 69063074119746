import { setHours, setMinutes } from 'date-fns';
import { chain, map } from 'lodash';
import { DateList, DateTime } from '../types';

export const setHourAndMinDate = (workDate: string, dateTime: DateTime) => {
  const { hour, min } = dateTime;
  let date = new Date(workDate);
  date = setHours(date, hour);
  date = setMinutes(date, min);
  return date;
};

export const convertDateList = (dateList: DateList[]) => {
  const result = chain(
    map(dateList, (date) => {
      const { workDate, timeList } = date;
      return map(timeList, (time) => {
        const { startTime, endTime } = time;
        return {
          workDate: new Date(workDate).toISOString(),
          workingStartAt: setHourAndMinDate(workDate, startTime).toISOString(),
          workingEndAt: setHourAndMinDate(workDate, endTime).toISOString(),
        };
      });
    })
  )
    .flatten()
    .value();
  return result;
};
