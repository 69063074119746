import { useEffect, useState } from 'react';
import { map } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Pagination } from '../../../components/Pagination';
import { Table } from '../../../components/Table';
import { TextField } from '../../../components/TextField';
import { ITEMS_PER_PAGE_MD } from '../../../constants';
import { useAllPartTimeJobsByCompany } from '../../../hooks/part-time-job';
import { sortDescString } from '../../../utils';

export const CompanyPartTimeJob = () => {
  const [page, setPage] = useState(1);
  const [dateFilter, setDateFilter] = useState<string>();
  const { id: companyId } = useParams<{ id: string }>();
  const { data: allPartTimeJobsByCompany } = useAllPartTimeJobsByCompany(
    {
      page,
      itemsPerPage: ITEMS_PER_PAGE_MD,
      sort: sortDescString,
      filter: dateFilter
        ? {
            workDateTime: new Date(dateFilter).toISOString(),
          }
        : undefined,
    },
    companyId ? +companyId : 0
  );

  useEffect(() => {
    setPage(1);
  }, [dateFilter, setPage]);

  return (
    <>
      <div className="flex items-center justify-between py-2 px-8">
        <TextField
          className="w-50"
          type="date"
          max="9999-12-31"
          onChange={(e) => setDateFilter(e.target.value)}
        />
        <Button
          className=" shadow-xs w-30 h-12 bg-gray-800 text-sm text-white"
          to={`/admin/company/${companyId}/part-time-job/add`}
        >
          공고 추가
        </Button>
      </div>
      <Card>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th>ID</Table.Th>
              <Table.Th>근무 타입</Table.Th>
              <Table.Th>시급</Table.Th>
              <Table.Th>모집 인원</Table.Th>
              <Table.Th>모집 내용</Table.Th>
              <Table.Th></Table.Th>
              <Table.Th></Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {allPartTimeJobsByCompany &&
              map(allPartTimeJobsByCompany.items, (part) => (
                <Table.Row key={part.id}>
                  <Table.Td>{part.id}</Table.Td>
                  <Table.Td>{part.workType}</Table.Td>
                  <Table.Td>{part.hourPay}</Table.Td>
                  <Table.Td>{part.recruitNumber}</Table.Td>
                  <Table.Td>{part.recruitDescription}</Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/part-time-job/${part.id}/manage`}
                    >
                      신청자 관리
                    </Link>
                  </Table.Td>
                  <Table.Td className="text-right">
                    <Link
                      className="font-normal text-indigo-600 hover:text-indigo-900"
                      to={`/admin/company/${companyId}/part-time-job/${part.id}/edit`}
                    >
                      상세보기
                    </Link>
                  </Table.Td>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Card>
      {allPartTimeJobsByCompany?.items.length !== 0 && (
        <Pagination
          itemsPerPage={ITEMS_PER_PAGE_MD}
          setPage={setPage}
          totalItemCount={
            allPartTimeJobsByCompany?.pagination.totalItemCount || 0
          }
          page={page}
        />
      )}
    </>
  );
};
