import { PartTimeJobDate } from '../part-time-job-date/type';
import { UserRole } from '../user/type';

export enum JobStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
}

export const KOREAN_JOB_STATUS = {
  [JobStatus.APPROVED]: '승인완료',
  [JobStatus.PENDING]: '승인대기',
};

export enum Attendance {
  WORK = 'WORK',
  NOT_WORK = 'NOT_WORK',
  PENDING = 'PENDING',
}

export const KOREAN_ATTENDANCE = {
  [Attendance.WORK]: '출근',
  [Attendance.NOT_WORK]: '결근',
  [Attendance.PENDING]: '-',
};

export enum Alarm {
  PENDING = 'PENDING',
  DELIVERED = 'DELIVERED',
}

export const KOREAN_ALARM = {
  [Alarm.PENDING]: '미발송',
  [Alarm.DELIVERED]: '발송완료',
};
interface UserWork {
  email: string;
  id: number;
  phone: string;
  role: UserRole;
  name: string;
  notWorkCount: number;
  applicationCount: number;
}
export interface Application {
  id: number;
  createdAt: string;
  updatedAt: string;
  companyName: string; // 근무지, 회사명
  recruitDescription: string; // 모집 내용
  workDate: string;
  startWorkTime: string; // 근무 시작 시간
  endWorkTime: string; // 근무 종료 시간
  appliedDate: string; // 알바 신청일
  jobStatus: JobStatus; // 알바 승인 상태
  attendance: Attendance; // 출근 여부
  alarm: Alarm; // 알림톡 여부
  userName: string; // 유저 이름
  userPersonalId: string; // 유저 주민번호
  userPhone: string; // 유저 휴대전화
  partTimeJobDateId: number;
  partTimeJobDate: PartTimeJobDate;
  partTimeJobId: number;
  user: UserWork;
  appliedResumeId: number;
}

export interface CreateApplicationDto {
  companyName: string; // 근무지, 회사명
  recruitDescription: string; // 모집 내용
  workDate: string;
  startWorkTime: string; // 근무 시작 시간
  endWorkTime: string; // 근무 종료 시간
  partTimeJobDateId: number;
  partTimeJobId: number;
}

export interface UpdateApplicationDto {
  id: number;
  companyName?: string; // 근무지, 회사명
  recruitDescription?: string; // 모집 내용
  workDate?: string;
  startWorkTime?: string; // 근무 시작 시간
  endWorkTime?: string; // 근무 종료 시간
  appliedDate?: string; // 알바 신청일
  jobStatus?: JobStatus; // 알바 승인 상태
  attendance?: Attendance; // 출근 여부
  Alarm?: Alarm; // 알림톡 여부
  userName?: string; // 유저 이름
  userPersonalId?: string; // 유저 주민번호
  userPhone?: string; // 유저 휴대전화
  partTimeJobDateId?: number;
  partTimeJobId?: number;
}

export interface BulkUpdateApplicationDto {
  updateApplicationIds: number[];
  partTimeJobDateId: number;
}
