import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface HamburgerButtonProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  setOpen: () => void;
  isWhite?: boolean;
}

export const HamburgerButton: FC<HamburgerButtonProps> = ({
  className,
  open,
  setOpen,
  isWhite,
  ...props
}) => {
  const [isTop, setIsTop] = useState(true);
  const handleScroll = () => {
    if (!window) return;

    if (window.scrollY === 0) {
      setIsTop(true);
    } else if (isTop) {
      setIsTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${className}`} {...props}>
      <button
        className={twMerge(
          'relative h-10 w-7 focus:outline-none',
          isTop ? 'text-white' : 'text-black',
          isWhite && 'text-black',
          open && 'text-black'
        )}
        onClick={() => setOpen()}
      >
        <div className="absolute left-1/2 top-1/2 block w-5 -translate-x-1/2 -translate-y-1/2 transform">
          <span
            className={`absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out ${
              open ? 'rotate-45' : '-translate-y-1.5'
            }`}
          ></span>
          <span
            className={`absolute block  h-0.5 w-5 transform bg-current transition duration-500 ease-in-out ${
              open ? 'opacity-0' : ''
            }`}
          ></span>
          <span
            className={`absolute block h-0.5 w-5 transform bg-current transition duration-500 ease-in-out ${
              open ? '-rotate-45' : 'translate-y-1.5'
            }`}
          ></span>
        </div>
      </button>
    </div>
  );
};
