import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { PartTimeJob } from '../api/part-time-job/type';
import { BookmarkButton } from './BookmarkButton';
import { Icon } from './Icon';

interface PartTimeCardProps {
  partTimeJob: PartTimeJob;
  isLogin: boolean;
}
export const PartTimeCard: FC<PartTimeCardProps> = ({
  partTimeJob,
  isLogin,
}) => {
  const navigate = useNavigate();
  const {
    title,
    hourPay,
    averageWorkingHours,
    company: { companyName, address },
  } = partTimeJob;

  if (!partTimeJob) {
    return <></>;
  }

  return (
    <div onClick={() => navigate(`/part-time-job/${partTimeJob.id}`)}>
      <img
        className="aspect-[5/3] w-full rounded-md border border-gray-200 object-cover"
        src={partTimeJob.mainImgUrl}
        crossOrigin="anonymous"
        alt=""
      />
      <div>
        <div className="mt-6 flex justify-between space-x-3">
          <div className="space-y-1">
            <h5 className="text-24 font-bold line-clamp-2">{title}</h5>
            <p className="teat-gray-700 text-14 font-medium">{companyName}</p>
          </div>
          <BookmarkButton
            partTimeJobId={partTimeJob.id}
            isLike={partTimeJob.userPartTimeJobs?.length !== 0}
            isLogin={isLogin}
          />
        </div>
        <h5 className="mt-2 font-bold">시급 {hourPay.toLocaleString()}원</h5>
        <h5 className="mt-2 text-14 font-medium">
          평균 근무시간 {averageWorkingHours}
        </h5>
        <div className="mt-2">
          <div className="flex w-max max-w-full items-center space-x-1 rounded-md bg-sub-2 p-2">
            <Icon.MapPin className="wh-4 flex-none  text-brand-1" />
            <p className="truncate text-13 font-medium ">{address}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
