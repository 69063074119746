import { FC } from 'react';
import React from 'react';
import { Button } from './Button';
import { Icon } from './Icon';
import { useModal } from './modal/Modal';

export interface ResumeBottomNavBarProps {
  firstResume?: boolean;
  buttonClicked?: boolean;
}

export const ResumeBottomNavBar: FC<ResumeBottomNavBarProps> = ({
  firstResume,
  buttonClicked,
  ...props
}) => {
  const { resumeSkip } = useModal();

  return (
    <>
      <div className="sticky inset-x-0 bottom-0 z-10 mt-10 flex h-24 w-full flex-row-reverse items-center justify-between border-t border-gray-100 bg-white px-5 md:px-8">
        <div className="w-100 space-x-4">
          {firstResume && (
            <Button
              type="button"
              text="건너뛰기"
              className="w-32 border border-brand-1 text-brand-1"
              onClick={() => resumeSkip()}
            />
          )}

          <Button
            text="저장하기"
            className="w-32 bg-brand-1 text-white disabled:bg-gray-300"
            disabled={buttonClicked}
          />
        </div>
        <div className="hidden space-x-2 pr-5 text-gray-600 md:flex">
          <Icon.AlertCircle className="flex-non" />
          <p>사진, 기본 정보, 계좌정보는 필수 입력사항입니다.</p>
        </div>
      </div>
    </>
  );
};
