import { adminApi, api } from '../../plugins/axios';
import { PATHS } from './path';

export function uploadAdminFroalaImage(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return adminApi.post(PATHS.uploadImage, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  });
}

export function uploadFile(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(PATHS.uploadFile, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  });
}
