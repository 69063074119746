import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './paths';
import { CreateCompanyDto, UpdateCompanyDto } from './type';

export function createCompany(createCompanyDto: CreateCompanyDto) {
  return adminApi.post(PATHS.ROOT, createCompanyDto);
}

export function updateCompany(updateCompanyDto: UpdateCompanyDto) {
  const { id } = updateCompanyDto;
  return adminApi.patch(PATHS.getOneById(id), updateCompanyDto);
}

export function deleteCompany(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllCompanies() {
  return api.get(`/${PATHS.ROOT}`).then(({ data }) => data);
}

export function getCompanyWagesByUserId(userId: number) {
  return adminApi
    .get(`/${PATHS.getWagesByUserId(userId)}`)
    .then(({ data }) => data);
}

export function getAllCompaniesByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}
