import { stringify } from 'qs';
import { adminApi, api } from '../../plugins/axios';
import { PaginationDto } from '../../types';
import { PATHS } from './path';
import { CreateNoticeDto, UpdateNoticeDto } from './type';

export function createNotice(createNoticeDto: CreateNoticeDto) {
  return adminApi.post(PATHS.ROOT, createNoticeDto);
}

export function updateNotice(updateNoticeDto: UpdateNoticeDto) {
  const { id } = updateNoticeDto;
  return adminApi.patch(PATHS.getOneById(id), updateNoticeDto);
}

export function deleteNotice(id: number) {
  return adminApi.delete(PATHS.getOneById(id));
}

export function getAllNotices(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return api.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}

export function getAllNoticesByAdmin(paginationDto: PaginationDto) {
  const queryString = stringify(paginationDto, {
    addQueryPrefix: true,
    encode: false,
  });
  return adminApi.get(`/${PATHS.ROOT}${queryString}`).then(({ data }) => data);
}
