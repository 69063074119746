import React, { FC, HTMLAttributes } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { Icon } from '../Icon';
import { Section } from '../Section';

export type SwiperNavigationCardProps = HTMLAttributes<HTMLDivElement>;
SwiperCore.use([Navigation]);

export const SwiperNavigationCard: FC<SwiperNavigationCardProps> = ({
  children,
}) => {
  return (
    <Section className="relative mx-10 md:mx-32">
      <Swiper
        pagination={false}
        navigation={{
          nextEl: '.swiper-button-next-1',
          prevEl: '.swiper-button-prev-1',
        }}
        modules={[Navigation]}
        className="mySwiper mx-0 md:mx-10"
        breakpoints={{
          370: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          768: {
            slidesPerView: 3.2,
            spaceBetween: 10,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
        }}
      >
        {children}
      </Swiper>
      <button className="swiper-button-prev-1 wh-10 absolute -left-10 top-1/2 z-40 hidden place-content-center rounded-full border border-gray-200 md:grid">
        <Icon.ChevronLeft />
      </button>
      <button className="swiper-button-next-1 wh-10 absolute -right-10 top-1/2 z-40 hidden place-content-center rounded-full border border-gray-200 md:grid">
        <Icon.ChevronRight />
      </button>
    </Section>
  );
};
