export const PATHS = {
  ROOT: 'applications',
  WORK: 'applications/attendance/work',
  NOT_WORK: 'applications/attendance/not-work',
  APPROVE: 'applications/approve',
  WITHDRAW: 'applications/withdraw',
  partTimeJobId: (partTimeJobId: number) =>
    `part-time-jobs/${partTimeJobId}/${PATHS.ROOT}`,
  getOneById: (id: number) => `${PATHS.ROOT}/${id}`,
  userId: (userId: number) => `users/${userId}/${PATHS.ROOT}`,
  partTimeJobDateId: (partTimeJobDateId: number) =>
    `part-time-job-dates/${partTimeJobDateId}/${PATHS.ROOT}`,
  getPTJExcel: 'applications/xlsx/ptj',
  getWorkExcel: 'applications/xlsx/work',
};
